<template>
    <chat-header v-if="chatUser" :chatUser="chatUser"></chat-header>
    <div class="my-4" id="image-preview" style="width: 120px; height: 120px;display:none;"></div>
    <div class="member-chat-container">
        <div class="messages" ref="messages" @scroll="checkScroll">
            <div v-if="isLoading" class="loading-text text-center my-4">Loading...</div>
            <div class="message" v-for="message in sortedMessages" :key="message.id" :class="{
        'logged-in-user': message.user && message.user.id === user.id,
        'chat-user': message.user && message.user.id === chatUser.id
    }">
                <div v-if="message.text" class="message-content" @mouseover="showDeleteButton(message.id)">
                    {{ message.text }}
                    <button v-if="hoveredMessage === message.id && user.id === message.from_user_id" class="btn btn-danger btn-sm delete-button"
                        @click="deleteMessage(message.id)">
                        <i class="bi bi-x"></i>
                    </button>
                </div>
                <div v-else-if="message.photo" class="message-content message-photo"
                    @mouseover="showDeleteButton(message.id)">
                    <a :href="message.photo" target="_blank">
                        <img :src="message.photo" :alt="message.user.name"
                            style="width: 100px; padding: 10px; cursor: pointer;">
                    </a>
                    <button v-if="hoveredMessage === message.id && user.id === message.from_user_id" class="btn btn-danger btn-sm delete-button"
                        @click="deleteMessage(message.id)">
                        <i class="bi bi-x"></i>
                    </button>
                </div>
                <div v-else-if="message.audio" class="message-content message-audio"
                    @mouseover="showDeleteButton(message.id)">
                    <audio ref="audioPlayer" controls preload="auto" style="padding:8px;">
                        <source :src="message.audio" type="audio/mpeg">
                    </audio>
                    <button v-if="hoveredMessage === message.id && user.id === message.from_user_id" class="btn btn-danger btn-sm delete-button"
                        @click="deleteMessage(message.id)">
                        <i class="bi bi-x"></i>
                    </button>
                </div>
                <div class="timestamp">{{ getHumanFriendlyDate(message.timestamp) }}</div>
            </div>
        </div>
        <div class="typing-area">
            <div class="input-group">

                <div class="icon-container">
                    <i class="fas fa-image btn-icon" @click="triggerFileInput" style="cursor: pointer;"></i>
                    <input type="file" ref="fileInput" @change="handleFileSelect" accept="image/*"
                        style="display: none;">
                    <i v-if="!isRecording" @click="startRecording" class="fas fa-microphone btn-icon"
                        style="cursor: pointer;"></i>
                    <i v-else @click="stopRecording" class="fas fa-stop btn-icon" style="cursor: pointer;"></i>
                </div>
                <input type="text" class="form-control" v-model="newMessage" @keyup.enter="sendMessage"
                    placeholder="Type a message..." maxlength="255">
                <div class="icon-container">
                    <i class="fas fa-paper-plane btn-icon" @click="sendMessage" style="cursor: pointer;"></i>
                </div>
                <small class="text-muted">Sharing WhatsApp, phone or any abusive word will result in blocking your
                    profile!</small>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Pusher from 'pusher-js';

import ChatHeader from '@/components/ChatHeader.vue';

export default {
    components: {
        'chat-header': ChatHeader,
    },
    data() {
        return {
            member: null,
            newMessage: "",
            messages: [],
            hoveredMessage: null,
            isLoading: false,
            currentPage: 1,
            lastRequestedPage: 0,
            //FOR RECORDING
            isRecording: false,
            mediaRecorder: null,
            audioChunks: [],
            recordingTimeout: null,
            pusher: null,
            channel: null,
            token: sessionStorage.getItem('accessToken'),
            user: null,
            chatUser: null
        };
    },
    mounted() {
        this.getChatUser();

        this.getLoggedinUser();

        this.loadMessages();

        this.initializePusher();
    },
    computed: {
        sortedMessages() {
            return this.messages.slice().sort((a, b) => {
                const dateA = new Date(a.timestamp).getTime();
                const dateB = new Date(b.timestamp).getTime();
                return dateA - dateB;
            });
        },
    },
    methods: {
        async loadMessages() {
            if (this.isLoading || this.currentPage === this.lastRequestedPage) {
                console.log('Skipping load:', { isLoading: this.isLoading, currentPage: this.currentPage, lastRequestedPage: this.lastRequestedPage });
                return;
            }
            this.isLoading = true;
            console.log('Starting load for page:', this.currentPage);

            let newMessages = [];

            try {
                const chatMemberId = this.$route.params.memberId;
                const response = await axios.get(`${process.env.VUE_APP_API_URL}member/get-messages/${chatMemberId}?page=${this.currentPage}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });

                newMessages = response.data.messages.map(message => ({
                    ...message,
                    id: message.id,
                    user: message.from_user_id === this.user.id ? this.user : this.chatUser,
                    timestamp: message.messaged_at,
                    text: message.message,
                    photo: message.photo,
                    audio: message.audio
                }));

                console.log(`Loaded ${newMessages.length} messages`);

                if (newMessages.length) {
                    this.messages = [...newMessages, ...this.messages];
                    this.scrollToBottom();
                }
            } catch (error) {
                console.error("Failed to load messages:", error);
            } finally {
                this.isLoading = false;
                this.lastRequestedPage = this.currentPage;
                this.currentPage++;
                // if (newMessages.length >= 50) {
                //     console.log(`Preparing to load next page: ${this.currentPage + 1}`);
                //     this.currentPage++;
                //     this.$nextTick(() => {
                //         this.loadMessages();
                //     });
                // } else {
                //     console.log('No more messages to load, or less than 50 messages were returned.');
                // }
            }
        },
        checkScroll() {
            const messagesContainer = this.$refs.messages;
            if (messagesContainer.scrollTop === 0) {
                this.loadMessages();
            }
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const messagesContainer = this.$refs.messages;
                if (messagesContainer) {
                    setTimeout(() => {
                        messagesContainer.scrollTop = messagesContainer.scrollHeight;
                    }, 100);
                }
            });
        },
        initializePusher() {
            this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
                cluster: process.env.VUE_APP_PUSHER_CLUSTER,
                encrypted: true,
                authEndpoint: process.env.VUE_APP_API_URL + 'pusher/auth',
                auth: {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                }
            });
        },
        disconnectPusher() {
            if (this.pusher) {
                this.pusher.disconnect();
            }
        },
        getLoggedinUser() {
            const userString = sessionStorage.getItem('user');
            if (userString) {
                this.user = JSON.parse(userString);
            }
        },
        async getChatUser() {
            const chatMemberId = this.$route.params.memberId;
            const url = process.env.VUE_APP_API_URL + "member/get-chat-members/" + chatMemberId;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            });
            this.chatUser = response.data;


            this.subscribeToChannel();
        },
        subscribeToChannel() {
            this.channel = this.pusher.subscribe('private-chat.' + this.user.id);
            this.channel.bind('MessageSent', (e) => {

                const newMessage = {
                    id: e.message.created_at,
                    text: e.message.message,
                    photo: e.message.photo,
                    audio: e.message.audio,
                    timestamp: e.message.created_at,
                    to_user_id: e.message.to_user_id,
                    user: e.message.from_user_id === this.user.id ? this.user : this.chatUser,
                };

                this.messages.push(newMessage);
                this.scrollToBottom();
                // Check if page is not in focus, then play sound
                if (document.visibilityState !== 'visible') {
                    const audio = new Audio(require('@/assets/audios/new-message.mp3'));
                    audio.play().catch(error => console.error('Error playing sound:', error));
                }
            });
        },
        async sendMessage() {
            if (this.newMessage.trim() === '') {
                return;
            }
            const to_user_id = this.$route.params.memberId;
            if (!to_user_id) {
                this.$router.go(-1);
                return;
            }
            const newMessage = {
                id: Date.now(),
                text: this.newMessage,
                timestamp: new Date().toISOString(),
                to_user_id: to_user_id,
                user: this.user,
            };
            this.messages.push(newMessage);
            this.newMessage = '';

            this.scrollToBottom();

            const payload = {
                message: newMessage.text,
                to_user_id: to_user_id,
                messaged_at: newMessage.timestamp
            };
            this.postToServer(payload);
        },
        async startRecording() {
            if (this.isRecording || !navigator.mediaDevices) {
                console.error('Recording is already in progress or getUserMedia not supported');
                return;
            }

            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                // Determine the MIME type based on the browser and device compatibility
                const mimeType = this.getAudioFormat();

                // Specify high-quality recording settings
                const audioOptions = {
                    mimeType,
                    audioBitsPerSecond: 128000 // Higher bit rate for better quality
                };

                // Check if the MIME type is supported with the specified audio quality settings
                if (MediaRecorder.isTypeSupported(audioOptions.mimeType)) {
                    this.mediaRecorder = new MediaRecorder(stream, audioOptions);
                } else {
                    // Fallback to default settings if the desired quality or MIME type is not supported
                    console.log('Falling back to default recording settings due to unsupported MIME type or audio quality settings.');
                    this.mediaRecorder = new MediaRecorder(stream);
                }

                this.audioChunks = [];
                this.isRecording = true;

                this.mediaRecorder.ondataavailable = e => this.audioChunks.push(e.data);
                this.mediaRecorder.start();

                // Automatically stop recording after 1 minute
                this.recordingTimeout = setTimeout(() => this.stopRecording(), 60000);
            } catch (err) {
                console.error('Error accessing the microphone:', err);
            }
        },
        stopRecording() {
            if (!this.isRecording || !this.mediaRecorder) return;

            this.mediaRecorder.stop();
            clearTimeout(this.recordingTimeout);

            this.mediaRecorder.onstop = () => {
                const mimeType = this.getAudioFormat();
                const audioBlob = new Blob(this.audioChunks, { type: mimeType });
                const audioUrl = URL.createObjectURL(audioBlob);

                const newMessage = {
                    id: Date.now(),
                    timestamp: new Date().toISOString(),
                    audio: audioUrl,
                    user: this.user
                };
                this.messages.push(newMessage);
                this.isRecording = false;

                this.scrollToBottom();

                const payload = new FormData();
                // const fileExtension = mimeType.includes('mp4') ? 'm4a' : (mimeType.includes('ogg') ? 'ogg' : 'webm');
                // payload.append('audio', audioBlob, `audio_${newMessage.id}.${fileExtension}`);
                payload.append('audio', audioBlob, `audio_${newMessage.id}.m4a`);
                payload.append('to_user_id', this.chatUser.id);
                payload.append('messaged_at', newMessage.timestamp);

                this.postToServer(payload);
            };
        },
        getAudioFormat() {
            return 'audio/mp3';
            // Prioritize MIME types based on browser support. You might need to adjust this based on testing.
            // if (MediaRecorder.isTypeSupported('audio/mp4')) {
            //     return 'audio/mp4';
            // } else if (MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
            //     return 'audio/webm;codecs=opus';
            // } else if (MediaRecorder.isTypeSupported('audio/webm')) {
            //     return 'audio/webm';
            // } else {
            //     // Default MIME type if none of the preferred types are supported
            //     return 'audio/mp3'; // Note: 'audio/wav' might not be directly supported. This is just a placeholder.
            // }
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileSelect(event) {
            const file = event.target.files[0];
            if (file && file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const img = new Image();
                    img.src = e.target.result;
                    img.onload = () => {
                        const preview = document.createElement('img');
                        preview.src = img.src;
                        preview.width = 120; // Set the width
                        preview.height = 120; // Set the height
                        document.getElementById('image-preview').innerHTML = '';
                        document.getElementById('image-preview').appendChild(preview);
                        const newMessage = {
                            id: Date.now(),
                            text: '',
                            timestamp: new Date().toISOString(),
                            photo: e.target.result,
                            user: this.user
                        };
                        this.messages.push(newMessage);

                        this.scrollToBottom();

                        const payload = {
                            photo: e.target.result,
                            to_user_id: this.chatUser.id,
                            messaged_at: newMessage.timestamp
                        };
                        this.postToServer(payload);
                    };
                };

                reader.readAsDataURL(file);
            } else {
                console.error('Selected file is not an image.');
            }
        },
        async postToServer(payload) {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${this.token}`,
                },
            };
            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}chat/send-message`, payload, config);
                console.log(response.data);
            } catch (error) {
                console.error('Error while sending message', error.response || error);
            }
        },
        showDeleteButton(messageId) {
            this.hoveredMessage = messageId;
        },
        deleteMessage(messageId) {
            // Remove the message from the messages array
            const index = this.messages.findIndex(message => message.id === messageId);
            if (index !== -1) {
                this.messages.splice(index, 1);
            }

            // Send DELETE request to the server
            axios.delete(`${process.env.VUE_APP_API_URL}member/message/${messageId}/delete`, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            });
        },
        getHumanFriendlyDate(dateInput) {
            const currentDate = new Date();
            const inputDate = new Date(dateInput);
            const diffInSeconds = Math.floor((currentDate - inputDate) / 1000);
            if (diffInSeconds < 60) { // less than 1 minute
                return "Just now";
            } else if (diffInSeconds < 3600) { // less than 1 hour
                return Math.floor(diffInSeconds / 60) + " minutes ago";
            } else if (diffInSeconds < 86400) { // less than 1 day
                return Math.floor(diffInSeconds / 3600) + " hours ago";
            } else if (diffInSeconds < 604800) { // less than 1 week
                return Math.floor(diffInSeconds / 86400) + " days ago";
            } else {
                // For dates older than 1 week, return the date in a readable format
                return inputDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });
            }
        }
    },
    beforeUnmount() {
        this.disconnectPusher();

        clearTimeout(this.recordingTimeout);
        if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
            this.mediaRecorder.stop();
        }
        // Also consider stopping the stream to release the microphone
        if (this.mediaRecorder && this.mediaRecorder.stream) {
            this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }
    },
};
</script>

<style scoped>
.member-chat-container {
    display: flex;
    flex-direction: column;
    height: calc(94vh);
    margin-top: 0px;
}

.messages {
    overflow-y: auto;
    flex-grow: 1;
    margin-bottom: 80px;
    margin-top: 10px;
}

.message {
    margin-bottom: 10px;
}

.message-content {
    width: 80%;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 5px;
    position: relative;
}
.delete-button {
    font-size: 6px;
    color: #000;
    position: absolute;
    top: 5px;
    right: 10px;
    padding: 0;
    background-color: transparent;
    border: none;
    z-index: 100;
}

.logged-in-user .message-content {
    background-color: #e9f5ff;
    margin-left: 1%;
}

.chat-user .message-content {
    float: right;
    background-color: #f2f3f5;
    margin-right: 1%;
}

.message-photo,
.message-audio {
    padding: 0px !important;
    background: none !important;
}

.timestamp {
    font-size: 0.75rem;
    color: #999999;
}

.logged-in-user .timestamp {
    text-align: right;
    padding-right: 20%;
}

.chat-user .timestamp {
    text-align: left;
    padding-left: 20%;
}

.typing-area {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-top: 1px solid #ccc;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.input-group {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    /* Adds space between the input and icons */
}

.form-control {
    flex-grow: 1;
    padding: 10px 15px;
    /* Increase padding for larger text area */
    font-size: 1rem;
    /* Adjust font size as needed */
    border-radius: 20px;
    /* Optional: Rounded corners for the input */
    border: 1px solid #ced4da;
    /* Optional: Match Bootstrap's default styling */
}

.icon-container {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Adds space between icons */
}

.btn-icon {
    font-size: 24px;
    /* Double the icon size */
    cursor: pointer;
}


</style>
