<template>
    <main-header heading="Chat"></main-header>

    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8">
                <div class="chat-members-list">
                    <div v-if="members.length > 0" class="list-group">
                        <a v-for="member in members" :key="member.id" :href="`/member/chat/${member.id}`"
                            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <img :src="member.photo_url" class="rounded-circle me-3" alt="Member Avatar">
                                <div>
                                    <h6 class="mb-1">{{ member.name }}</h6>
                                    <small><span v-if="member.last_message.text" class="me-2 fst-italic">{{ member.last_message.text }}</span> <span class="text-muted">{{ getHumanFriendlyDate(member.last_message.created_at) }}</span></small>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div v-else class="text-center mt-4">
                        <p>No members available to chat. Try connecting with more members.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>
  
<script>
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    name: 'ChatMembersList',
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    },
    data() {
        return {
            members: [],
        };
    },
    methods: {
        async fetchChatMembers() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}member/get-chat-members`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
                    },
                });
                this.members = response.data.requests.map(request => {
                    return request;
                });
            } catch (error) {
                console.error('Error fetching chat members:', error);
            }
        },
        getHumanFriendlyDate(dateInput) {
            const currentDate = new Date();
            const inputDate = new Date(dateInput);
            const diffInSeconds = Math.floor((currentDate - inputDate) / 1000);

            if (diffInSeconds < 60) { // less than 1 minute
                return "Just now";
            } else if (diffInSeconds < 3600) { // less than 1 hour
                return Math.floor(diffInSeconds / 60) + " minutes ago";
            } else if (diffInSeconds < 86400) { // less than 1 day
                return Math.floor(diffInSeconds / 3600) + " hours ago";
            } else if (diffInSeconds < 604800) { // less than 1 week
                return Math.floor(diffInSeconds / 86400) + " days ago";
            } else {
                // For dates older than 1 week, return the date in a readable format
                return inputDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });
            }
        }
    },
    created() {
        this.fetchChatMembers();
    },
};
</script>
  
<style>
.rounded-circle {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 50%; 
    object-fit: cover; 
}
</style>
  