<template>
    <main-header heading="Manage Subscription"></main-header>

    <div class="container my-4">
        <h3 class="mt-4 mb-4">My Subscription</h3>
        <div v-if="lastSubscription" class="card mb-4">
            <div class="card-body">
                <h5 class="card-title">{{ lastSubscription.package.title }}</h5>
                <p>Price: {{ formatPrice(lastSubscription.price) }}</p>
                <p>Credit Remaining: {{ lastSubscription.credits_remaining }}</p>
                <p>Status:
                    <strong
                        :class="{ 'text-success': lastSubscription.status === 'Active', 'text-danger': lastSubscription.status === 'Expired' }">
                        {{ lastSubscription.status }}
                    </strong>
                </p>
                <p>Expiry: {{ formatDate(lastSubscription.expiry) }}</p>
            </div>

        </div>
        <router-link to="/subscription/packages" class="btn btn-secondary mb-4 w-100"> Upgrade Your Subscription
        </router-link>
        <div v-if="invoices !== null && invoices.length > 0 && invoices[0] !== null">
            <h3>Invoices</h3>
            <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(invoice, index) in invoices" :key="invoice.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ formatDate(invoice.invoice_date) }}</td>
                        <td>{{ invoice.status }}</td>
                        <td><a :href="invoice.url" download>Download</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
export default {
    name: 'MemberSubscriptions',
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    },
    data() {
        return {
            lastSubscription: null,
            invoices: [],
            accessToken: sessionStorage.getItem('accessToken')
        };
    },
    methods: {
        fetchLastSubscription() {
            const headers = {
                "Authorization": "Bearer " + this.accessToken,
            };
            axios.get(process.env.VUE_APP_API_URL + 'member/subscription/', { headers })
                .then(response => {
                    this.lastSubscription = response.data.subscription;
                    this.invoices = response.data.invoices;
                })
                .catch(error => {
                    console.error('Error fetching subscription details:', error);
                });
        },
        formatPrice(value) {
            return Math.abs(value) < 0.01 ? 'Free' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PKR' }).format(value);
        },
        formatDate(dateString) {
            const options = {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
            };

            return new Date(dateString).toLocaleDateString('en-US', options)
                .replace(/(\d+)\s+(\w+)\s+(\d+)/, '$1-$2-$3');
        },
        pay() {
            // Implement payment logic here
            console.log('Payment button clicked');
        }
    },
    mounted() {
        this.fetchLastSubscription();
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>