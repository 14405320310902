<template>
    <main-header heading="Guardian Details"></main-header>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div v-if="toastMessage" class="alert" :class="toastType ? 'alert-success' : 'alert-danger'">
                    {{ toastMessage }}
                </div>
                <h3 class="mt-4">Manage Guardian</h3>
                <form @submit.prevent="submitForm">
                    <div class="mb-3">
                        <label for="name">Name:</label>
                        <input type="text" id="name" class="form-control" v-model="guardian.name" required>
                    </div>
                    <div class="mb-3">
                        <label for="relationship">Relationship:</label>
                        <select id="relationship" class="form-control" v-model="guardian.relationship" required>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Uncle">Uncle</option>
                            <option value="Aunt">Aunt</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="whatsapp">WhatsApp:</label>
                        <input type="text" id="whatsapp" class="form-control" v-model="guardian.whatsapp" required>
                    </div>
                    <div class="d-flex justify-content-between mt-4">
                        <button type="submit" class="btn btn-primary w-100" :disabled="isLoading">
                            <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            {{ isLoading ? 'Loading...' : 'Submit' }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter,
    },
    data() {
        return {
            isLoading: false,
            guardian: {
                name: '',
                relationship: '',
                whatsapp: '',
            },
            toastMessage: '',
            toastType: true,
            token: sessionStorage.getItem('accessToken'),
        };
    },
    mounted() {
        this.fetchGuardianDetails();
    },
    methods: {
        async fetchGuardianDetails() {
            // const guardianId = this.$route.params.guardian;
            // if (!guardianId) return;

            this.isLoading = true;
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + `guardians/get`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });
                this.guardian = response.data;
            } catch (error) {
                this.toastMessage = "Failed to load guardian details: " + error.response.data.message || error.message;
                this.toastType = false;
            } finally {
                this.isLoading = false;
            }
        },
        async submitForm() {
            this.isLoading = true;
            try {
                const payload = this.guardian;
                await axios.post(process.env.VUE_APP_API_URL + 'guardians/', payload, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });
                this.toastMessage = "Guardian details saved successfully";
                this.toastType = true;
            } catch (error) {
                this.toastMessage = "Failed to save details: " + error.response.data.message || error.message;
                this.toastType = false;
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
/* Additional styling can be added here */
</style>