<template>
    <main-header heading="Complete Your Subscription"></main-header>

    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <h5 class="card-title my-4">Complete Your Subscription</h5>
                <p>Thank you for subscribing to the {{ selectedPackage.title }}. To finalize your subscription, kindly
                    proceed with the payment using {{ selectedPaymentMethod }} detailed in the invoice provided
                    below. Additionally, please notify the administrator with the necessary information to activate your
                    subscription promptly.</p>
                <div class="d-flex justify-content-between mt-4">
                    <a class="btn btn-primary w-50 me-2" :href="invoiceUrl">Download Invoice</a>
                    <router-link class="btn btn-secondary w-50" to="/members/search">Search Profiles</router-link>
                </div>

            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>

import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter,
    },
    data() {
        return {
            accessToken: sessionStorage.getItem('accessToken'),
            selectedPackage: JSON.parse(sessionStorage.getItem('package')),
            selectedPaymentMethod: sessionStorage.getItem('paymentMethod'),
            invoiceUrl: sessionStorage.getItem('invoiceUrl')
        }
    },
    methods: {

    }
};
</script>

<style></style>
