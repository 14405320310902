<template>
  <main-header heading="Logout"></main-header>

  <div class="container my-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <h3 class="card-title">Logout</h3>
        <p class="card-text my-4">Are you sure you want to log out?</p>
        <button @click="logout" class="btn btn-primary w-100">Logout</button>
      </div>
    </div>
  </div>
  <main-footer></main-footer>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
export default {
  components: {
    'main-header': MainHeader,
    'main-footer': MainFooter
  },
  methods: {
    logout() {
      sessionStorage.removeItem('accessToken');

      if (sessionStorage.getItem('members')) {
        sessionStorage.removeItem('members');
      }
      if (sessionStorage.getItem('match_preferences')) {
        sessionStorage.removeItem('match_preferences');
      }
      if (sessionStorage.getItem('user')) {
        sessionStorage.removeItem('user');
      }
      
      this.$router.push('/login');
    },
  },
};
</script>
