<template>
    <main-header heading="Verify Contact"></main-header>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div v-if="successMessage" class="alert alert-success mt-3">{{ successMessage }}</div>
                <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
                <h3 class="mt-4">Verify Contact</h3>
                <form @submit.prevent="submitForm">
                    <div class="mb-3">
                        <label for="email" class="form-label">Email Address</label>
                        <div class="input-group">
                            <input type="email" id="email" class="form-control" v-model="email"
                                :class="{ 'is-invalid': errors.email }" @change="validateAndVerifyEmail" required>
                            <button type="button" class="btn btn-primary" @click="verifyEmail"
                                v-if="!email_verified_at">Verify Email</button>
                        </div>
                        <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
                    </div>
                    <div class="mb-3">
                        <label for="mobile" class="form-label">WhatsApp Number</label>
                        <div class="input-group">
                            <input type="tel" id="mobile" class="form-control" v-model="mobile"
                                :class="{ 'is-invalid': errors.mobile }" @change="validateAndVerifyPhoneNumber" required>
                            <button type="button" class="btn btn-primary" @click="verifyWhatsApp"
                                v-if="!mobile_verified_at">Verify WhatsApp</button>
                        </div>
                        <div v-if="errors.mobile" class="invalid-feedback">{{ errors.mobile }}</div>
                        <small>Use country code without plus(+) sign with phone number ex: 923001234567</small>
                    </div>
                    <div class="d-flex justify-content-between mt-4">
                        <button type="submit" class="btn btn-primary w-100">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>
<script>

import axios from "axios";
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    },
    data() {
        return {
            email: '',
            mobile: '',
            email_verified_at: '',
            mobile_verified_at: '',
            isValid: true,
            successMessage: '',
            errorMessage: '',
            errors: {
                email: '',
                mobile: '',
            },

        };
    },
    created() {
        this.loadUserProfile();
    },
    methods: {
        async loadUserProfile() {
            const apiUrl = process.env.VUE_APP_API_URL + "member/profile";
            const accessToken = sessionStorage.getItem('accessToken');
            const headers = {
                "Authorization": "Bearer " + accessToken,
            };
            try {
                const response = await axios.get(apiUrl, { headers });
                this.email = response.data.email;
                this.mobile = response.data.mobile;
                this.email_verified_at = response.data.email_verified_at;
                this.mobile_verified_at = response.data.mobile_verified_at;

            } catch (error) {
                console.error("Error fetching member settings:", error);
            }
        },
        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        validateAndVerifyEmail() {
            if (this.isValidEmail(this.email)) {
                this.verifyEmailAPI(this.email);
            } else {
                // Email format is not valid
                this.errors.email = 'Please enter a valid email address.';
                this.isValid = false;
            }
        },
        isValidPhoneNumber(number) {
            const cleanNumber = number.replace(/[+\-()\s.]/g, '');

            if (cleanNumber.length >= 12 && cleanNumber.length <= 15) {
                const phoneRegex = /^\d+$/;
                return phoneRegex.test(cleanNumber);
            }

            return false;
        },
        validateAndVerifyPhoneNumber() {
            if (this.isValidPhoneNumber(this.mobile)) {
                this.verifyPhoneNumberAPI(this.mobile);
            } else {
                this.errors.mobile = 'Please enter a valid phone number with country code.';
                this.isValid = false;
            }
        },
        async verifyEmailAPI(email) {
            try {
                const headers = {
                    "Authorization": "Bearer " + this.accessToken,
                };
                const response = await axios.post(process.env.VUE_APP_API_URL + 'verify-contact', { contact: email }, { headers });
                if (response.data.status === 'success') {
                    this.errors.email = null;
                    this.isValid = true;
                } else {
                    this.errors.email = 'Email already exists in our database. Please use another one.';
                    this.isValid = false;
                }
            } catch (error) {
                this.errors.email = 'There is an error verifying your email with our system. Please try again later.';
                this.isValid = false;
            }
        },
        async verifyPhoneNumberAPI(number) {
            try {
                const headers = {
                    "Authorization": "Bearer " + this.accessToken,
                };
                const response = await axios.post(process.env.VUE_APP_API_URL + 'verify-contact', { contact: number }, { headers });
                if (response.data.status == 'success') {
                    //DO NOTHING IF PHONE EXISTS IN THE SYSTEM
                    this.errors.mobile = null;
                    this.isValid = true;
                } else {
                    this.errors.mobile = 'Phone number already exists in our database. Please use another one.';
                    this.isValid = false;
                }
            } catch (error) {
                this.errors.mobile = 'There is an error verifying your phone number with our system. Please try again later.';
                this.isValid = false;
            }
        },
        async submitForm() {
            try {
                const payload = {
                    email: this.email,
                    mobile: this.mobile,
                }
                const response = await axios.put(process.env.VUE_APP_API_URL + "members/update-contact", payload, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                    }
                });
                if (response.status == 200) {
                    this.successMessage = 'Preferences saved successfully.';
                } else {
                    this.showError("Profile update failed: " + response.data.message);
                }
            } catch (error) {
                this.showError("Error during profile update: " + error.message);
            }
        },
        showError(message) {
            alert(message);
        },
        resetForm() {
            // this.email = '';
            // this.mobile = '';
        },
        verifyWhatsApp() {
            this.$router.push('/member/verify-whatsapp');
        },
        verifyEmail() {
            this.$router.push('/member/verify-email');
        }
    },
}
</script>