<template>
    <main-header heading="Requests Received"></main-header>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div class="member-requests-received">
                    <div v-if="requests.length > 0" class="list-group">
                        <div class="list-group-item" v-for="(request, index) in requests" :key="request.id">
                            <div class="d-flex justify-content-between">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <img :src="request.photo_url" class="rounded-circle me-3" alt="Member Avatar">
                                    <div class="flex-grow-1">
                                        <p class="mb-1">{{ request.first_name }} {{ request.last_name }}
                                            <span v-if="request.items == 'profile'">wants to view your profile.</span>
                                            <span v-else-if="request.items == 'photos'">wants to view your photos.</span>
                                            <span v-else-if="request.items == 'chat'">wants to chat with you.</span>
                                        </p>
                                        <div v-if="request.status === 'approved'">Request accepted.</div>
                                        <div v-else-if="request.status === 'denied'">Request deleted.</div>
                                        <div v-else>
                                            <button class="btn btn-primary me-2"
                                                @click="approveRequest(request, index)">Accept</button>
                                            <button class="btn btn-secondary"
                                                @click="denyRequest(request, index)">Delete</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- Date and Cancel Button Column -->
                                <div class="col d-flex flex-column align-items-center justify-content-center">
                                    <span class="date-time">{{ getHumanFriendlyDate(request.created_at) }}</span>
                                </div>
                            </div>
                            <hr class="my-4">
                        </div>
                    </div>
                    <div v-else class="text-center mt-4">
                        <p>There are no requests received from members. You can search for a member and send one
                            <router-link to="/members/search">here</router-link>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    },
    setup() {
        // Define the reactive variables
        const requestApproved = ref(false);
        const requestDenied = ref(false);
        const requests = ref([]);
        // Fetch requests data from the server
        const fetchRequests = async () => {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'member/get-requests-received', {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                    }
                });
                requests.value = response.data.requests.map(request => {
                    //ADD DEFAULT PHOTO
                    
                    //ITEMS
                    const items = request.items.split(',');
                    return {
                        ...request,
                        hideProfile: items.includes('profile'),
                        hidePhotos: items.includes('photos'),
                        hideChat: items.includes('chat')
                    };
                });
            } catch (error) {
                console.error('Error fetching the requests:', error);
            }
        };

        onMounted(fetchRequests);

        // Approve a specific request
        const approveRequest = async (request, index) => {
            try {
                const requestData = {
                    request_id: request.id,
                };
                if (request.items == 'profile') {
                    requestData.profile = true;
                } else if (request.items == 'photos') {
                    requestData.photos = true;
                } else if (request.items == 'chat') {
                    requestData.chat = true;
                }
                await axios.post(`${process.env.VUE_APP_API_URL}member/approve-request`, requestData, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
                    },
                });
                requests.value[index].status = 'approved'; // Directly update the status of the specific request
            } catch (error) {
                console.error('Error approving the request:', error);
            }
        };
        const denyRequest = async (request, index) => {
            try {
                await axios.post(`${process.env.VUE_APP_API_URL}member/deny-request`, { request_id: request.id }, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
                    },
                });
                requests.value.splice(index, 1); // Remove the specific request from the list
            } catch (error) {
                console.error('Error denying the request:', error);
            }
        };
        // Format a date string
        const getHumanFriendlyDate = (dateInput) => {
            const currentDate = new Date();
            const inputDate = new Date(dateInput);
            const diffInSeconds = Math.floor((currentDate - inputDate) / 1000);

            if (diffInSeconds < 60) { 
                return "Just now";
            } else if (diffInSeconds < 3600) { 
                return Math.floor(diffInSeconds / 60) + " minutes ago";
            } else if (diffInSeconds < 86400) { 
                return Math.floor(diffInSeconds / 3600) + " hours ago";
            } else if (diffInSeconds < 604800) { 
                return Math.floor(diffInSeconds / 86400) + " days ago";
            } else {
                return inputDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });
            }
        };

        // Capitalize the status
        const capitalizeStatus = (status) => {
            if (!status) return '';
            return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        };
        // Check if any switch is on
        const isAnySwitchOn = (request) => {
            return request.hideProfile || request.hidePhotos || request.hideChat;
        };
        return {
            requests,
            denyRequest,
            approveRequest,
            getHumanFriendlyDate,
            capitalizeStatus,
            isAnySwitchOn,
            requestApproved,
            requestDenied
        };
    }
};
</script>
<style scoped>
.member-requests-received {
    margin: auto;
}

.list-group-item {
    color: #000000;
    margin-bottom: 1rem;
    padding: 1rem;
}

.rounded-circle {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.flex-grow-1 h5,
.flex-grow-1 small {
    margin-bottom: 0;
}

.form-check-input {
    cursor: pointer;
}

.btn-outline-danger {
    float: right;
}

/* Animation for deleting a request */
@keyframes fadeOut {
    from {
        opacity: 1;
        height: auto;
        padding: 1rem;
    }

    to {
        opacity: 0;
        height: 0;
        padding: 0;
    }
}

.animate-out {
    animation: fadeOut 300ms forwards;
}

.time-ago {
    color: #272727;
}
</style>
