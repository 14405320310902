<template>
  <search-header @filters-changed="handleFilterChange"></search-header>
  <div v-for="(entry, index) in Object.entries(warnings)" :key="index" class="d-block">
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      <div v-if="entry[0] === 'profile_complete'">
        <router-link to="/member/profile/edit">Complete your profile.</router-link>
      </div>
      <div v-else-if="entry[0] === 'photos'">
        <router-link to="/member/photos/upload">Upload your photos.</router-link>
      </div>
      <div v-else-if="entry[0] === 'match_preferences'">
        <router-link to="/member/match-preferences">Set your match preferences.</router-link>
      </div>
      <div v-else-if="entry[0] === 'interests'">
        <router-link to="/member/interests/edit">Set your interests.</router-link>
      </div>
      <div v-else-if="entry[0] === 'email_verified'">
        <router-link to="/member/verify-email">Verify your email address.</router-link>
      </div>
      <div v-else-if="entry[0] === 'mobile_verified'">
        <router-link to="/member/verify-whatsapp">Verify your mobile number.</router-link>
      </div>
      <div v-else-if="entry[0] === 'guardian'">
        <router-link to="/guardian/edit">Enter guardian details.</router-link>
      </div>
      <!-- <div v-else>
      {{ entry[1] }}
    </div> -->
      <button type="button" class="btn-close" @click="dismissWarning(index)" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
  </div>
  <div id="contentContainer" class="container my-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div v-if="members.length === 0 && !isLoading">
          <p class="text-center my-4">No profiles found.</p>
        </div>
        <div v-else>
          <matches-of-the-day></matches-of-the-day>
          <h5 class="mt-4">Top Matches</h5>
          <div class="row">
            <div class="col-6 col-md-6 d-flex justify-content-center" v-for="member in members" :key="member.id">
              <member-card :memberId="member.id" :full_name="member.first_name + ' ' + member.last_name"
                :age="calculateAge(member.date_of_birth)" :occupation="member.occupation" :marital_status="member.marital_status"
                :location="`${member.city}, ${member.country}`" :gender="member.gender" :photo_url="member.photo_url" :is_premium="member.is_premium"
                :match_percentage="member.match_percentage"
                @memberClicked="navigateToMember" />
            </div>
            <div v-if="showMoreProfiles" class="text-center" style="margin-bottom: 80px;">
              <button class="btn btn-primary btn-sm" @click="loadMore">Show more
                profiles</button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
  <main-footer></main-footer>
</template>

<script>
import axios from 'axios';
import SearchHeader from '@/components/SearchHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
import MemberCard from '@/components/MemberCard.vue';
import MatchesOfTheDay from '@/components/MatchesOfTheDay.vue';

export default {
  components: {
    'search-header': SearchHeader,
    'main-footer': MainFooter,
    'member-card': MemberCard,
    'matches-of-the-day': MatchesOfTheDay
  },
  data() {
    return {
      members: JSON.parse(sessionStorage.getItem('members') || '[]'),
      nextPageUrl: null,
      isLoading: !sessionStorage.getItem('members'),
      token: sessionStorage.getItem('accessToken'),
      currentFilters: {},
      warnings: {},
      showMoreProfiles: true
    };
  },
  created() {
    if (!this.members.length) {
      this.fetchMembers();
    }
    this.fetchWarnings();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    dismissWarning(index) {
      delete this.warnings[index];
    },
    fetchWarnings() {
      axios.get(process.env.VUE_APP_API_URL + "member/warnings", {
        headers: {
          Authorization: `Bearer ${this.token}`
        },
      }).then((response) => {
        this.warnings = response.data.warnings;
      }).catch((error) => {
        console.error("Error fetching warnings: ", error);
      });
    },
    calculateAge(dateOfBirth) {

      if (!dateOfBirth) {
        return false;
      }

      const birthday = new Date(dateOfBirth);
      const today = new Date();
      let age = today.getFullYear() - birthday.getFullYear();
      const m = today.getMonth() - birthday.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
        age--;
      }

      return age.toString();
    },
    async fetchMembers(url = process.env.VUE_APP_API_URL + "members/search") {
      if (!this.isLoading) {
        this.isLoading = true;
        if (!url.includes('page=')) {
          this.members = [];
        }
      }

      try {

        const response = await axios.post(url, this.currentFilters, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        });
        const newMembers = response.data.data.map(member => {
          
          return member;
        });


        if (newMembers.length >= 10) {
          this.showMoreProfiles = true;
        } else {
          this.showMoreProfiles = false;
        }
        this.members = [...this.members, ...newMembers];
        this.nextPageUrl = response.data.next_page_url;
        sessionStorage.setItem('filters', JSON.stringify(this.currentFilters));
        sessionStorage.setItem('nextPageUrl', this.nextPageUrl);
        sessionStorage.setItem('members', JSON.stringify(this.members));
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
        this.scrollToBottom();
      }
    },

    scrollToBottom() {
      setTimeout(() => {
        const container = document.getElementById('contentContainer');
        container.scrollTop = container.scrollHeight;
      }, 0);
    },
    handleScroll() {
      let container = document.getElementById('contentContainer');
      if (window.innerHeight + window.scrollY >= container.offsetHeight - 10 && !this.isLoading && this.nextPageUrl) {
        this.loadMore();
      }
    },
    loadMore() {
      // console.log(sessionStorage.getItem('nextPageUrl'));
      // console.log(this.showMoreProfiles);
      if (sessionStorage.getItem('nextPageUrl') && this.showMoreProfiles) {
        if (sessionStorage.getItem('filters')) {
          this.currentFilters = JSON.parse(sessionStorage.getItem('filters'));
        }
        this.fetchMembers(sessionStorage.getItem('nextPageUrl'));
        sessionStorage.removeItem('nextPageUrl');
        sessionStorage.removeItem('filters');
      }
    },
    handleFilterChange(filters) {
      this.currentFilters = filters;
      sessionStorage.removeItem('members'); // Clear saved members before fetching new ones
      this.fetchMembers();
    },
    navigateToMember(memberId) {
      if (memberId) {
        this.$router.push({ name: 'MemberProfile', params: { memberId: memberId } });
      } else {
        console.error('Member ID is undefined.');
      }
    }
  },
};
</script>

