<template>
  <main-header heading="Delete Account"></main-header>

  <div class="container my-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6"> 
        
        <div v-if="showSuccessAlert" class="alert alert-success mt-3">
          Account deleted successfully.
        </div>
        <div v-if="showErrorAlert" class="alert alert-danger mt-3">
          Error deleting account. Please try again.
        </div>
        <h3>Delete Your Account</h3>
        <div class="alert alert-warning">
          You will no longer be able to access your account. Do you still want to delete your account?
          <form @submit.prevent="deleteAccount">
            <div class="mb-3">
              <label for="password" class="form-label">If yes, please enter your password to confirm account
                deletion:</label>
              <input type="password" class="form-control" id="password" v-model="password" required />
            </div>
            <div class="mb-3">
              <button type="submit" class="btn btn-danger">Delete My Account</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <main-footer></main-footer>
</template>

<script>
import axios from "axios";
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
  components: {
    'main-header': MainHeader,
    'main-footer': MainFooter
  },
  data() {
    return {
      password: "",
      showSuccessAlert: false,
      showErrorAlert: false,
    };
  },
  methods: {
    async deleteAccount() {
      try {
        const accessToken = sessionStorage.getItem('accessToken');

        const response = await axios.delete(process.env.VUE_APP_API_URL + "member/delete-account", {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
          data: {
            password: this.password,
          },
        });

        if (response.status === 200) {
          this.showSuccessAlert = true;
        } else {
          this.showErrorAlert = true;
        }
      } catch (error) {
        console.error(error);
        this.showErrorAlert = true;
      }
    },
    cancel() {
      // Redirect or handle cancel action here.
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}
</style>
