<template>
    <public-header></public-header>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div class="blog-posts">
                    <h3 class="my-4">Blog Posts</h3>
                    <!-- Show "No posts found" if posts count is zero -->
                    <div v-if="posts.length === 0" class="text-center">
                        <span>No posts found</span>
                    </div>
                    <div v-else>
                        <div v-for="post in posts" :key="post.id" class="card mb-3"
                            style="display: flex; flex-direction: row;">
                            <router-link :to="`/blog/${post.slug}`" class="d-flex"
                                style="text-decoration: none; color: inherit;">
                                <img :src="post.featured_image" class="img-fluid" style="width: 33%; border-radius: 5px;"
                                    alt="Blog Post Image">
                                <div class="card-body">
                                    <div class="text-muted" style="font-size: 0.9rem;">
                                        <i class="bi bi-clock-history"></i> {{ humanFriendlyDate(post.created_at) }} ·
                                        {{ post.read_time }} min read
                                    </div>
                                    <h5 class="card-title">{{ post.title }}</h5>
                                    <div class="d-flex align-items-center">
                                        <img v-if="post.user.photo_url" :src="post.user.photo_url" class="rounded-circle"
                                            style="width: 40px; height: 40px; margin-right: 10px;" alt="Author">
                                        <div>{{ post.user.name }}</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="loading" class="text-center">
                            <span>Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>  
<script>
import axios from 'axios';
import PublicHeader from "@/components/PublicHeader.vue"

export default {
    name: 'BlogPosts',
    components: {
        'public-header': PublicHeader
    },
    data() {
        return {
            posts: [],
            loading: false,
            page: 0,
            hasMore: true,
        };
    },
    methods: {
        async fetchPosts() {
            if (!this.hasMore) return;
            this.loading = true;
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + `blog/posts?page=${this.page}`);
                //console.log(response.data.data);
                if (response.data.data.length) {
                    this.posts.push(...response.data.data);
                    this.page++;
                } else {
                    this.hasMore = false;
                }
            } catch (error) {
                console.error('Failed to fetch posts:', error);
            } finally {
                this.loading = false;
            }
        },
        humanFriendlyDate(date) {
            return new Date(date).toLocaleDateString(undefined, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            });
        },
        setupInfiniteScroll() {
            window.addEventListener('scroll', this.onScroll);
        },
        onScroll() {
            let bottomOfWindow =
                document.documentElement.scrollTop + window.innerHeight ===
                document.documentElement.offsetHeight;

            if (bottomOfWindow) {
                this.fetchPosts();
            }
        },
    },
    mounted() {
        this.fetchPosts();
        this.setupInfiniteScroll();
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll);
    },
};
</script>
  
<style scoped>
.img-fluid{
    max-height: 200px;
}
/* Add any additional styling here */
</style>
  