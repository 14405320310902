<template>
  <public-header></public-header>

  <div class="container my-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <h3 class="mb-3">Forgot Your Password?</h3>
        <p>Please enter your registered email address or WhatsApp number so we can send you a link there.</p>
        <form @submit.prevent="submitForm">
          <div class="mb-3">
            <input type="text" class="form-control" placeholder="Please enter email or mobile number" v-model="userInput"
              :class="{ 'is-invalid': !isValid }" required>
            <small class="form-text">Please use the country code for mobile.</small>
            <div v-if="!isValid" class="invalid-feedback">Please enter a valid email or mobile number</div>
          </div>
          <!-- <button type="submit" class="btn btn-primary w-100" :disabled="isSubmitting">
            <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-if="!isSubmitting">Send Reset Instructions</span>
          </button> -->
          <button type="submit" class="btn btn-primary w-100" :disabled="isSubmitting">
            <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Send Reset Instructions
          </button>
          <div class="d-flex justify-content-between mt-4">
            <router-link to="/set-new-password" class="btn btn-secondary w-50 me-2">I already have codes</router-link>
            <router-link to="/login" class="btn btn-secondary w-50">Login</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PublicHeader from '@/components/PublicHeader.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default {
  components: {
    'public-header': PublicHeader,
  },
  data() {
    return {
      userInput: '',
      isValid: true,
      isSubmitting: false, // Add this line
    };
  },
  methods: {
    async submitForm() {
      this.isValid = this.isValidInput(this.userInput);

      if (!this.isValid) return;

      this.isSubmitting = true;

      try {
        await axios.post(process.env.VUE_APP_API_URL + 'member/reset-password', { contact: this.userInput });
        this.$router.push('/set-new-password');
      } catch (error) {
        // Handle error appropriately
      } finally {
        this.isSubmitting = false; // Re-enable the button once the server responds
      }
    },
    isValidInput(input) {
      if (!input.trim()) return false;

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const mobileRegex = /^\d{12,15}$/;

      return emailRegex.test(input.trim()) || mobileRegex.test(input.trim());
    }
  }
};
</script>

<style scoped></style>
