<template>
  <div id="app">
    <router-view></router-view>
    <WhatsAppIcon />
  </div>
</template>

<script>
import WhatsAppIcon from './components/WhatsAppIcon.vue';

export default {
  name: 'App',
  components: {
    WhatsAppIcon,
  },
};
</script>

<style scoped></style>
