<template>
    <main-header heading="Member Preferences"></main-header>

    <div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-6">
                    <div class="alert" :class="alertClass" v-if="message">{{ message }}</div>
                    <h3 class="mt-4">Set Your Interests</h3>
                    
                    <form @submit.prevent="updateInterests">
                        <div class="row">
                            <div class="col-6" v-for="(interest, index) in interestOptions" :key="index">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" :id="`interest${index}`"
                                        :value="interest" v-model="selectedInterests">
                                    <label class="form-check-label" :for="`interest${index}`">{{ interest }}</label>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary w-100 mt-3">Update Interests</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

  
<script>
import axios from "axios";
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter,
    },
    data() {
        return {
            interestOptions: [
                "Adventures",
                "Art",
                "Cooking",
                "Dancing",
                "Fashion",
                "Fitness",
                "Foodie",
                "Gaming",
                "Hiking",
                "Movies",
                "Music",
                "Nature",
                "Netflix",
                "Outdoor Activities",
                "Pets",
                "Photography",
                "Reading",
                "Religion",
                "Socializing",
                "Sports",
                "Traveling",
                "Yoga"
            ],
            totalInterests: [], // This will be fetched from the server
            selectedInterests: [],
            message: "",
            token: sessionStorage.getItem('accessToken'),
        };
    },
    computed: {
        alertClass() {
            return this.message.startsWith("Success") ? "alert-success" : "alert-danger";
        },
    },
    methods: {
        async updateInterests() {
            try {
                await axios.put(process.env.VUE_APP_API_URL + "member/interests/update", {
                    interests: this.selectedInterests,
                }, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });

                this.message = "Success: Interests updated successfully!";
            } catch (error) {
                this.message = `Error: ${error.response.data.message || "Failed to update interests."}`;
            }
        },
        async fetchInterests() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + "member/interests", {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });

                this.totalInterests = response.data.interests || [];
                // Preselect interests
                this.selectedInterests = this.interestOptions.filter(interest => this.totalInterests.includes(interest));
            } catch (error) {
                this.message = `Error: ${error.response.data.message || "Failed to fetch interests."}`;
            }
        },
    },
    mounted() {
        this.fetchInterests();
    },
};
</script>
