<template>
  <public-header></public-header>

  <div class="container my-4">
    <div v-if="serverResponse" class="alert" :class="{ 'alert-success': isSuccess, 'alert-danger': !isSuccess }"
      role="alert">
      {{ serverResponse }}
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <h3>Welcome Back</h3>
        <p>Login to continue</p>
        <form @submit.prevent="submitForm">
          <div class="mb-3">
            <input type="text" class="form-control" placeholder="Email Address" v-model="email"
              :class="{ 'is-invalid': errors.email }" required>
            <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
          </div>
          <div class="mb-3 position-relative">
            <input :type="isPasswordVisible ? 'text' : 'password'" class="form-control" placeholder="Password"
              v-model="password" :class="{ 'is-invalid': errors.password }" required>
            <div v-if="errors.password" class="invalid-feedback">{{ errors.password }}</div>
            <!-- Toggle Button -->
            <button type="button" class="btn btn-sm position-absolute top-50 end-0 translate-middle-y"
              @click="togglePasswordVisibility">
              <i :class="isPasswordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
            </button>
          </div>
          <div class="mb-3 text-end">
            <router-link to="/reset-password" class="text-decoration-none">Forget Password?</router-link>
          </div>
          <button type="submit" class="btn btn-primary w-100" :disabled="isLoading">
            <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Log in
          </button>
          <div class="my-2 w-100 d-flex justify-content-center align-items-center">
            OR
          </div>
          <GoogleLogin class="my-2 w-100" :callback="googleLoginCB" prompt></GoogleLogin>
          <hr class="my-4">
          <router-link to="/register" class="btn btn-secondary w-100">
            Register
          </router-link>
        </form>
        <p class="text-center mt-4">
          <router-link to="/page/terms-of-service" class="text-decoration-none">Terms of use</router-link>
          -
          <router-link to="/page/privacy-policy" class="text-decoration-none">Privacy Policy</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PublicHeader from "@/components/PublicHeader.vue"
import { decodeCredential } from 'vue3-google-login';

export default {
  components: {
    'public-header': PublicHeader
  },
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      isPasswordVisible: false,
      errors: {
        email: null,
        password: null,
      },
      isValid: true,
    };
  },
  created() {
    if (sessionStorage.getItem('accessToken')) {
      const redirect = '/members/search';
      this.$router.push(redirect);
    }
  },
  methods: {
    async googleLoginCB(response) {
      let user = decodeCredential(response.credential);
      try {
        const payload = {
          email: user.email,
          source: 'google'
        };
        const response = await axios.post(process.env.VUE_APP_API_URL + 'member/login/social', payload);
        let status = response.data.status;
        if (status == 'success') {

          let token = response.data.token;
          let user = response.data.user;

          sessionStorage.setItem('accessToken', token);
          sessionStorage.setItem('user', JSON.stringify(user));

          const redirectUrl = this.$route.query.redirect || '/members/search';

          if (user && user.active === '0') {
            this.$router.push('/member/account');
          } else {
            this.$router.push(redirectUrl);
          }
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.errors.social = error.response.data.error;
        } else {
          // Handle registration for non-existing users
          let newUser = {
            first_name: user.family_name,
            last_name: user.given_name,
            email: user.email,
            photo_url: user.picture,
            email_verified: true,
            source: 'google'
          };
          this.$router.push({
            path: '/register',
            query: { user: JSON.stringify(newUser) }
          });
        }
      }
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    validateEmail() {
      if (!this.email) {
        this.errors.email = 'Username is required';
        this.isValid = false;
      } else {
        this.errors.email = null;
        this.isValid = true;
      }
    },
    validatePassword() {
      if (!this.password) {
        this.errors.password = 'Password is required';
        this.isValid = false;
      } else {
        this.errors.password = null;
        this.isValid = true;
      }
    },
    checkFormValidity() {
      this.validateEmail();
      this.validatePassword();
    },
    async submitForm() {
      this.checkFormValidity();
      if (this.isValid) {
        try {
          this.isLoading = true;
          const payload = {
            email: this.email,
            password: this.password,
          };
          const response = await axios.post(process.env.VUE_APP_API_URL + 'members/login', payload);
          if (response.data.status == 'success') {
            const token = response.data.token;
            const user = response.data.user;

            sessionStorage.setItem('accessToken', token);
            sessionStorage.setItem('user', JSON.stringify(user));

            const redirectUrl = this.$route.query.redirect || '/members/search';

            if (user.active === 0) {
              this.$router.push('/member/account');
            } else {
              this.$router.push(redirectUrl);
            }

          } else {
            this.errors.email = "Invalid email or password.";
            this.isValid = false;
          }
          this.isLoading = false;
        } catch (error) {
          this.errors.email = error.response.data.error;
          this.isValid = false;
          this.isLoading = false;
        }
      }
    },
    clearErrors() {
      this.errors = {};
      this.isValid = true;
    }
  }
};
</script>
