<template>
    <main-header heading="Ignored Members"></main-header>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div class="my-requests">
                    <div v-if="requests.length > 0" class="list-group">
                        <div class="list-group-item" v-for="(request, index) in requests" :key="request.id">
                            <div class="d-flex justify-content-between">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <img :src="request.photo_url" class="rounded-circle me-3" alt="Member Avatar">
                                    <div class="flex-grow-1">
                                        <p class="mb-1">{{ request.name }}
                                            
                                        </p>
                                        <div>
                                            <button class="btn btn-secondary"
                                                @click="cancelRequest(request, index)">Cancel Ignore</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- Date and Cancel Button Column -->
                                <div class="col d-flex flex-column align-items-center justify-content-center">
                                    <span class="date-time">{{ getHumanFriendlyDate(request.ignored_at) }}</span>
                                </div>
                            </div>
                            <hr class="my-4">
                        </div>
                    </div>
                    <div v-else class="text-center mt-4">
                        <p>There are no ignored members.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    },
    setup() {
        const requests = ref([]);
        const requestDeleted = ref(false);
        // Fetch requests data
        const fetchRequests = async () => {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'member/get-ignored-members', {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                    }
                });
                requests.value = response.data.requests.map(request => {
                    //ADD DEFAULT PHOTO
                    
                    return {
                        ...request,
                    };
                });

            } catch (error) {
                console.error('Error fetching the requests:', error);
            }
        };

        // Call fetchRequests on component mount
        onMounted(fetchRequests);

        const cancelRequest = (request, index) => {
            request.deleting = true; // For animation purpose

            // Wait for the animation to finish before deleting
            setTimeout(() => {
                axios.post(process.env.VUE_APP_API_URL + 'member/cancel-ignored', { request_id: request.id }, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                    }
                }).then(() => {
                    // Remove the request from the list
                    requests.value.splice(index, 1);
                }).catch(error => {
                    console.error('Error cancelling the request:', error);
                    request.deleting = false;
                });
            }, 300);
        };

        const getHumanFriendlyDate = (dateInput) => {
            const currentDate = new Date();
            const inputDate = new Date(dateInput);
            const diffInSeconds = Math.floor((currentDate - inputDate) / 1000);

            if (diffInSeconds < 60) { // less than 1 minute
                return "Just now";
            } else if (diffInSeconds < 3600) { // less than 1 hour
                return Math.floor(diffInSeconds / 60) + " minutes ago";
            } else if (diffInSeconds < 86400) { // less than 1 day
                return Math.floor(diffInSeconds / 3600) + " hours ago";
            } else if (diffInSeconds < 604800) { // less than 1 week
                return Math.floor(diffInSeconds / 86400) + " days ago";
            } else {
                // For dates older than 1 week, return the date in a more detailed readable format
                // including the time to provide a clearer context
                return inputDate.toLocaleString("en-US", {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    // hour: '2-digit',
                    // minute: '2-digit',
                    // second: '2-digit'
                });
            }
        };

        const capitalizeStatus = (status) => {
            return status.charAt(0).toUpperCase() + status.slice(1);
        };

        return { requests, cancelRequest, getHumanFriendlyDate, capitalizeStatus, requestDeleted };
    }
};
</script>
  
  
<style scoped>
.member-requests-received {
    margin: auto;
}

.list-group-item {
    color: #000000;
    margin-bottom: 1rem;
    padding: 1rem;
}

.rounded-circle {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 50%; 
    object-fit: cover; 
}

.flex-grow-1 h5,
.flex-grow-1 small {
    margin-bottom: 0;
}

.form-check-input {
    cursor: pointer;
}

.btn-outline-danger {
    float: right;
}

/* Animation for deleting a request */
@keyframes fadeOut {
    from {
        opacity: 1;
        height: auto;
        padding: 1rem;
    }

    to {
        opacity: 0;
        height: 0;
        padding: 0;
    }
}

.animate-out {
    animation: fadeOut 300ms forwards;
}

.time-ago {
    color: #272727;
}
</style>