<template>
  <header class="navbar bg-light">
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-center w-100">
        <!-- Logo and Text on the Extreme Left -->
        <router-link to="/" class="navbar-brand d-flex align-items-center">
          <img src="../assets/images/logo.png" alt="Logo" class="logo" />
          <span class="website-text">PakSouls.com</span>
        </router-link>

        <!-- Spacer to push content to both ends -->
        <div class="flex-grow-1"></div>

        <!-- Dropdown Menu with Three Dots on the Extreme Right -->
        <div class="dropdown" ref="dropdown">
          <button class="btn" type="button" id="dropdownMenuButton" @click="toggleDropdown">
            <i class="fas fa-ellipsis-v"></i>
          </button>
          <ul class="dropdown-menu" :class="{ 'show': dropdownOpen }" aria-labelledby="dropdownMenuButton">
            <li><router-link to="/page/contact" class="dropdown-item">Contact</router-link></li>
            <li><router-link to="/blog" class="dropdown-item">Blog</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'PublicHeader',
  data() {
    return {
      dropdownOpen: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleDropdown(event) {
      event.stopPropagation();
      this.dropdownOpen = !this.dropdownOpen;
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
  },
};
</script>

<style scoped>
/* Ensures the dropdown aligns to the right and is not cut off */
.dropdown-menu {
  display: none;
  position: absolute;
  right: 0;
}

.dropdown-menu.show {
  display: block;
}

/* Ensure elements are visually as expected */
.logo {
  max-height: 50px;
}

.navbar-brand {
  display: flex;
  align-items: center;
}
</style>
