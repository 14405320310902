<template>
    <main-header heading="Verify Email"></main-header>

    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div v-if="successMessage" class="alert alert-success mt-3">{{ successMessage }}</div>
                <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
                <h3 class="mt-4">Verify Email Address</h3>
                <!-- Send Email Verification Codes link -->
                <div v-if="!verificationCodeSent" class="mb-3">
                    <a href="#" @click.prevent="sendVerificationCode">Send Email Verification Codes</a>
                </div>
                <!-- Verification Code input and Verify Code button are shown after the link is clicked -->
                <div v-if="isVerificationCodeVisible" class="mb-3">
                    <label for="verificationCode" class="form-label">Verification Code</label>
                    <input type="text" class="form-control" id="verificationCode" v-model="verificationCode"
                        placeholder="Enter the verification code" required>
                    <button class="btn btn-secondary mt-3" @click="verifyCode">Verify Code</button>
                </div>
                <hr class="my-4">
                <!-- Home Button linked to members/search -->
                <router-link class="btn btn-secondary w-100" to="/members/search">Home</router-link>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>
  
<script>
import { ref } from 'vue';
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter,
    },
    setup() {
        const verificationCode = ref('');
        const isVerificationCodeVisible = ref(false); // Controls visibility of verification code input and button
        const verificationCodeSent = ref(false); // Indicates if the verification code has been sent
        const successMessage = ref('');
        const errorMessage = ref('');

        const sendVerificationCode = () => {
            isVerificationCodeVisible.value = true; // Show verification code input and Verify button
            verificationCodeSent.value = true; // Indicate that the verification code has been sent

            axios.post(process.env.VUE_APP_API_URL + 'member/generate-verification-codes/', { text: 'email' }, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                }
            }).then(() => {
                // Handle success if needed
            }).catch(() => {
                errorMessage.value = 'There was an error sending the verification code. Please try again later.';
                isVerificationCodeVisible.value = false; // Optionally hide inputs again or handle differently
                verificationCodeSent.value = false; // Reset the send state in case of error
            });
        };

        const verifyCode = () => {
            axios.post(process.env.VUE_APP_API_URL + 'member/verify-codes/', { text: 'email', code: verificationCode.value }, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                }
            }).then(() => {
                successMessage.value = 'Your Email address has been verified successfully.';
                isVerificationCodeVisible.value = false; // Optionally hide the verification code input and button
                verificationCodeSent.value = false; // Ensure the send link doesn't show again after success
            }).catch(() => {
                errorMessage.value = 'There is an error verifying your Email address. Please try again later.';
            });
        };

        return { verificationCode, isVerificationCodeVisible, verificationCodeSent, successMessage, errorMessage, sendVerificationCode, verifyCode };
    }
};
</script>
  
<style></style>
