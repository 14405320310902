<template>
  <footer class="main-footer bg-light fixed-bottom" :class="{ 'hidden': isHidden }">
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-3">
          <router-link to="/members/search/" class="btn btn-login">
            <i class="bi bi-house"></i><br /> Home
          </router-link>
        </div>
        <div class="col-3">
          <router-link to="/member/management/" class="btn btn-login">
            <i class="bi bi-people"></i><br /> Members
          </router-link>
        </div>
        <div class="col-3">
          <router-link to="/member/chat/" class="btn btn-login">
            <i class="bi bi-chat-dots"></i><br /> Chat
          </router-link>
        </div>
        <div class="col-3">
          <router-link to="/member/account/" class="btn btn-login">
            <i class="bi bi-person"></i><br /> Account
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>
  
<script>
export default {
  name: 'MainFooter',
  data() {
    return {
      isHidden: false,
      lastScrollPosition: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollPosition > this.lastScrollPosition) {
        this.isHidden = true;
      } else {
        this.isHidden = false;
      }

      this.lastScrollPosition = currentScrollPosition;
    },
  },
}
</script>

<style scoped>
.btn-login{
  font-size: 12px;
}
.main-footer {
  border-top:1px solid #DEE2E6;
  transition: transform 0.3s ease-in-out;
}

.hidden {
  transform: translateY(100%);
}
</style>


  