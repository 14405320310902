<template>
  <main-header heading="Upload Photos"></main-header>

  <div class="container my-4">
    <h3 class="mt-4">Upload Your Photos</h3>

    <div class="row justify-content-center">
      <div v-for="(photo, index) in uploadedPhotos" :key="photo.id" class="col-6 mb-4">
        <div class="image-upload-box" @click="openSlider(index)">
          <img :src="photo.url" class="card-img-top" alt="Uploaded Image" />
          <button class="rotate-slider" @click.stop="rotatePhoto(photo.id)">
            <i class="bi bi-arrow-clockwise"></i>
          </button>
          <button class="close-slider" @click.stop="deletePhoto(photo.id, index)">
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div>

      <div class="col-6 mb-4">
        <file-pond class="image-upload-box" name="photo" ref="pond" label-idle="+" :allow-multiple="false"
          accepted-file-types="image/jpeg, image/png" :server="serverConfig" @init="handleFilePondInit" />
      </div>
    </div>

    <!-- Popup Slider -->
    <div v-if="showSlider" class="slider-overlay">
      <Carousel :itemsToShow="1" :wrapAround="true">
        <Slide v-for="photo in uploadedPhotos" :key="photo.id">
          <img :src="photo.url" class="slider-image">
        </Slide>
      </Carousel>
      <button class="rotate-slider" @click="rotatePhoto(photo.id)">
        <i class="bi bi-arrow-clockwise"></i>
      </button>
      <button class="close-slider" @click="showSlider = false">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>
  </div>
  <main-footer></main-footer>
</template>


<script>
import axios from "axios";
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  components: {
    FilePond,
    'main-header': MainHeader,
    'main-footer': MainFooter,
    Carousel,
    Slide
  },
  data() {
    return {
      uploadedPhotos: [], // Array to store uploaded photo objects
      serverConfig: {
        process: {
          url: process.env.VUE_APP_API_URL + 'member/photos/upload',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
          },
          onload: (response) => {
            const photo = JSON.parse(response).photo;
            this.uploadedPhotos.push(photo);
          },
          onerror: response => this.handleError(response),
          allowRevert: false, // Disable the revert option in FilePond
        },
      },
      showSlider: false,
      currentSlideIndex: 0
    };
  },
  methods: {
    handleFilePondInit() {
      console.log("FilePond has initialized");
    },
    handleError(response) {
      console.error("File upload error:", response);
    },
    rotatePhoto(photoId) {
      const rotateDegree = 90; // Rotation degree
      axios.post(`${process.env.VUE_APP_API_URL}member/rotate-photo/${photoId}`, {
        id: photoId,
        degrees: rotateDegree
      }, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          // Assuming the response contains the updated photo URL
          const updatedPhotoUrl = response.data.photo.url;
          const photoIndex = this.uploadedPhotos.findIndex(photo => photo.id === photoId);
          if (photoIndex !== -1) {
            // Append timestamp to the URL to prevent caching
            this.uploadedPhotos[photoIndex].url = `${updatedPhotoUrl}?timestamp=${new Date().getTime()}`;
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    deletePhoto(photoId, index) {
      // Make a DELETE request to the server
      fetch(`${process.env.VUE_APP_API_URL}member/delete-photo/${photoId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
        .then(response => {
          if (response.ok) {
            // Remove the photo from the uploadedPhotos array
            this.uploadedPhotos.splice(index, 1);
          } else {
            throw new Error('Failed to delete photo');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    fetchPhotos() {
      fetch(`${process.env.VUE_APP_API_URL}member/photos`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to fetch photos');
          }
        })
        .then(data => {
          // Append a timestamp query parameter to each photo URL to prevent caching
          const photosWithTimestamp = data.photos.map(photo => {
            const timestamp = new Date().getTime(); // Get current timestamp
            photo.url += `?timestamp=${timestamp}`; // Append timestamp to the photo URL
            return photo;
          });
          this.uploadedPhotos = photosWithTimestamp;
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    openSlider(index) {
      this.currentSlideIndex = index;
      this.showSlider = true;
    },
    closeSlider() {
      this.showSlider = false;
    }
  },
  mounted() {
    this.fetchPhotos();
  },
};
</script>
<style scoped>
.image-upload-box {
  border: 1px solid transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  aspect-ratio: 1 / 1;
}

.image-upload-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-upload-box:not(.filepond--root) {
  border: 1px dashed #ccc;
  /* dashed border for uploaded photos */
}

.card-img-top {
  height: 100%;
  object-fit: cover;
}

.btn-close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.slider-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-image {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
}

.close-slider {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  /* Adjust based on your layout */
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0.5rem;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate-slider {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  /* Adjust based on your layout */
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0.5rem;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>