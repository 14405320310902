<template>
  <header class="sticky-top d-flex justify-content-between align-items-center bg-light p-2">
    <button class="btn" @click="goBack">
      <i class="bi bi-arrow-left"></i>
    </button>
    <h5>Search Results</h5>
    <div>
      <!-- <button class="btn" :class="{ 'highlighted': showNotifications }" @click="toggleNotifications">
        <i class="bi bi-bell"></i>
      </button> -->
      <ul v-if="showNotifications" class="dropdown-menu">
        <li v-for="notification in notifications" :key="notification.id" class="dropdown-item text-truncate">
          <a href ="#">{{ notification.data.text }}</a>
        </li>
      </ul>
      <button class="btn" @click="toggleBottomSheet">
        <i class="bi bi-search"></i>
      </button>
      <div v-if="showBellDropdown" class="bell-dropdown">
        <!-- Dropdown content goes here -->
      </div>
    </div>
  </header>
  <warning-popup></warning-popup>
  <bottom-sheet ref="bottomSheet" @apply-filters="handleApplyFilters"></bottom-sheet>
</template>

<script>
import axios from 'axios';

import WarningPopup from './WarningPopup.vue';
import BottomSheet from './BottomSheet.vue';

export default {
  emits: ['filters-changed'],
  components: {
    'warning-popup': WarningPopup,
    'bottom-sheet': BottomSheet
  },
  data() {
    return {
      showNotifications: false,
      notifications: [],
      hideTimer: null,
    };
  },
  methods: {
    goBack() {
      if (window.history.length > 1) {
        this.$router.back();
      } else {
        this.$router.push('/');
      }
    },
    toggleBottomSheet() {
      if (this.$refs.bottomSheet) {
        this.$refs.bottomSheet.toggleSheet();
      }
    },
    toggleBellDropdown() {
      this.showBellDropdown = !this.showBellDropdown;
      if (this.showBellDropdown) {

        setTimeout(() => {
          this.showBellDropdown = false;
        }, 5000);
      }
    },
    handleApplyFilters(filters) {
      this.$emit('filters-changed', filters);
    },
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
      if (this.showNotifications) {
        this.fetchNotifications();
      }
    },
    fetchNotifications() {
      const accessToken = sessionStorage.getItem('accessToken');

      axios.get(process.env.VUE_APP_API_URL + 'member/notifications', { headers: { Authorization: `Bearer ${accessToken}` } })
        .then(response => {
          this.notifications = response.data;
        })
        .catch(error => console.error('Error fetching notifications:', error));
    },
  }
};
</script>

<style scoped>
header {
  top: 0;
  z-index: 1020;
  border-bottom: 1px solid #DEE2E6;
}


</style>
