<template>
    <div id="subscription-paypal">
        <button @click="createPayment">Pay with PayPal</button>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "SubscriptionPayPal",

    data() {
        return {
            accessToken: sessionStorage.getItem('accessToken')
        };
    },
    methods: {
        requestPayment() {
            const headers = {
                "Authorization": "Bearer " + this.accessToken,
            };

            const paymentDetails = {
                subtotal: '10.00',
                shipping_discount: '0.00',
                total: '10.00',
                items: [
                    {
                        name: 'Item Name',
                        price: '10.00',
                        qty: 1
                    }
                ]
            };

            // Correctly include headers in the config object
            axios.post(process.env.VUE_APP_API_URL + 'paypal/create-payment', paymentDetails, { headers: headers })
                .then(response => {
                    if (response.data.approval_url) {
                        // Redirect user to PayPal approval URL
                        window.location.href = response.data.approval_url;
                    } else {
                        // Handle the case where no approval URL is returned
                        console.error('No approval URL returned');
                    }
                })
                .catch(error => {
                    // Handle errors
                    console.error('Error creating the payment:', error);
                });
        },
        executePayment(paymentId, payerId) {
            // Call your Laravel backend to execute the payment
            axios.post(process.env.VUE_APP_API_URL + 'paypal/execute-payment', { paymentId, payerId })
                .then(response => {
                    // Handle the payment execution response
                    // For example, show a success message or redirect
                    console.log('Payment successful:', response.data);
                })
                .catch(error => {
                    console.error("Error executing the payment: ", error);
                });
        }
    },
    created() {
        this.requestPayment();
    },
    // mounted() {
    //     // Check URL parameters for paymentId and PayerID (PayPal redirects back after payment)
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const paymentId = urlParams.get('paymentId');
    //     const payerId = urlParams.get('PayerID');

    //     if (paymentId && payerId) {
    //         this.executePayment(paymentId, payerId);
    //     }
    // }
};
</script>

<style>
/* Add your CSS styling if needed */
</style>