<template>
    <div v-if="showWarning" class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"><i class="bi bi-exclamation-triangle-fill"></i> Warning</h5>
                    <button type="button" class="btn-close" @click="hidePopup"></button>
                </div>
                <div class="modal-body">
                    <p>{{ warningMessage }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="acknowledgeWarning">Acknowledge</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    data() {
        return {
            showWarning: false,
            warningMessage: '',
        };
    },
    mounted() {
        this.fetchWarning();
    },
    methods: {
        fetchWarning() {
            const accessToken = sessionStorage.getItem('accessToken');
            axios.get(process.env.VUE_APP_API_URL + 'member/warning', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then(response => {
                    if (response.data.warning) {
                        this.warningMessage = response.data.warning;
                        this.showWarning = true;
                    }
                })
                .catch(error => console.error('There was a problem fetching the warning:', error));
        },
        acknowledgeWarning() {
            const accessToken = sessionStorage.getItem('accessToken');
            axios.post(process.env.VUE_APP_API_URL + 'member/warning/acknowledge', {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then(() => {
                    this.showWarning = false;
                })
                .catch(error => console.error('There was a problem acknowledging the warning:', error));
        },
        hidePopup() {
            this.showWarning = false;
        },
    },
};
</script>

<style scoped>
/* Add any additional styling you want for the warning popup here */
</style>
