<template>
  <header class="chat-header bg-light p-2">
    <div class="d-flex align-items-center">
      <button class="btn" @click="goBack">
        <i class="bi bi-arrow-left"></i>
      </button>
      <img :src="chatUser.photo_url" class="rounded-circle" :alt="chatUser.name">
      <div class="user-info">
        <h5 style="font-size: 16px">{{ chatUser.name }} <router-link :to="`/members/search/${chatUser.id}`"
            class="view-profile">View profile</router-link></h5>
        <p v-if="chatUser.last_message">{{ getHumanFriendlyDate(chatUser.last_message.created_at) }}</p>
      </div>
    </div>
  </header>
  <warning-popup></warning-popup>
</template>

<script>
import WarningPopup from './WarningPopup.vue';

export default {
  components: {
    'warning-popup': WarningPopup
  },
  props: {
    chatUser: Object,
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getHumanFriendlyDate(dateInput) {
      const currentDate = new Date();
      const inputDate = new Date(dateInput);
      const diffInSeconds = Math.floor((currentDate - inputDate) / 1000);

      if (diffInSeconds < 60) { // less than 1 minute
        return "Just now";
      } else if (diffInSeconds < 3600) { // less than 1 hour
        return Math.floor(diffInSeconds / 60) + " minutes ago";
      } else if (diffInSeconds < 86400) { // less than 1 day
        return Math.floor(diffInSeconds / 3600) + " hours ago";
      } else if (diffInSeconds < 604800) { // less than 1 week
        return Math.floor(diffInSeconds / 86400) + " days ago";
      } else {
        // For dates older than 1 week, return the date in a readable format
        return inputDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });
      }
    }
  }
};
</script>

<style scoped>
.chat-header {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #DEE2E6;
}

.rounded-circle {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
}

.user-info h5,
.user-info p {
  margin: 0;
  padding-left: 10px;
}

.user-info p {
  font-size: 0.9rem;
  color: #6c757d;
}

.view-profile {
  font-size: 11px;
  font-weight: 400;
  text-decoration: none;
}
</style>

