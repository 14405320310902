<template>
    <main-header heading="Shortlisted Matches"></main-header>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div class="member-requests-received">
                    <div v-if="requests.length > 0" class="list-group">
                        <div class="col-6 col-md-4 d-flex justify-content-center" v-for="member in requests"
                            :key="member.id">
                            <member-card :memberId="member.id" :full_name="member.first_name + ' ' + member.last_name"
                                :age="calculateAge(member.date_of_birth)" :marital_status="member.marital_status"
                                :location="member.city + ', ' + member.country" :gender="member.gender"
                                :photo_url="member.photo_url" @memberClicked="navigateToMember(member.id)" />
                        </div>
                    </div>
                    <div v-else class="text-center mt-4">
                        <p>There are no shortlisted matches for your profile. You can search profiles
                            <router-link to="/members/search">here</router-link>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>
  
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
import MemberCard from '@/components/MemberCard.vue';
import { useRouter } from 'vue-router'; 

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter,
        'member-card': MemberCard,
    },
    setup() {
        const router = useRouter();
        // Define the reactive variables
        const requestApproved = ref(false);
        const requestDenied = ref(false);
        const requests = ref([]);
        // Fetch requests data from the server
        const fetchRequests = async () => {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'member/get-shortlisted-matches', {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                    }
                });
                requests.value = response.data.requests.map(request => {
                    //ADD DEFAULT PHOTO
                    
                    //ITEMS
                    const items = request.items.split(',');
                    return {
                        ...request,
                        hideProfile: items.includes('profile'),
                        hidePhotos: items.includes('photos'),
                        hideChat: items.includes('chat')
                    };
                });
                console.log(requests);
            } catch (error) {
                console.error('Error fetching the requests:', error);
            }
        };

        onMounted(fetchRequests);

        // Approve a specific request
        const approveRequest = async (request, index) => {
            try {
                const requestData = {
                    request_id: request.id,
                };
                if (request.items == 'profile') {
                    requestData.profile = true;
                } else if (request.items == 'photos') {
                    requestData.photos = true;
                } else if (request.items == 'chat') {
                    requestData.chat = true;
                }
                await axios.post(process.env.VUE_APP_API_URL + 'member/approve-request', requestData, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                    }
                });
                requests.value[index].status = 'approved';
                requestApproved.value = true;
            } catch (error) {
                console.error('Error approving the request:', error);
            }
        };
        // Deny a specific request
        const denyRequest = async (request, index) => {
            try {
                await axios.post(process.env.VUE_APP_API_URL + 'member/deny-request', { request_id: request.id }, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                    }
                });
                requests.value.splice(index, 1);
                requestDenied.value = true;
            } catch (error) {
                console.error('Error denying the request:', error);
            }
        };
        const calculateAge = (dateOfBirth) => {

            if (!dateOfBirth) {
                return false;
            }

            const birthday = new Date(dateOfBirth);
            const today = new Date();
            let age = today.getFullYear() - birthday.getFullYear();
            const m = today.getMonth() - birthday.getMonth();

            if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
                age--;
            }

            return age.toString();
        };
        const navigateToMember = (memberId) => {
            if (memberId) {
                router.push({ name: 'MemberProfile', params: { memberId: memberId } });
            } else {
                console.error('Member ID is undefined.');
            }
        };
        // Format a date string
        const getHumanFriendlyDate = (dateInput) => {
            const currentDate = new Date();
            const inputDate = new Date(dateInput);
            const diffInSeconds = Math.floor((currentDate - inputDate) / 1000);

            if (diffInSeconds < 60) { // less than 1 minute
                return "Just now";
            } else if (diffInSeconds < 3600) { // less than 1 hour
                return Math.floor(diffInSeconds / 60) + " minutes ago";
            } else if (diffInSeconds < 86400) { // less than 1 day
                return Math.floor(diffInSeconds / 3600) + " hours ago";
            } else if (diffInSeconds < 604800) { // less than 1 week
                return Math.floor(diffInSeconds / 86400) + " days ago";
            } else {
                // For dates older than 1 week, return the date in a readable format
                return inputDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });
            }
        };

        // Capitalize the status
        const capitalizeStatus = (status) => {
            if (!status) return '';
            return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        };
        // Check if any switch is on
        const isAnySwitchOn = (request) => {
            return request.hideProfile || request.hidePhotos || request.hideChat;
        };
        return {
            requests,
            denyRequest,
            approveRequest,
            calculateAge,
            getHumanFriendlyDate,
            capitalizeStatus,
            navigateToMember,
            isAnySwitchOn,
            requestApproved,
            requestDenied
        };
    }
};
</script>
<style scoped>
.member-requests-received {
    margin: auto;
}

.list-group-item {
    color: #000000;
    margin-bottom: 1rem;
    padding: 1rem;
}

.rounded-circle {
    width: 75px;
    height: 75px;
    object-fit: cover;
}

.flex-grow-1 h5,
.flex-grow-1 small {
    margin-bottom: 0;
}

.form-check-input {
    cursor: pointer;
}

.btn-outline-danger {
    float: right;
}

/* Animation for deleting a request */
@keyframes fadeOut {
    from {
        opacity: 1;
        height: auto;
        padding: 1rem;
    }

    to {
        opacity: 0;
        height: 0;
        padding: 0;
    }
}

.animate-out {
    animation: fadeOut 300ms forwards;
}

.time-ago {
    color: #272727;
}
</style>
