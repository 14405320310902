import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/global.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import vue3GoogleLogin from 'vue3-google-login'

//firebase
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
        }).catch((err) => {
            console.log('Service Worker registration failed:', err);
        });
}


// Create the app instance
const app = createApp(App);
app.use(router);
app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_LOGIN_CLIENT_ID
});
router.afterEach((to) => {
    // Ensure Google Analytics is defined
    if (typeof window.gtag !== "undefined") {
      window.gtag('config', 'G-0FEFZCEL2S', {
        'page_path': to.path,
        'page_title': to.name || 'Unknown Page'
      });
    }
  });
app.mount('#app');
