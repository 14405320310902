<template>
  <public-header></public-header>
  <div class="container my-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <h3 class="my-4">Register Your Account</h3>

        <div v-if="!(currentStep === 9 || (currentStep === 8 && source !== 'web'))"
          class="mb-4 d-flex justify-content-center">
          <span v-for="step in (source !== 'web' ? 7 : 8)" :key="step" class="wizard-circle"
            :class="{ 'active': currentStep === step }" @click="goToStep(step)">{{ step }}</span>
        </div>
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div v-if="currentStep === 1">
            <div class="row">
              <div class="col-12">
                <div class="mb-3">
                  <p>Welcome to PakSouls.com! Registering with us is simple and straightforward. Follow instructions
                    under
                    fields to create your profile.</p>
                </div>
                <!-- <div class="my-4">
                  <p>Select a package below:</p>
                  <div class="mb-3 d-flex align-items-center" v-for="pkg in packages" :key="pkg.id">
                    <input class="form-check-input me-3" type="radio" :id="'package-' + pkg.id" :value="pkg.id"
                      v-model="package_id">
                    <label class="form-check-label me-3" :for="'package-' + pkg.id">
                      <h6>{{ pkg.title }}</h6>
                      <div>
                        <p>
                          {{ pkg.profiles }} Profiles.
                          <span class="text-success">{{ pkg.discounted_price_pkr }}</span>&nbsp;<span class="text-decoration-line-through">{{ pkg.price_pkr }}</span>&nbsp;/&nbsp;<span class="text-success">{{ pkg.discounted_price_usd }}</span>&nbsp;<span
                            class="text-decoration-line-through">{{ pkg.price_usd }}</span>&nbsp;
                          <span v-if="pkg.id !== 1">per month</span>
                        </p>

                      </div>
                    </label>
                  </div>
                </div> -->

                <div class="mb-3">
                  <input type="checkbox" id="terms_of_use" class="form-check-input" v-model="agreed_to_terms"
                    :class="{ 'is-invalid': errors.agreed_to_terms }" required>&nbsp;
                  <label for="terms_of_use" class="form-label">Agree to Terms of Service</label><br />
                  <small v-if="errors.agreed_to_terms" class="invalid-feedback">{{ errors.agreed_to_terms }}</small>
                  <small>By checking this box, you acknowledge and agree to our <a href="/page/terms-of-service">Terms
                      of
                      Use</a>.</small>
                </div>

                <!-- Referral Code Input -->
                <a v-if="!showAffiliateCode" href="#" @click.prevent="toggleAffiliateCodeVisibility"
                  class="text-primary" style="cursor: pointer;">Enter Referral Code</a>
                <div v-if="showAffiliateCode" class="mb-3">
                  <label for="affiliate_code" class="form-label">Referral Code (Optional)</label>
                  <input type="text" id="affiliate_code" class="form-control" v-model="affiliate_code"
                    :class="{ 'is-invalid': errors.affiliate_code }">
                  <small v-if="errors.affiliate_code" class="invalid-feedback">{{ errors.affiliate_code }}</small>
                  <small>Enter a referral code if you have one.</small>
                </div>
              </div>
            </div>
          </div>
          <!-- Form Wizard Screen 1 -->
          <div v-if="currentStep === 2">
            <div class="row">
              <div class="col-12">
                <p class="my-4">Enter your account information.</p>
                <div class="mb-3">
                  <label for="first_name" class="form-label">First Name</label>
                  <input type="text" id="first_name" class="form-control" v-model="first_name"
                    :class="{ 'is-invalid': errors.first_name }" required>
                  <div v-if="errors.first_name" class="invalid-feedback">{{ errors.first_name }}</div>
                </div>
                <div class="mb-3">
                  <label for="last_name" class="form-label">Last Name</label>
                  <input type="text" id="last_name" class="form-control" v-model="last_name"
                    :class="{ 'is-invalid': errors.last_name }" required>
                  <div v-if="errors.last_name" class="invalid-feedback">{{ errors.last_name }}</div>
                </div>
                <div class="mb-3">
                  <label for="gender" class="form-label">Gender</label>
                  <select id="gender" class="form-select" v-model="gender" :class="{ 'is-invalid': errors.gender }"
                    required>
                    <option value="" disabled>Select Gender</option>
                    <option v-for="genderOption in genderOptions" :key="genderOption.value" :value="genderOption.value">
                      {{ genderOption.text }}
                    </option>
                  </select>
                  <div v-if="errors.gender" class="invalid-feedback">{{ errors.gender }}</div>
                </div>
                <div class="mb-3">
                  <label for="maritalStatus" class="form-label">Marital Status</label>
                  <select id="maritalStatus" class="form-select" v-model="marital_status"
                    :class="{ 'is-invalid': errors.marital_status }" required>
                    <option value="" disabled>Select Marital Status</option>
                    <option v-for="status in maritalStatusOptions" :key="status.value" :value="status.value">
                      {{ status.text }}
                    </option>
                  </select>
                  <div v-if="errors.marital_status" class="invalid-feedback">{{ errors.marital_status }}</div>
                </div>
                <div class="mb-3">
                  <label for="dateOfBirth" class="form-label">Date of Birth</label>
                  <div style="display: flex; justify-content: space-between;">
                    <!-- Inline style for side-by-side layout -->
                    <!-- Year Dropdown -->
                    <select v-model="year" @change="updateDays" class="form-control"
                      :class="{ 'is-invalid': errors.date_of_birth }" required style="width: 30%;">
                      <option value="">Year</option>
                      <option v-for="year in years" :value="year" :key="'year-' + year">{{ year }}</option>
                    </select>

                    <!-- Month Dropdown -->
                    <select v-model="month" @change="updateDays" class="form-control"
                      :class="{ 'is-invalid': errors.date_of_birth }" required style="width: 30%;">
                      <option value="">Month</option>
                      <option v-for="(month, index) in months" :value="index + 1" :key="'month-' + month">{{ month }}
                      </option>
                    </select>

                    <!-- Day Dropdown -->
                    <select v-model="day" class="form-control" :class="{ 'is-invalid': errors.date_of_birth }" required
                      style="width: 30%;">
                      <option value="">Day</option>
                      <option v-for="day in daysInMonth" :value="day" :key="'day-' + day">{{ day }}</option>
                    </select>

                  </div>
                  <div v-if="errors.date_of_birth" class="invalid-feedback">{{ errors.date_of_birth }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Form Wizard Screen 2 -->
          <div v-if="currentStep === 3">
            <div class="row">
              <div class="col-12">
                <p class="my-4">Enter your contact and addressing information.</p>
                <div class="mb-3">
                  <label for="email" class="form-label">Email Address</label>
                  <input type="email" id="email" class="form-control" v-model="email"
                    :class="{ 'is-invalid': errors.email }" @change="validateAndVerifyEmail" required>
                  <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
                </div>
                <div class="mb-3">
                  <label for="whatsapp" class="form-label">WhatsApp Number</label>
                  <div class="row">
                    <div class="col-4">
                      <select id="countryCode" class="form-control" v-model="countryCode">
                        <option v-for="option in dialingCodeOptions" :value="option.code" v-bind:key="option.code">
                          {{ option.name }} ({{ option.code }})
                        </option>
                      </select>
                    </div>
                    <div class="col-8">
                      <input type="tel" id="whatsapp" class="form-control" v-model="whatsapp"
                        :class="{ 'is-invalid': errors.whatsapp }" @change="validateAndVerifyPhoneNumber" required>
                      <div v-if="errors.whatsapp" class="invalid-feedback">{{ errors.whatsapp }}</div>
                    </div>
                  </div>
                  <small>Enter your phone number without leading zeros or country code.</small>
                </div>
                <div class="mb-3">
                  <label for="country" class="form-label">Country</label>
                  <select id="country" class="form-select" v-model="country" :class="{ 'is-invalid': errors.country }"
                    required>
                    <option value="" disabled>Select Country</option>
                    <option v-for="countryOption in countryOptions" :key="countryOption" :value="countryOption">
                      {{ countryOption }}
                    </option>
                  </select>
                  <div v-if="errors.country" class="invalid-feedback">{{ errors.country }}</div>
                </div>
                <div class="mb-3">
                  <label for="city" class="form-label">City</label>
                  <div v-if="country === 'Pakistan'">
                    <select id="city" class="form-select" v-model="city" :class="{ 'is-invalid': errors.city }"
                      required>
                      <option value="" disabled>Select City</option>
                      <option v-for="cityOption in pakistanCities" :key="cityOption" :value="cityOption">
                        {{ cityOption }}
                      </option>
                    </select>
                  </div>
                  <div v-else>
                    <input type="text" id="city" class="form-control" v-model="city"
                      :class="{ 'is-invalid': errors.city }" required>
                  </div>
                  <div v-if="errors.city" class="invalid-feedback">{{ errors.city }}</div>
                </div>

              </div>
            </div>
          </div>
          <!-- Form Wizard Screen 3 -->
          <div v-if="currentStep === 4">
            <div class="row">
              <div class="col-12">
                <p class="my-4">Enter your education and professional details.</p>
                <!-- Occupation -->
                <div class="mb-3">
                  <label for="occupation" class="form-label">Occupation</label>
                  <input type="text" id="occupation" class="form-control" v-model="occupation"
                    :class="{ 'is-invalid': errors.occupation }" required>
                  <div v-if="errors.occupation" class="invalid-feedback">{{ errors.occupation }}</div>
                </div>

                <!-- Education -->
                <div class="mb-3">
                  <label for="education" class="form-label">Education</label>
                  <select id="education" class="form-select" v-model="education"
                    :class="{ 'is-invalid': errors.education }" required>
                    <option value="" disabled>Select Education Level</option>
                    <option v-for="educationOption in educationOptions" :key="educationOption.value"
                      :value="educationOption.value">
                      {{ educationOption.text }}
                    </option>
                  </select>
                  <div v-if="errors.education" class="invalid-feedback">{{ errors.education }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Form Wizard Screen 4 -->
          <div v-if="currentStep === 5">
            <div class="row">
              <div class="col-12">
                <p class="my-4">Select your religion and caste.</p>
                <div class="mb-3">
                  <label for="religion" class="form-label">Religion</label>
                  <select id="religion" class="form-select" v-model="religion"
                    :class="{ 'is-invalid': errors.religion }" required>
                    <option value="" disabled>Select Religion</option>
                    <option v-for="religionOption in religionOptions" :key="religionOption.value"
                      :value="religionOption.value">
                      {{ religionOption.text }}
                    </option>
                  </select>
                  <div v-if="errors.religion" class="invalid-feedback">{{ errors.religion }}</div>
                </div>
                <div class="mb-3">
                  <label for="caste" class="form-label">Caste</label>
                  <select id="caste" class="form-select" v-model="caste" :class="{ 'is-invalid': errors.caste }"
                    required>
                    <option value="" disabled>Select Caste</option>
                    <option v-for="casteOption in casteOptions" :key="casteOption" :value="casteOption">
                      {{ casteOption }}
                    </option>
                  </select>
                  <div v-if="errors.caste" class="invalid-feedback">{{ errors.caste }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Form Wizard Screen 5 -->
          <div v-if="currentStep === 6">
            <div class="row">
              <div class="col-12">
                <p class="my-4">Enter requirements for your match.</p>
                <div class="mb-3">
                  <label for="profile" class="form-label">Match Preferences</label>
                  <textarea rows="5" id="profile" class="form-control" v-model="profile"
                    :class="{ 'is-invalid': errors.profile }" required maxlength="2000"></textarea>
                  <small class="text-muted">Do not add phone number, whatsapp or email address.</small>
                  <div v-if="errors.profile" class="invalid-feedback">{{ errors.profile }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Form Wizard Screen 6 -->
          <div v-if="currentStep === 7">
            <div class="row">
              <div class="col-12">
                <p class="my-4">Upload your profile photo (face should be visible).</p>
                <div class="mb-3">
                  <label for="photo" class="form-label">Upload Photo</label>
                  <input type="file" id="photo" class="form-control" @change="handleFileUpload"
                    :class="{ 'is-invalid': errors.photo }" accept="image/*">
                  <div v-if="errors.photo" class="invalid-feedback">{{ errors.photo }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Form Wizard Screen 8 -->
          <div v-if="currentStep === 8 && source == 'web'">
            <div class="row">
              <div class="col-12">
                <p class="my-4">Enter your account password.</p>
                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>
                  <input :type="isPasswordVisible ? 'text' : 'password'" id="password" class="form-control"
                    v-model="password" :class="{ 'is-invalid': errors.password }">
                  <div v-if="errors.password" class="invalid-feedback">{{ errors.password }}</div>
                  <!-- Toggle Button -->
                  <button type="button" style="margin-top:-20px"
                    class="btn btn-sm position-absolute end-0 translate-middle-y" @click="togglePasswordVisibility">
                    <i :class="isPasswordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                  </button>
                </div>
                <div class="mb-3">
                  <label for="confirmPassword" class="form-label">Confirm Password</label>
                  <input :type="isPasswordVisible ? 'text' : 'password'" id="confirmPassword" class="form-control"
                    v-model="confirmPassword" :class="{ 'is-invalid': errors.confirmPassword }">
                  <div v-if="errors.confirmPassword" class="invalid-feedback">{{ errors.confirmPassword }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Form Wizard Screen 8 -->
          <div v-if="(source !== 'web' && currentStep === 8) || currentStep === 9">
            <div class="row">
              <div class="col-12">
                <p class="my-4">Registration Almost Complete!</p>
                <div v-if="registrationComplete" class="alert alert-success mt-3">
                  <p class="my-4">
                    <!-- Please make thxe payment for registration while we verify your profile. Once the payment is received
                    and your profile is verified, you will be able to log in to PakSouls.com and start finding your
                    match. Thank you for your patience!
                    <a :href="subscription.invoice_url">Download your invoice here.</a> -->
                    Congratulations on registering! Your profile is now under review. We will contact you shortly for verification. Once approved, you'll gain access to explore other members' profiles. Thank you for joining PakSouls.com family! 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Navigation Buttons -->
          <div v-if="(currentStep < 9 && !(currentStep === 8 && source !== 'web'))"
            class="d-flex justify-content-between mt-4">
            <button v-if="currentStep > 1" type="button" class="btn btn-secondary w-50 me-2"
              @click="previousStep">Previous</button>

            <!-- Show Submit button if currentStep is 6 and source is not web -->
            <button v-if="currentStep === 7 && source !== 'web'" type="submit" class="btn btn-primary w-50"
              :disabled="isSubmitting">
              <span v-if="!isSubmitting">Submit</span>
              <span v-else>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Submitting...
              </span>
            </button>

            <!-- Show Next button if currentStep is not 6 or source is web, and currentStep is less than 8 -->
            <button v-else-if="currentStep < 8" type="button" class="btn btn-primary"
              :class="{ 'w-100': currentStep === 1, 'w-50': currentStep > 1 }" @click="nextStep">Next</button>

            <!-- Original Submit button for steps 8 and above -->
            <button v-else type="submit" class="btn btn-primary w-50" :disabled="isSubmitting">
              <span v-if="!isSubmitting">Submit</span>
              <span v-else>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Submitting...
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PublicHeader from "@/components/PublicHeader.vue"

export default {
  components: {
    'public-header': PublicHeader
  },
  data() {
    return {
      currentStep: 1,
      isSubmitting: false,
      agreed_to_terms: false,
      isValid: true,
      registrationComplete: false,
      password: '',
      confirmPassword: '',
      isPasswordVisible: false,
      first_name: '',
      last_name: '',
      gender: '',
      marital_status: '',
      date_of_birth: '',
      day: '',
      month: '',
      year: '',
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      monthNamesToNumbers: {
        'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06',
        'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12'
      },
      years: this.generateYearRange(),
      daysInMonth: 31,
      photo: '',
      email: '',
      email_verified: false,
      emailValidated: false,
      whatsapp: '',
      countryCode: '+92',
      whatsappValidated: false,
      city: '',
      country: '',
      occupation: '',
      education: '',
      religion: '',
      caste: '',
      profile: '',
      source: 'web',
      photo_url: '',
      affiliate_code: '',
      showAffiliateCode: false,
      packages: null,
      package_id: 1,
      subscription: null,
      genderOptions: [{ value: 'Male', text: 'Male' }, { value: 'Female', text: 'Female' }],
      maritalStatusOptions: [
        { value: 'Single', text: 'Single' },
        { value: 'Married', text: 'Married' },
        { value: 'Widow', text: 'Widow' },
        { value: 'Divorced', text: 'Divorced' },
        { value: 'Other', text: 'Other' }
      ],
      countryOptions: [
        'Pakistan', 'United States', 'Canada', 'United Kingdom', 'Australia',
        'Germany', 'France', 'Italy', 'Spain', 'Finland', 'Japan', 'Malaysia', 'China', 'India',
        'Brazil', 'Mexico', 'Argentina', 'South Africa', 'Egypt', 'Nigeria',
        'Kenya', 'Bahrain', 'Kuwait', 'Oman', 'Qatar', 'Saudi Arabia', 'United Arab Emirates', 'Russia',
        'South Korea', 'Singapore', 'New Zealand', 'Hungary', 'Turkey', 'Sweden', 'Italy' 
      ],
      dialingCodeOptions: [
        { name: 'Pakistan', code: '+92' },
        { name: 'United States', code: '+1' },
        { name: 'Canada', code: '+1' },
        { name: 'United Kingdom', code: '+44' },
        { name: 'Australia', code: '+61' },
        { name: 'Germany', code: '+49' },
        { name: 'France', code: '+33' },
        { name: 'Italy', code: '+39' },
        { name: 'Spain', code: '+34' },
        { name: 'Finland', code: '+358' },
        { name: 'Japan', code: '+81' },
        { name: 'Malaysia', code: '+60' },
        { name: 'China', code: '+86' },
        { name: 'India', code: '+91' },
        { name: 'Brazil', code: '+55' },
        { name: 'Mexico', code: '+52' },
        { name: 'Argentina', code: '+54' },
        { name: 'South Africa', code: '+27' },
        { name: 'Egypt', code: '+20' },
        { name: 'Nigeria', code: '+234' },
        { name: 'Kenya', code: '+254' },
        { name: 'Bahrain', code: '+973' },
        { name: 'Kuwait', code: '+965' },
        { name: 'Oman', code: '+968' },
        { name: 'Qatar', code: '+974' },
        { name: 'Saudi Arabia', code: '+966' },
        { name: 'United Arab Emirates', code: '+971' },
        { name: 'Russia', code: '+7' },
        { name: 'South Korea', code: '+82' },
        { name: 'Singapore', code: '+65' },
        { name: 'New Zealand', code: '+64' },
        { name: 'Hungary', code: '+36' },
        { name: 'Turkey', code: '+90' },
        { name: 'Sweden', code: '+46' },
        { name: 'Italy', code: '+39' },
      ],
      religionOptions: [
        { value: 'Islam', text: 'Islam' },
        { value: 'Islam - Sunni, Barelvi', text: 'Islam - Sunni, Barelvi' },
        { value: 'Islam - Sunni, Deobandi', text: 'Islam - Sunni, Deobandi' },
        { value: 'Islam - Sunni, Ahle Hadees', text: 'Islam - Sunni, Ahle Hadees' },
        { value: 'Islam - Sunni, Other', text: 'Islam - Sunni, Other' },
        { value: 'Islam - Shia', text: 'Islam - Shia' },
        { value: 'Christianity', text: 'Christianity' },
        { value: 'Hinduism', text: 'Hinduism' },
        { value: 'Buddhism', text: 'Buddhism' },
        { value: 'Sikhism', text: 'Sikhism' }
      ],
      educationOptions: [
        { value: 'PhD', text: "PhD" },
        { value: 'Master\'s', text: "Master's" },
        { value: 'Bachelor\'s', text: "Bachelor's" },
        { value: 'Intermediate', text: "Intermediate" },
        { value: 'Matric', text: "Matric" },
        { value: 'Other', text: "Other" }
      ],
      casteOptions: [
        "Abbasi", "Alvi", "Ansari", "Arain", "Awan", "Baig", "Bhutto", "Bhatti",
        "Bugti", "Bukhari", "Butt", "Chaudhry", "Farooqi", "Gilani", "Gujjar",
        "Hashmi", "Jat", "Jamali", "Jiskani", "Jatoi", "Kazmi", "Khan", "Khosa",
        "Khuhro", "Khetran", "Leghari", "Malik", "Memon", "Marri", "Mazari", "Mengal",
        "Mirza", "Mughal", "Naqvi", "Pathan (Pashtun)", "Qureshi", "Rajput",
        "Rana", "Rind", "Rizvi", "Shah", "Sheikh", "Siddiqui", "Soomro", "Syed",
        "Talpur", "Usmani", "Yousufzai", "Zaidi", "Other"
      ],
      pakistanCities: ["Karachi", "Lahore", "Islamabad", "Faisalabad", "Rawalpindi", "Hyderabad", "Multan", "Gujranwala", "Quetta", "Bahawalpur", "Sargodha", "Sialkot", "Sukkur", "Larkana", "Sheikhupura", "Rahim Yar Khan", "Jhang", "Dera Ghazi Khan", "Gujrat", "Kasur", "Mardan", "Mingora", "Dera Ismail Khan", "Nawabshah", "Sahiwal", "Mirpur Khas", "Okara", "Burewala", "Jacobabad", "Saddiqabad", "Kohat", "Murree", "Khanewal", "Hafizabad", "Kohlu", "Khuzdar", "Chakwal", "Daska", "Gojra", "Mandi Bahauddin", "Toba Tek Singh", "Khairpur", "Chishtian", "Attock", "Vehari", "Mianwali", "Jhelum", "Kashmor", "Qila Abdullah", "Haripur", "Lodhran", "Malakand", "Swabi", "Charsadda", "Thatta", "Bhakkar", "Badin", "Layyah", "Loralai", "Khanpur", "Turbat", "Muzaffargarh", "Ghotki", "Karak", "Nankana Sahib", "Ziarat", "Tando Allahyar", "Chaman", "Kandhkot", "Hangu", "Timargara", "Kasur", "Zafarwal", "Qila Saifullah", "Lakki Marwat", "Rajanpur", "Hala", "Kahror Pakka", "Mastung", "Gwadar", "Thari Mirwah", "Moro", "Tando Muhammad Khan", "Tando Adam", "Jalalpur Jattan", "Naushahro Feroze", "New Mirpur", "Dipalpur", "Shikarpur", "Havelian", "Lala Musa", "Bhawana", "Shahdadkot", "Matiari", "Ghauspur", "Akora Khattak", "Jampur", "Kotli", "Kahuta", "Kandiaro"],
      errors: {
        first_name: '',
        last_name: '',
        gender: '',
        marital_status: '',
        date_of_birth: '',
        email: '',
        whatsapp: '',
        city: '',
        country: '',
        occupation: '',
        education: '',
        religion: '',
        caste: '',
        profile: '',
        password: '',
        confirmPassword: '',
        photo: '',
      },
    };
  },
  computed: {
    dateOfBirthFormatted() {
      const dob = this.year + '-' + (this.month < 10 ? '0' : '') + this.month + '-' + (this.day < 10 ? '0' : '') + this.day;
      return dob;
    }
  },
  created() {
    this.getStarterPackage();
    const queryParams = new URLSearchParams(window.location.search);
    const affiliate = queryParams.get('affiliate');
    if (affiliate) {
      this.affiliate_code = affiliate;
    }
  },
  mounted() {
    this.checkAffiliateParam();
    this.registerWithGoogle();
  },
  watch: {
    // package_id(newVal, oldVal) {
    //   console.log('Old value: ' +oldVal+ 'New value:', newVal);
    // },
    daysInMonth(newVal) {
      if (this.day > newVal) {
        this.day = '';
      }
    }
  },
  methods: {
    async getStarterPackage() {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + 'subscription/packages/starter');
        this.packages = response.data.packages;
        this.packages = null; // disable packages 
      } catch (error) {
        console.error('There was an error fetching the starter package:', error);
      }
    },

    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    generateYearRange() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = currentYear - 18; year >= currentYear - 65; year--) {
        years.push(year);
      }
      return years;
    },
    updateDays() {
      if (this.month && this.year) {
        this.day = '';  // Reset day selection
        const isLeapYear = (this.year % 4 === 0 && this.year % 100 !== 0) || this.year % 400 === 0;
        const daysInFeb = isLeapYear ? 29 : 28;
        const daysInMonth = [31, daysInFeb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        this.daysInMonth = daysInMonth[this.month - 1];
      }
    },
    goToStep(stepNumber) {
      if (this.currentStep < stepNumber && !this.validateCurrentStep()) {
        return;
      }
      this.currentStep = stepNumber;
    },
    nextStep() {
      if (this.validateCurrentStep()) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    clearErrors() {
      this.errors = {};
      this.isValid = true;
    },
    validateCurrentStep() {

      this.clearErrors();

      switch (this.currentStep) {
        case 1:
          if (!this.agreed_to_terms) {
            this.errors.agreed_to_terms = 'Please agree to our terms of use.';
            this.isValid = false;
          }
          break;
        case 2:
          if (!this.first_name) {
            this.errors.first_name = 'First name is required';
            this.isValid = false;
          }
          if (!this.last_name) {
            this.errors.last_name = 'Last name is required';
            this.isValid = false;
          }
          if (!this.gender) {
            this.errors.gender = 'Gender is required';
            this.isValid = false;
          }
          if (!this.marital_status) {
            this.errors.marital_status = 'Marital status is required';
            this.isValid = false;
          }
          if (!this.validateDateOfBirth()) {
            this.isValid = false;
          }
          break;

        case 3:
          if (!this.email) {
            this.errors.email = 'Email is required';
            this.isValid = false;
          } else if (!this.isValidEmail(this.email)) {
            this.errors.email = 'Invalid email format';
            this.isValid = false;
          }
          if (!this.whatsapp) {
            this.errors.whatsapp = 'WhatsApp number is required';
            this.isValid = false;
          } else if (!this.isValidPhoneNumber(this.whatsapp)) {
            this.errors.whatsapp = 'Invalid phone number';
            this.isValid = false;
          }
          if (!this.city) {
            this.errors.city = 'City is required';
            this.isValid = false;
          }
          if (!this.country) {
            this.errors.country = 'Country is required';
            this.isValid = false;
          }
          break;

        case 4:
          if (!this.occupation) {
            this.errors.occupation = 'Occupation is required';
            this.isValid = false;
          }
          if (!this.education) {
            this.errors.education = 'Education level is required';
            this.isValid = false;
          }

          break;

        case 5:
          if (!this.religion) {
            this.errors.religion = 'Religion is required';
            this.isValid = false;
          }
          if (!this.caste) {
            this.errors.caste = 'Caste is required';
            this.isValid = false;
          }
          break;

        case 6:
          // if (!this.profile) {
          //   this.errors.profile = 'Profile is required';
          //   this.isValid = false;
          // } else if (this.profile.length > 2000) {
          //   this.errors.profile = 'Profile must not exceed 2000 characters';
          //   this.isValid = false;
          // }
          if (this.profile.length > 2000) {
            this.errors.profile = 'Profile must not exceed 2000 characters';
            this.isValid = false;
          }
          break;
        case 7:
          if (!this.photo) {
            this.errors.photo = 'Please upload a photo';
            this.isValid = false;
          }
          break;
        case 8:
          if (!this.password) {
            this.errors.password = 'Password is required';
            this.isValid = false;
          } else if (this.password.length < 8) {
            this.errors.password = 'Password must be at least 8 characters';
            this.isValid = false;
          }
          if (!this.confirmPassword) {
            this.errors.confirmPassword = 'Confirming password is required';
            this.isValid = false;
          } else if (this.password !== this.confirmPassword) {
            this.errors.confirmPassword = 'Passwords do not match';
            this.isValid = false;
          }
          break;
        default:
      }

      return this.isValid;
    },
    validateDateOfBirth() {
      if (!this.day || !this.month || !this.year) {
        this.errors.date_of_birth = 'Date of birth is required';
        return false;
      }

      const dob = new Date(this.date_of_birth);
      const today = new Date();
      let age = today.getFullYear() - dob.getFullYear();
      const m = today.getMonth() - dob.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        age--;
      }

      if (age < 18 || age > 65) {
        this.errors.date_of_birth = 'Age must be between 18 and 65';
        return false;
      }

      return true;
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validateAndVerifyEmail() {
      if (this.isValidEmail(this.email)) {
        this.verifyEmailAPI(this.email);
      } else {
        // Email format is not valid
        this.errors.email = 'Please enter a valid email address.';
        this.isValid = false;
      }
    },
    async verifyEmailAPI(email) {
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + 'verify-contact', { contact: email });
        if (response.data.status === 'success') {
          this.errors.email = null;
          this.isValid = true;
        } else {
          this.errors.email = 'Email already exists in our database. Please use another one.';
          this.isValid = false;
        }
      } catch (error) {
        this.errors.email = 'There is an error verifying your email with our system. Please try again later.';
        this.isValid = false;
      }
    },
    isValidPhoneNumber(number) {
      const regex = /^[1-9]\d*$/; // Regex to check if the number does not start with 0
      return regex.test(number);
    },
    validateAndVerifyPhoneNumber() {
      const fullNumber = this.countryCode + this.whatsapp;
      if (this.isValidPhoneNumber(this.whatsapp)) {
        this.verifyPhoneNumberAPI(fullNumber);
      } else {
        this.errors.whatsapp = 'Please enter a valid phone number without leading zeros.';
        this.isValid = false;
      }
    },
    async verifyPhoneNumberAPI(number) {
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + 'verify-contact', { contact: number });
        if (response.data.status == 'success') {
          this.errors.whatsapp = null;
          this.isValid = true;
        } else {
          this.errors.whatsapp = 'Phone number already exists in our database. Please use another one.';
          this.isValid = false;
        }
      } catch (error) {
        this.errors.whatsapp = 'There is an error verifying your phone number with our system. Please try again later.';
        this.isValid = false;
      }
    },
    handleFileUpload(event) {
      const uploadedFile = event.target.files[0];

      if (uploadedFile && uploadedFile.size <= 5242880) {
        this.photo = uploadedFile;
        this.errors.photo = '';
      } else {
        this.errors.photo = 'File size should not exceed 5MB';
        this.photo = null;
      }
    },
    toggleAffiliateCodeVisibility() {
      this.showAffiliateCode = !this.showAffiliateCode;
    },
    checkAffiliateParam() {
      const urlParams = new URLSearchParams(window.location.search);
      const affiliate = urlParams.get('affiliate');
      if (affiliate) {
        this.showAffiliateCode = true;
        this.affiliate_code = affiliate; // Optionally pre-fill the affiliate code
      }
    },
    async submitForm() {
      if (this.validateCurrentStep()) {
        const payload = {
          first_name: this.first_name,
          last_name: this.last_name,
          gender: this.gender,
          marital_status: this.marital_status,
          date_of_birth: this.dateOfBirthFormatted,
          email: this.email,
          mobile: this.countryCode + this.whatsapp,
          city: this.city,
          country: this.country,
          occupation: this.occupation,
          education_level: this.education,
          religion: this.religion,
          caste: this.caste,
          profile: this.profile,
          password: this.password,
          password_confirmation: this.confirmPassword,
          photo: this.photo,
          source: this.source,
          email_verified: this.email_verified,
          photo_url: this.photo_url,
          affiliate_code: this.affiliate_code,
          package_id: this.package_id
        };

        try {
          this.isSubmitting = true;
          const response = await axios.post(process.env.VUE_APP_API_URL + "members/register-quick", payload, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          if (response.status == 200) {
            this.registrationComplete = true;
            this.subscription = response.data.subscription;
            this.currentStep++;
            this.isSubmitting = false;
            this.resetForm();
          } else {
            this.showError("Registration failed: " + response.data.message);
          }
        } catch (error) {
          let errorMessage = "There is an error submitting your registration. Please contact website administrator.";
          if (error.response && error.response.data && error.response.data.error) {
            const errors = error.response.data.error;
            const firstErrorKey = Object.keys(errors)[0];
            if (firstErrorKey && errors[firstErrorKey].length > 0) {
              errorMessage = errors[firstErrorKey][0];
            }
          }
          this.showError(errorMessage);
          this.isSubmitting = false;
        }
      }

    },
    showError(message) {
      alert(message);
    },
    resetForm() {
      this.first_name = '';
      this.last_name = '';
      this.gender = '';
      this.marital_status = '';
      this.date_of_birth = '';
      this.email = '';
      this.whatsapp = '';
      this.city = '';
      this.country = '';
      this.occupation = '';
      this.education = '';
      this.religion = '';
      this.caste = '';
      this.profile = '';
      this.photo = '';
      this.password = '';
      this.confirmPassword = '';
      this.affiliate_code = '';

    },
    registerWithGoogle() {
      // Check if the 'user' query parameter exists
      if (this.$route.query.user) {
        try {
          // Decode the URI component and parse the 'user' query parameter from the URL
          const userObj = JSON.parse(decodeURIComponent(this.$route.query.user));

          // Assign the properties to local variables
          this.first_name = userObj.first_name;
          this.last_name = userObj.last_name;
          this.email = userObj.email;
          this.photo_url = userObj.photo_url;
          this.email_verified = userObj.email_verified;
          this.source = userObj.source;

        } catch (e) {
          console.error("Error parsing user information from URL", e);
          // Handle errors or incorrect formatting here
        }
      }
    }
  }
};
</script>

<style scoped></style>