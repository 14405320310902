<template>
    <main-header heading="Edit Profile"></main-header>

    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <h3 class="my-4">Edit Your Profile</h3>
                <div v-if="currentStep < 6" class="mb-4 d-flex justify-content-center">
                    <span v-for="step in 5" :key="step" class="wizard-circle" :class="{ active: currentStep === step }"
                        @click="goToStep(step)">{{ step }}</span>
                </div>
                <form @submit.prevent="submitForm" enctype="multipart/form-data">
                    <!-- Form Wizard Screen 1 -->
                    <div v-if="currentStep === 1">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p class="my-4">Enter your account information.</p>
                                <div class="mb-3">
                                    <label for="first_name" class="form-label">First Name</label>
                                    <input type="text" id="first_name" class="form-control" v-model="first_name"
                                        :class="{ 'is-invalid': errors.first_name }" required>
                                    <div v-if="errors.first_name" class="invalid-feedback">{{ errors.first_name }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="last_name" class="form-label">Last Name</label>
                                    <input type="text" id="last_name" class="form-control" v-model="last_name"
                                        :class="{ 'is-invalid': errors.last_name }" required>
                                    <div v-if="errors.last_name" class="invalid-feedback">{{ errors.last_name }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="gender" class="form-label">Gender</label>
                                    <select id="gender" class="form-select" v-model="gender"
                                        :class="{ 'is-invalid': errors.gender }" required>
                                        <option value="" disabled>Select Gender</option>
                                        <option v-for="genderOption in genderOptions" :key="genderOption.value"
                                            :value="genderOption.value">
                                            {{ genderOption.text }}
                                        </option>
                                    </select>
                                    <div v-if="errors.gender" class="invalid-feedback">{{ errors.gender }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="maritalStatus" class="form-label">Marital Status</label>
                                    <select id="maritalStatus" class="form-select" v-model="marital_status"
                                        :class="{ 'is-invalid': errors.marital_status }" required>
                                        <option value="" disabled>Select Marital Status</option>
                                        <option v-for="status in maritalStatusOptions" :key="status.value"
                                            :value="status.value">
                                            {{ status.text }}
                                        </option>
                                    </select>
                                    <div v-if="errors.marital_status" class="invalid-feedback">{{ errors.marital_status }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="dateOfBirth" class="form-label">Date of Birth</label>
                                    <div style="display: flex; justify-content: space-between;">
                                        <!-- Inline style for side-by-side layout -->
                                        <!-- Year Dropdown -->
                                        <select v-model="year" @change="updateDays" class="form-control"
                                            :class="{ 'is-invalid': errors.date_of_birth }" required style="width: 30%;">
                                            <option value="">Year</option>
                                            <option v-for="year in years" :value="year" :key="'year-' + year">{{ year }}
                                            </option>
                                        </select>

                                        <!-- Month Dropdown -->
                                        <select v-model="month" @change="updateDays" class="form-control"
                                            :class="{ 'is-invalid': errors.date_of_birth }" required style="width: 30%;">
                                            <option value="">Month</option>
                                            <option v-for="(month, index) in months" :value="index + 1"
                                                :key="'month-' + month">{{ month }}
                                            </option>
                                        </select>

                                        <!-- Day Dropdown -->
                                        <select v-model="day" class="form-control"
                                            :class="{ 'is-invalid': errors.date_of_birth }" required style="width: 30%;">
                                            <option value="">Day</option>
                                            <option v-for="day in daysInMonth" :value="day" :key="'day-' + day">{{ day }}
                                            </option>
                                        </select>

                                    </div>
                                    <div v-if="errors.date_of_birth" class="invalid-feedback">{{ errors.date_of_birth }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Form Wizard Screen 2 -->
                    <div v-if="currentStep === 2">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p class="my-4">Enter your contact and addressing information.</p>
                                <!-- <div class="mb-3">
                                    <label for="email" class="form-label">Email Address</label>
                                    <input type="email" id="email" class="form-control" v-model="email"
                                        :class="{ 'is-invalid': errors.email }" @change="validateAndVerifyEmail" required>
                                    <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
                                </div> -->
                                <!-- <div class="mb-3">
                                    <label for="whatsapp" class="form-label">WhatsApp Number</label>
                                    <input type="tel" id="whatsapp" class="form-control" v-model="whatsapp"
                                        :class="{ 'is-invalid': errors.whatsapp }" @change="validateAndVerifyPhoneNumber"
                                        required>
                                    <div v-if="errors.whatsapp" class="invalid-feedback">{{ errors.whatsapp }}</div>
                                    <small>Use country code without plus(+) sign with phone number ex: 923001234567</small>
                                </div> -->
                                <div class="mb-3">
                                    <label for="city" class="form-label">City</label>
                                    <div v-if="country === 'Pakistan'">
                                        <select id="city" class="form-select" v-model="city"
                                            :class="{ 'is-invalid': errors.city }" required>
                                            <option value="" disabled>Select City</option>
                                            <option v-for="cityOption in pakistanCities" :key="cityOption"
                                                :value="cityOption">
                                                {{ cityOption }}
                                            </option>
                                        </select>
                                    </div>
                                    <div v-else>
                                        <input type="text" id="city" class="form-control" v-model="city"
                                            :class="{ 'is-invalid': errors.city }" required>
                                    </div>
                                    <div v-if="errors.city" class="invalid-feedback">{{ errors.city }}</div>
                                </div>

                                <div class="mb-3">
                                    <label for="country" class="form-label">Country</label>
                                    <select id="country" class="form-select" v-model="country"
                                        :class="{ 'is-invalid': errors.country }" required>
                                        <option value="" disabled>Select Country</option>
                                        <option v-for="countryOption in countryOptions" :key="countryOption"
                                            :value="countryOption">
                                            {{ countryOption }}
                                        </option>
                                    </select>
                                    <div v-if="errors.country" class="invalid-feedback">{{ errors.country }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="hometown" class="form-label">Hometown</label>
                                    <input type="text" id="hometown" class="form-control" v-model="hometown"
                                        :class="{ 'is-invalid': errors.hometown }" required>
                                    <div v-if="errors.hometown" class="invalid-feedback">{{ errors.hometown }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="parental_status" class="form-label">Parental Status</label>
                                    <input type="text" id="parental_status" class="form-control" v-model="parental_status"
                                        :class="{ 'is-invalid': errors.parental_status }" required>
                                    <div v-if="errors.parental_status" class="invalid-feedback">{{ errors.parental_status }}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <!-- Form Wizard Screen 3 -->
                    <div v-if="currentStep === 3">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p class="my-4">Enter your education and professional details.</p>
                                <!-- Occupation -->
                                <div class="mb-3">
                                    <label for="occupation" class="form-label">Occupation</label>
                                    <input type="text" id="occupation" class="form-control" v-model="occupation"
                                        :class="{ 'is-invalid': errors.occupation }" required>
                                    <div v-if="errors.occupation" class="invalid-feedback">{{ errors.occupation }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="industry" class="form-label">Industry</label>
                                    <select id="industry" class="form-select" v-model="industry"
                                        :class="{ 'is-invalid': errors.industry }" required>
                                        <option value="" disabled>Select Industry</option>
                                        <option v-for="industryOption in industryOptions" :key="industryOption"
                                            :value="industryOption">
                                            {{ industryOption }}
                                        </option>
                                    </select>
                                    <div v-if="errors.industry" class="invalid-feedback">{{ errors.industry }}</div>
                                </div>


                                <div class="mb-3">
                                    <label for="employment_details" class="form-label">Employment Details</label>
                                    <textarea rows="5" id="employment_details" class="form-control"
                                        v-model="employment_details" :class="{ 'is-invalid': errors.employment_details }"
                                        required maxlength="2000"></textarea>
                                    <small class="text-muted">Do not add phone number, whatsapp or email address.</small>
                                    <div v-if="errors.employment_details" class="invalid-feedback">{{
                                        errors.employment_details }}</div>
                                </div>
                                <!-- Education -->
                                <div class="mb-3">
                                    <label for="education" class="form-label">Education</label>
                                    <select id="education" class="form-select" v-model="education"
                                        :class="{ 'is-invalid': errors.education }" required>
                                        <option value="" disabled>Select Education Level</option>
                                        <option v-for="educationOption in educationOptions" :key="educationOption.value"
                                            :value="educationOption.value">
                                            {{ educationOption.text }}
                                        </option>
                                    </select>
                                    <div v-if="errors.education" class="invalid-feedback">{{ errors.education }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Form Wizard Screen 4 -->
                    <div v-if="currentStep === 4">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p class="my-4">Select your religion and caste.</p>
                                <div class="mb-3">
                                    <label for="religion" class="form-label">Religion</label>
                                    <select id="religion" class="form-select" v-model="religion"
                                        :class="{ 'is-invalid': errors.religion }" required>
                                        <option value="" disabled>Select Religion</option>
                                        <option v-for="religionOption in religionOptions" :key="religionOption.value"
                                            :value="religionOption.value">
                                            {{ religionOption.text }}
                                        </option>
                                    </select>
                                    <div v-if="errors.religion" class="invalid-feedback">{{ errors.religion }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="caste" class="form-label">Caste</label>
                                    <select id="caste" class="form-select" v-model="caste"
                                        :class="{ 'is-invalid': errors.caste }" required>
                                        <option value="" disabled>Select Caste</option>
                                        <option v-for="casteOption in casteOptions" :key="casteOption" :value="casteOption">
                                            {{ casteOption }}
                                        </option>
                                    </select>
                                    <div v-if="errors.caste" class="invalid-feedback">{{ errors.caste }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="height" class="form-label">Height</label>
                                    <input type="text" id="height" class="form-control" v-model="height"
                                        :class="{ 'is-invalid': errors.height }" required>
                                    <div v-if="errors.height" class="invalid-feedback">{{ errors.height }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="weight" class="form-label">Weight</label>
                                    <input type="text" id="weight" class="form-control" v-model="weight"
                                        :class="{ 'is-invalid': errors.weight }" required>
                                    <div v-if="errors.weight" class="invalid-feedback">{{ errors.weight }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="skin" class="form-label">Skin Color</label>
                                    <select id="skin" class="form-select" v-model="skin"
                                        :class="{ 'is-invalid': errors.skin }" required>
                                        <option value="" disabled>Select Skin Color</option>
                                        <option v-for="skinOption in skinOptions" :key="skinOption" :value="skinOption">
                                            {{ skinOption }}
                                        </option>
                                    </select>
                                    <div v-if="errors.skin" class="invalid-feedback">{{ errors.skin }}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- Form Wizard Screen 5 -->
                    <div v-if="currentStep === 5">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p class="my-4">Enter requirements for your match.</p>
                                <div class="mb-3">
                                    <label for="profile" class="form-label">Match Preferences</label>
                                    <textarea rows="5" id="profile" class="form-control" v-model="profile"
                                        :class="{ 'is-invalid': errors.profile }" required maxlength="2000"></textarea>
                                    <small class="text-muted">Do not add phone number, whatsapp or email address.</small>
                                    <div v-if="errors.profile" class="invalid-feedback">{{ errors.profile }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Form Wizard Screen 6 -->
                    <!-- <div v-if="currentStep === 6">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p class="my-4">Upload your profile photo (face should be visible).</p>
                                <div class="mb-3">
                                    <label for="photo" class="form-label">Upload Photo</label>
                                    <input type="file" id="photo" class="form-control" @change="handleFileUpload"
                                        accept="image/*">
                                    <div v-if="errors.photo" class="invalid-feedback">{{ errors.photo }}</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- Form Wizard Screen 7 -->
                    <!-- <div v-if="currentStep === 6">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p class="my-4">Enter your account password.</p>
                                <div class="mb-3">
                                    <label for="password" class="form-label">Password</label>
                                    <input type="password" id="password" class="form-control" v-model="password"
                                        :class="{ 'is-invalid': errors.password }">
                                    <div v-if="errors.password" class="invalid-feedback">{{ errors.password }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="confirmPassword" class="form-label">Confirm Password</label>
                                    <input type="password" id="confirmPassword" class="form-control"
                                        v-model="confirmPassword" :class="{ 'is-invalid': errors.confirmPassword }">
                                    <div v-if="errors.confirmPassword" class="invalid-feedback">{{ errors.confirmPassword }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div v-if="currentStep === 6">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p class="my-4">Profile Updated</p>
                                <div v-if="registrationComplete" class="alert alert-success mt-3">
                                    Your profile has successfully been updated.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Navigation Buttons -->
                    <div v-if="currentStep < 6" class="d-flex justify-content-between mt-4 my-5">
                        <button v-if="currentStep > 1" type="button" class="btn btn-secondary w-50 me-2"
                            @click="previousStep">Previous</button>
                        <button v-if="currentStep < 5" type="button"
                            :class="{ 'btn btn-primary w-100': currentStep === 1, 'btn btn-primary w-50': currentStep !== 1 }"
                            @click="nextStep">Next</button>
                        <button v-else type="submit" class="btn btn-primary w-50">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>
<script>
import axios from "axios";
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    },
    data() {
        return {
            currentStep: 1,
            isValid: true,
            registrationComplete: false,
            //password: '',
            //confirmPassword: '',
            user_id: '',
            first_name: '',
            last_name: '',
            gender: '',
            marital_status: '',
            date_of_birth: '',
            day: '',
            month: '',
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            monthNamesToNumbers: { "01": "Jan", "02": "Feb", "03": "Mar", "04": "Apr", "05": "May", "06": "Jun", "07": "Jul", "08": "Aug", "09": "Sep", "10": "Oct", "11": "Nov", "12": "Dec" },
            year: '',
            years: this.generateYearRange(),
            daysInMonth: 31,
            //photo: '',
            email: '',
            emailValidated: false,
            whatsapp: '',
            whatsappValidated: false,
            city: '',
            country: '',
            hometown: '',
            parental_status: '',
            occupation: '',
            industry: '',
            employment_details: '',
            education: '',
            religion: '',
            caste: '',
            height: '',
            weight: '',
            skin: '',
            profile: '',
            accessToken: sessionStorage.getItem('accessToken'),
            genderOptions: [
                { value: 'Male', text: 'Male' },
                { value: 'Female', text: 'Female' }
            ],
            maritalStatusOptions: [
                { value: 'Single', text: 'Single' },
                { value: 'Married', text: 'Married' },
                { value: 'Widow', text: 'Widow' },
                { value: 'Divorced', text: 'Divorced' },
                { value: 'Other', text: 'Other' }
            ],
            countryOptions: [
                'Pakistan', 'United States', 'Canada', 'United Kingdom', 'Australia',
                'Germany', 'France', 'Italy', 'Spain', 'Finland', 'Japan', 'Malaysia', 'China', 'India',
                'Brazil', 'Mexico', 'Argentina', 'South Africa', 'Egypt', 'Nigeria',
                'Kenya', 'Bahrain', 'Kuwait', 'Oman', 'Qatar', 'Saudi Arabia', 'United Arab Emirates', 'Russia',
                'South Korea', 'Singapore', 'New Zealand', 'Hungary', 'Turkey', 'Sweden', 'Italy' 
            ],
            religionOptions: [
                { value: 'Islam', text: 'Islam' },
                { value: 'Islam - Sunni, Barelvi', text: 'Islam - Sunni, Barelvi' },
                { value: 'Islam - Sunni, Deobandi', text: 'Islam - Sunni, Deobandi' },
                { value: 'Islam - Sunni, Ahle Hadees', text: 'Islam - Sunni, Ahle Hadees' },
                { value: 'Islam - Sunni, Other', text: 'Islam - Sunni, Other' },
                { value: 'Islam - Shia', text: 'Islam - Shia' },
                { value: 'Christianity', text: 'Christianity' },
                { value: 'Hinduism', text: 'Hinduism' },
                { value: 'Buddhism', text: 'Buddhism' },
                { value: 'Sikhism', text: 'Sikhism' }
            ],
            educationOptions: [
                { value: 'PhD', text: "PhD" },
                { value: 'Master\'s', text: "Master's" },
                { value: 'Bachelor\'s', text: "Bachelor's" },
                { value: 'Intermediate', text: "Intermediate" },
                { value: 'Matric', text: "Matric" },
                { value: 'Other', text: "Other" }
            ],
            industryOptions: [
                "Technology",
                "Finance",
                "Healthcare",
                "Manufacturing",
                "Retail",
                "Education",
                "Entertainment",
                "Automotive",
                "Telecommunications",
                "Energy",
                "Agriculture",
                "Real Estate",
                "Media",
                "Hospitality",
                "Transportation",
                "Construction",
                "Government",
                "Consulting",
                "Aerospace",
                "Biotechnology",
                "Pharmaceuticals",
                "Fashion",
                "Insurance",
                "Food and Beverage",
                "Sports",
                "Environmental",
                "Mining",
                "Utilities",
                "E-commerce",
                "Advertising",
                "Engineering",
                "Legal",
                "Non-profit",
                "Travel",
                "Music",
                "Chemicals",
                "Design",
                "Human Resources",
                "Logistics",
                "Marketing",
                "Architecture",
                "Gaming",
                "Venture Capital",
                "Research",
                "Startups",
                "Social Media",
                "Security",
                "Art",
            ],

            casteOptions: [
                "Abbasi",
                "Alvi",
                "Ansari",
                "Arain",
                "Awan",
                "Baig",
                "Bhutto",
                "Bhatti",
                "Bugti",
                "Bukhari",
                "Butt",
                "Chaudhry",
                "Farooqi",
                "Gilani",
                "Gujjar",
                "Hashmi",
                "Jat",
                "Jamali",
                "Jiskani",
                "Jatoi",
                "Kazmi",
                "Khan",
                "Khosa",
                "Khuhro",
                "Khetran",
                "Leghari",
                "Malik",
                "Memon",
                "Marri",
                "Mazari",
                "Mengal",
                "Mirza",
                "Mughal",
                "Naqvi",
                "Pathan (Pashtun)",
                "Qureshi",
                "Rajput",
                "Rana",
                "Rind",
                "Rizvi",
                "Shah",
                "Sheikh",
                "Siddiqui",
                "Soomro",
                "Syed",
                "Talpur",
                "Usmani",
                "Yousufzai",
                "Zaidi",
                "Other",
            ],
            skinOptions: [
                "Wheatish", "Fair", "Olive", "Tan", "Beige", "Brown", "Dark Brown"
            ],
            pakistanCities: ["Karachi", "Lahore", "Islamabad", "Faisalabad", "Rawalpindi", "Hyderabad", "Multan", "Gujranwala", "Quetta", "Bahawalpur", "Sargodha", "Sialkot", "Sukkur", "Larkana", "Sheikhupura", "Rahim Yar Khan", "Jhang", "Dera Ghazi Khan", "Gujrat", "Kasur", "Mardan", "Mingora", "Dera Ismail Khan", "Nawabshah", "Sahiwal", "Mirpur Khas", "Okara", "Burewala", "Jacobabad", "Saddiqabad", "Kohat", "Murree", "Khanewal", "Hafizabad", "Kohlu", "Khuzdar", "Chakwal", "Daska", "Gojra", "Mandi Bahauddin", "Toba Tek Singh", "Khairpur", "Chishtian", "Attock", "Vehari", "Mianwali", "Jhelum", "Kashmor", "Qila Abdullah", "Haripur", "Lodhran", "Malakand", "Swabi", "Charsadda", "Thatta", "Bhakkar", "Badin", "Layyah", "Loralai", "Khanpur", "Turbat", "Muzaffargarh", "Ghotki", "Karak", "Nankana Sahib", "Ziarat", "Tando Allahyar", "Chaman", "Kandhkot", "Hangu", "Timargara", "Kasur", "Zafarwal", "Qila Saifullah", "Lakki Marwat", "Rajanpur", "Hala", "Kahror Pakka", "Mastung", "Gwadar", "Thari Mirwah", "Moro", "Tando Muhammad Khan", "Tando Adam", "Jalalpur Jattan", "Naushahro Feroze", "New Mirpur", "Dipalpur", "Shikarpur", "Havelian", "Lala Musa", "Bhawana", "Shahdadkot", "Matiari", "Ghauspur", "Akora Khattak", "Jampur", "Kotli", "Kahuta", "Kandiaro"],
            errors: {
                first_name: '',
                last_name: '',
                gender: '',
                marital_status: '',
                date_of_birth: '',
                email: '',
                whatsapp: '',
                city: '',
                country: '',
                hometown: '',
                parental_status: '',
                occupation: '',
                industry: '',
                employment_details: '',
                education: '',
                religion: '',
                caste: '',
                height: '',
                weight: '',
                skin: '',
                profile: '',
                //password: '',
                //confirmPassword: '',
                //photo: '',
            },
        };
    },
    computed: {
        dateOfBirthFormatted() {
            const dob = this.year + '-' + (this.month < 10 ? '0' : '') + this.month + '-' + (this.day < 10 ? '0' : '') + this.day;
            return dob;
        }
    },
    created() {
        this.loadUserProfile();
    },
    watch: {
        daysInMonth(newVal) {
            if (this.day > newVal) {
                this.day = '';
            }
        }
    },
    methods: {
        async loadUserProfile() {
            const apiUrl = process.env.VUE_APP_API_URL + "member/profile";
            const accessToken = sessionStorage.getItem('accessToken');
            const headers = {
                "Authorization": "Bearer " + accessToken,
            };

            try {
                const response = await axios.get(apiUrl, { headers });
                this.user_id = response.data.id;
                this.first_name = response.data.first_name;
                this.last_name = response.data.last_name;
                //this.date_of_birth = response.data.date_of_birth;
                console.log(response.data.date_of_birth);
                if (response.data.date_of_birth) {
                    const parts = response.data.date_of_birth.split('-'); // Assuming the date is in YYYY-MM-DD format
                    this.year = parts[0];
                    this.month = Number(parts[1]);
                    this.day = Number(parts[2]);
                } else {
                    // Set to default or empty values if date_of_birth is not available
                    this.year = '';
                    this.month = '';
                    this.day = '';
                }
                this.gender = response.data.gender;
                this.marital_status = response.data.marital_status;
                this.height = response.data.height;
                this.weight = response.data.weight;
                this.education = response.data.education_level;
                this.industry = response.data.industry;
                this.occupation = response.data.occupation;
                this.industry = response.data.industry;
                this.employment_details = response.data.employment_details;
                this.religion = response.data.religion;
                this.sect = response.data.sect;
                this.caste = response.data.caste;
                this.height = response.data.height;
                this.weight = response.data.weight;
                this.skin = response.data.skin;
                this.city = response.data.city;
                this.country = response.data.country;
                this.hometown = response.data.hometown;
                this.parental_status = response.data.parental_status;
                this.interests = response.data.interests;
                this.profile = response.data.profile;
                this.mobile_verified_at = response.data.mobile_verified_at;
                this.created_at = response.data.created_at;
                this.active = response.data.active;

            } catch (error) {
                console.error("Error fetching member settings:", error);
            }
        },
        goToStep(stepNumber) {
            if (this.currentStep < stepNumber && !this.validateCurrentStep()) {
                return;
            }
            this.currentStep = stepNumber;
        },
        nextStep() {
            if (this.validateCurrentStep()) {
                this.currentStep++;
            }
        },
        previousStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },
        clearErrors() {
            this.errors = {};
            this.isValid = true;
        },
        validateCurrentStep() {

            this.clearErrors();

            switch (this.currentStep) {
                case 1:
                    if (!this.first_name) {
                        this.errors.first_name = 'First name is required';
                        this.isValid = false;
                    }
                    if (!this.last_name) {
                        this.errors.last_name = 'Last name is required';
                        this.isValid = false;
                    }
                    if (!this.gender) {
                        this.errors.gender = 'Gender is required';
                        this.isValid = false;
                    }
                    if (!this.marital_status) {
                        this.errors.marital_status = 'Marital status is required';
                        this.isValid = false;
                    }
                    if (!this.validateDateOfBirth()) {
                        this.isValid = false;
                    }
                    break;

                case 2:
                    // if (!this.email) {
                    //     this.errors.email = 'Email is required';
                    //     this.isValid = false;
                    // } else if (!this.isValidEmail(this.email)) {
                    //     this.errors.email = 'Invalid email format';
                    //     this.isValid = false;
                    // }
                    // if (!this.whatsapp) {
                    //     this.errors.whatsapp = 'WhatsApp number is required';
                    //     this.isValid = false;
                    // } else if (!this.isValidPhoneNumber(this.whatsapp)) {
                    //     this.errors.whatsapp = 'Invalid phone number';
                    //     this.isValid = false;
                    // }
                    if (!this.city) {
                        this.errors.city = 'City is required';
                        this.isValid = false;
                    }
                    if (!this.country) {
                        this.errors.country = 'Country is required';
                        this.isValid = false;
                    }
                    if (!this.hometown) {
                        this.errors.hometown = 'Hometown is required';
                        this.isValid = false;
                    }
                    if (!this.parental_status) {
                        this.errors.parental_status = 'Parent status is required';
                        this.isValid = false;
                    }
                    break;

                case 3:
                    if (!this.occupation) {
                        this.errors.occupation = 'Occupation is required';
                        this.isValid = false;
                    }
                    if (!this.industry) {
                        this.errors.industry = 'Industry is required';
                        this.isValid = false;
                    }
                    if (!this.employment_details) {
                        this.errors.employment_details = 'Employment details is required';
                        this.isValid = false;
                    }
                    if (!this.education) {
                        this.errors.education = 'Education level is required';
                        this.isValid = false;
                    }

                    break;

                case 4:
                    if (!this.religion) {
                        this.errors.religion = 'Religion is required';
                        this.isValid = false;
                    }
                    if (!this.caste) {
                        this.errors.caste = 'Caste is required';
                        this.isValid = false;
                    }
                    if (!this.height) {
                        this.errors.height = 'Height is required';
                        this.isValid = false;
                    }
                    if (!this.weight) {
                        this.errors.weight = 'Weight is required';
                        this.isValid = false;
                    }
                    if (!this.skin) {
                        this.errors.skin = 'Skin is required';
                        this.isValid = false;
                    }
                    break;

                case 5:
                    if (!this.profile) {
                        this.errors.profile = 'Profile is required';
                        this.isValid = false;
                    } else if (this.profile.length > 2000) {
                        this.errors.profile = 'Profile must not exceed 2000 characters';
                        this.isValid = false;
                    }
                    break;
                // case 6:
                //     if (!this.photo) {
                //         this.errors.photo = 'Please upload a photo';
                //         this.isValid = false;
                //     }
                //     break;
                // case 6:
                //     if (!this.password) {
                //         this.errors.password = 'Password is required';
                //         this.isValid = false;
                //     } else if (this.password.length < 8) {
                //         this.errors.password = 'Password must be at least 8 characters';
                //         this.isValid = false;
                //     }
                //     if (!this.confirmPassword) {
                //         this.errors.confirmPassword = 'Confirming password is required';
                //         this.isValid = false;
                //     } else if (this.password !== this.confirmPassword) {
                //         this.errors.confirmPassword = 'Passwords do not match';
                //         this.isValid = false;
                //     }
                //     break;
                default:
            }

            return this.isValid;
        },

        validateDateOfBirth() {
            if (!this.day || !this.month || !this.year) {
                this.errors.date_of_birth = 'Date of birth is required';
                return false;
            }

            const dob = new Date(this.date_of_birth);
            const today = new Date();
            let age = today.getFullYear() - dob.getFullYear();
            const m = today.getMonth() - dob.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
                age--;
            }

            if (age < 18 || age > 65) {
                this.errors.date_of_birth = 'Age must be between 18 and 65';
                return false;
            }

            return true;
        },
        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        validateAndVerifyEmail() {
            if (this.isValidEmail(this.email)) {
                this.verifyEmailAPI(this.email);
            } else {
                // Email format is not valid
                this.errors.email = 'Please enter a valid email address.';
                this.isValid = false;
            }
        },
        isValidPhoneNumber(number) {
            const cleanNumber = number.replace(/[+\-()\s.]/g, '');

            if (cleanNumber.length >= 11 && cleanNumber.length <= 15) {
                const phoneRegex = /^\d+$/;
                return phoneRegex.test(cleanNumber);
            }

            return false;
        },
        validateAndVerifyPhoneNumber() {
            if (this.isValidPhoneNumber(this.whatsapp)) {
                this.verifyPhoneNumberAPI(this.whatsapp);
            } else {
                this.errors.whatsapp = 'Please enter a valid phone number with country code.';
                this.isValid = false;
            }
        },
        async verifyEmailAPI(email) {
            try {
                const headers = {
                    "Authorization": "Bearer " + this.accessToken,
                };
                const response = await axios.post(process.env.VUE_APP_API_URL + 'verify-contact', { contact: email }, { headers });
                if (response.data.status === 'success') {
                    this.errors.email = null;
                    this.isValid = true;
                } else {
                    this.errors.email = 'Email already exists in our database. Please use another one.';
                    this.isValid = false;
                }
            } catch (error) {
                this.errors.email = 'There is an error verifying your email with our system. Please try again later.';
                this.isValid = false;
            }
        },
        async verifyPhoneNumberAPI(number) {
            try {
                const headers = {
                    "Authorization": "Bearer " + this.accessToken,
                };
                const response = await axios.post(process.env.VUE_APP_API_URL + 'verify-contact', { contact: number }, { headers });
                if (response.data.status == 'success') {
                    //DO NOTHING IF PHONE EXISTS IN THE SYSTEM
                    this.errors.whatsapp = null;
                    this.isValid = true;
                } else {
                    this.errors.whatsapp = 'Phone number already exists in our database. Please use another one.';
                    this.isValid = false;
                }
            } catch (error) {
                this.errors.whatsapp = 'There is an error verifying your phone number with our system. Please try again later.';
                this.isValid = false;
            }
        },
        // handleFileUpload(event) {
        //     const uploadedFile = event.target.files[0];

        //     if (uploadedFile && uploadedFile.size <= 5242880) {
        //         this.photo = uploadedFile;
        //         this.errors.photo = '';
        //     } else {
        //         this.errors.photo = 'File size should not exceed 5MB';
        //         this.photo = null;
        //     }
        // },
        async submitForm() {
            if (this.validateCurrentStep()) {
                const payload = {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    gender: this.gender,
                    marital_status: this.marital_status,
                    date_of_birth: this.dateOfBirthFormatted,
                    city: this.city,
                    country: this.country,
                    hometown: this.hometown,
                    parental_status: this.parental_status,
                    occupation: this.occupation,
                    industry: this.industry,
                    employment_details: this.employment_details,
                    education_level: this.education,
                    religion: this.religion,
                    caste: this.caste,
                    height: this.height,
                    weight: this.weight,
                    skin: this.skin,
                    profile: this.profile,
                };

                try {
                    const response = await axios.put(process.env.VUE_APP_API_URL + "members/update-profile", payload, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                        }
                    });
                    if (response.status == 200) {
                        this.registrationComplete = true;

                        this.currentStep++;

                        this.resetForm();
                    } else {
                        this.showError("Profile update failed: " + response.data.message);
                    }
                } catch (error) {
                    this.showError("Error during profile update: " + error.message);
                }
            }

        },

        showError(message) {
            alert(message);
        },
        resetForm() {
            this.first_name = '';
            this.last_name = '';
            this.gender = '';
            this.marital_status = '';
            this.date_of_birth = '';
            this.email = '';
            this.whatsapp = '';
            this.city = '';
            this.country = '';
            this.hometown = '';
            this.parental_status = '';
            this.occupation = '';
            this.industry = '';
            this.employment_details = '';
            this.education = '';
            this.religion = '';
            this.caste = '';
            this.height = '';
            this.weight = '';
            this.skin = '';
            this.profile = '';
            //this.photo = '';
            //this.password = '';
            //this.confirmPassword = '';

        },
        generateYearRange() {
            const currentYear = new Date().getFullYear();
            const years = [];
            for (let year = currentYear - 18; year >= currentYear - 65; year--) {
                years.push(year);
            }
            return years;
        },
        updateDays() {
            if (this.month && this.year) {
                this.day = '';  // Reset day selection
                const isLeapYear = (this.year % 4 === 0 && this.year % 100 !== 0) || this.year % 400 === 0;
                const daysInFeb = isLeapYear ? 29 : 28;
                const daysInMonth = [31, daysInFeb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                this.daysInMonth = daysInMonth[this.month - 1];
            }
        },
    }
};
</script>
<style scoped></style>