<template>
    <public-header></public-header>

    <div class="container">
        <div class="my-2">
            <i class="bi bi-clock-history"></i> {{ post ? humanFriendlyDate(post.created_at) : 'Loading...' }}
        </div>
        <h3 class="my-2">{{ post ? post.title : 'Loading...' }}</h3>
        <img v-if="post && post.featured_image" :src="post.featured_image" alt="Featured Image" class="mb-4">
        <div v-if="post" class="d-flex justify-content-between align-items-center my-2">
            <div class="d-flex align-items-center">
                <img v-if="post.user.photo_url" :src="post.user.photo_url" class="rounded-circle"
                    style="width: 40px; height: 40px; margin-right: 10px;" alt="Author">
                <div>{{ post.user.name }}</div>
            </div>
            <div>
                <!-- WhatsApp Icon -->
                <i class="bi bi-whatsapp" @click="shareToWhatsApp"
                    style="cursor: pointer; margin-right: 10px; color: #25D366;"></i>
                <!-- Facebook Icon -->
                <i class="bi bi-facebook" @click="shareToFacebook"
                    style="cursor: pointer; margin-right: 10px; color: #1877F2;"></i>
                <!-- LinkedIn Icon -->
                <i class="bi bi-linkedin" @click="shareToLinkedIn"
                    style="cursor: pointer; margin-right: 10px; color: #0A66C2;"></i>
                <!-- Instagram Icon (Using share-fill as a stand-in) -->
                <i class="bi bi-instagram" @click="shareToInstagram" style="cursor: pointer; color: #E4405F;"></i>
            </div>
        </div>
        <div v-html="post ? post.content : 'Loading...'"></div>
    </div>
</template>

<script>
import axios from 'axios';
import PublicHeader from '@/components/PublicHeader.vue';

export default {
    components: {
        'public-header': PublicHeader,
    },
    data() {
        return {
            post: null
        };
    },
    mounted() {
        const slug = this.$route.params.slug;

        axios.get(process.env.VUE_APP_API_URL + 'blog/post/' + slug)
            .then((response) => {
                this.post = response.data;
            })
            .catch((error) => {
                console.error('Error fetching blog post', error);
            });
    },
    methods: {
        goBack() {
            // Implement goBack method if needed
        },
        humanFriendlyDate(date) {
            return new Date(date).toLocaleDateString(undefined, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            });
        },
        shareToWhatsApp() {
            const text = `Check out this post: ${this.post.title} - ${window.location.href}`;
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;
            window.open(whatsappUrl, '_blank');
        },
        shareToFacebook() {
            const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
            window.open(facebookUrl, '_blank');
        },
        shareToLinkedIn() {
            const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`;
            window.open(linkedInUrl, '_blank');
        },
        shareToInstagram() {
            const url = window.location.href;
            if (navigator.clipboard && window.isSecureContext) {
                // Use the Clipboard API when available
                navigator.clipboard.writeText(url).then(() => {
                    alert("The link has been copied to your clipboard. Open Instagram and paste it in your story or post to share.");
                }).catch((err) => {
                    console.error('Could not copy the link to clipboard: ', err);
                    alert("Failed to copy the link. Please manually copy the URL from the address bar.");
                });
            } else {
                // Fallback for older browsers
                // Create a temporary text area element to hold the URL
                const textArea = document.createElement("textarea");
                textArea.value = url;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    const successful = document.execCommand('copy');
                    const msg = successful ? 'successful' : 'unsuccessful';
                    console.log('Fallback: Copying text command was ' + msg);
                    alert("The link has been copied to your clipboard. Open Instagram and paste it in your story or post to share.");
                } catch (err) {
                    console.error('Fallback: Oops, unable to copy', err);
                    alert("Failed to copy the link. Please manually copy the URL from the address bar.");
                }
                document.body.removeChild(textArea);
            }
        },
    }
}
</script>

<style scoped></style>
