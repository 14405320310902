<template>
  <header class="sticky-top d-flex justify-content-between align-items-center bg-light p-2">
    <button class="btn" @click="goBack">
      <i class="bi bi-arrow-left"></i>
    </button>
    <h5>{{ heading }}</h5>
    <div>
      <!-- <button class="btn" :class="{ 'highlighted': showNotifications }" @click="toggleNotifications">
        <i class="bi bi-bell"></i>
      </button> -->
      <button v-if="reportedUserId != null" class="btn" @click="showReportModal = true">
        <i class="bi bi-exclamation-octagon-fill"></i>
      </button>
      <ul v-if="showNotifications" class="dropdown-menu">
        <li v-for="notification in notifications" :key="notification.id" class="dropdown-item text-truncate">
          <a href="#">{{ notification.data.text }}</a>
        </li>
      </ul>
    </div>
  </header>
  <div v-show="showReportModal" class="card" style="margin-top: 20px;">
    <div class="card-header d-flex justify-content-between">
      <h5 class="modal-title">Report Member</h5>
      <button type="button" class="btn-close" aria-label="Close" @click="showReportModal = false">
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label for="details" class="form-label">Report Details</label>
            <textarea rows="5" id="details" class="form-control" v-model="details" required
              :class="{ 'is-invalid': errors.details }" placeholder="Enter your profile details and requirements for your match." minlength="30" maxlength="500"></textarea>
            <div v-if="errors.details" class="invalid-feedback">{{ errors.details }}</div>
          </div>
          <div class="mb-3">
            <label for="screenshot" class="form-label">Upload Image (optional)</label>
            <input type="file" id="screenshot" @change="handleFileUpload" accept="image/*" class="form-control" :class="{ 'is-invalid': errors.screenshot }">
            <div v-if="errors.screenshot" class="invalid-feedback">{{ errors.screenshot }}</div>
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary me-2" @click="showReportModal = false">Cancel</button>
            <button type="button" class="btn btn-primary" @click="submitReport">Report Member</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <warning-popup></warning-popup>
  <bottom-sheet ref="bottomSheet" @apply-filters="handleApplyFilters"></bottom-sheet>
</template>

<script>
import axios from 'axios';
import WarningPopup from './WarningPopup.vue';
import BottomSheet from './BottomSheet.vue';

export default {
  components: {
    'warning-popup': WarningPopup,
    'bottom-sheet': BottomSheet
  },
  props: {
    heading: String
  },
  data() {
    return {
      showNotifications: false,
      notifications: [],
      reportedUserId: null,
      showReportModal: false,
      reportDetails: '',
      selectedFile: null,
      details: '',
      screenshot: '',
      errors: {
        details: '',
        screenshot: ''
      }
    };
  },
  mounted() {
    const pathSegments = window.location.pathname.split('/');
    const searchIndex = pathSegments.indexOf('search');
    this.reportedUserId = searchIndex !== -1 ? pathSegments[searchIndex + 1] : null;
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
      if (this.showNotifications) {
        this.fetchNotifications();
      }
    },
    fetchNotifications() {
      const accessToken = sessionStorage.getItem('accessToken');
      axios.get(process.env.VUE_APP_API_URL + 'member/notifications', { headers: { Authorization: `Bearer ${accessToken}` } })
        .then(response => {
          this.notifications = response.data;
        })
        .catch(error => console.error('Error fetching notifications:', error));
    },
    handleFileUpload(event) {
      this.screenshot = event.target.files[0];
      if (this.screenshot && this.screenshot.size > 2097152) { // 2MB
        this.errors.screenshot = 'File size should not exceed 2MB';
        this.screenshot = null;
      } else {
        this.errors.screenshot = '';
      }
    },
    resetForm(){
      this.details = '';
      this.screenshot = '';
    },
    submitReport() {
      if(!this.details){
        this.errors.details = 'Please enter report details.';
        return;
      }
      const accessToken = sessionStorage.getItem('accessToken');
      const formData = new FormData();
      formData.append('reported_user_id', this.reportedUserId);
      formData.append('details', this.details);
      formData.append('screenshot', this.screenshot);

      axios.post(process.env.VUE_APP_API_URL + 'member/report', formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          this.showReportModal = false;
          this.details = '';
          this.screenshot = '';
          this.errors.details = '';
          this.errors.screenshot = '';
          setTimeout(() => {
            this.showReportModal = false;
          }, 3000);
        })
        .catch(error => {
          this.errors.details = error.response.message || 'An error occurred while reporting the member.';
        });
    }
  }
};
</script>

<style scoped>
header {
  top: 0;
  z-index: 1020;
  border-bottom: 1px solid #DEE2E6;
}

.modal {
  display: block;
  /* Show the modal by default (you will toggle this with v-show based on your actual logic) */
  background: rgba(0, 0, 0, 0.5);
  /* Backdrop effect */
}

.close-popup {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0.5rem;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
