<template>
    <public-header></public-header>

    <div class="container my-4">
        <div v-html="page ? page.text : 'Loading...'"></div>
    </div>
    <!-- <main-footer></main-footer> -->
</template>

<script>
import axios from 'axios';
import PublicHeader from '@/components/PublicHeader.vue';
//import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        'public-header': PublicHeader,
        //'main-footer': MainFooter
    },
    data() {
        return {
            page: {
                title: '404 Page Not Found',
                text: 'The requested page was not found.'
            }
        };
    },
    mounted() {
        const slug = this.$route.params.slug;

        axios.get(process.env.VUE_APP_API_URL + 'pages/' + slug)
            .then((response) => {
                this.page = response.data;
            })
            .catch((error) => {
                console.error('Error while incrementing view', error);
            });
    },
    methods: {
        goBack() {

        }
    }
}
</script>

<style scoped>
/* Add Poppins font */
body {
    font-family: 'Poppins', sans-serif;
}
</style>
