<template>
    <main-header heading="Subscription Packages"></main-header>

    <div class="container my-4">
        <div class="row">
            <!-- Display packages as cards -->
            <div class="col-12 col-md-4" v-for="pkg in packages" :key="pkg.id">
                <div class="card mb-4">
                    <div class="card-body">
                        <h5 class="card-title">{{ pkg.title }}</h5>
                        <p class="card-text">{{ formatPrice(pkg.price) }} 
                            <span v-if="pkg.id !== 1"> per {{ getDuration(pkg.duration) }}</span>
                        </p>
                        <ul>
                            <li>Profiles: {{ pkg.profiles }}</li>
                            <!-- <li>Messages: {{ pkg.messages }}</li>
                            <li>Comments: {{ pkg.comments }}</li> -->
                            <li v-if="pkg.account_manager">Match Making Help</li>
                        </ul>
                        <button :class="pkg.id === currentPackageId ? 'btn btn-disabled' : 'btn btn-secondary'" @click="selectPackage(pkg)"
                            :disabled="pkg.id === currentPackageId || pkg.id < currentPackageId">
                            {{ pkg.id === currentPackageId ? 'Current Package' : 'Select' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter,
    },
    data() {
        return {
            packages: [],
            currentPackageId: null,
            // Retrieve the accessToken from sessionStorage
            accessToken: sessionStorage.getItem('accessToken'),
        };
    },
    methods: {
        formatPrice(value) {
            return Math.abs(value) < 0.01 ? 'Free' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PKR' }).format(value);
        },
        getDuration(days) {
            const months = days / 30;
            return months === 1 ? 'month' : `${months} months`;
        },
        async fetchPackages() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'subscription/packages', {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                    },
                });
                this.packages = response.data;
            } catch (error) {
                console.error('Error fetching packages:', error);
            }
        },
        async fetchCurrentPackage() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'member/subscription', {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                    },
                });
                this.currentPackageId = response.data.subscription.package_id;
            } catch (error) {
                console.error('Error fetching current package:', error);
            }
        },
        selectPackage(selectedPackage) {
            sessionStorage.setItem('package', JSON.stringify(selectedPackage));
            this.$router.push('/subscription/payment');
        }
    },
    created() {
        this.fetchPackages();
        this.fetchCurrentPackage();
    }
};
</script>

<style scoped>
/* Your styles here */
</style>