<template>
  <transition name="slide-up">
    <div class="overlay" v-if="isVisible">
      <div class="bottom-sheet">
        <div class="close-icon" @click="toggleSheet">
          <i class="fas fa-times"></i>
        </div>
        <form @submit.prevent="applyFilters">
          <h5 class="mb-3">Search Filters</h5>
          <div class="mb-3">
            <label class="mb-2">Age Range: {{ filters.ageRange[0] }} - {{ filters.ageRange[1] }}</label>
            <vue-slider v-model="filters.ageRange" :min="18" :max="65" :tooltip="'always'"></vue-slider>
          </div>
          <div class="mb-3 row">
            <div class="col-6">
              <input type="text" class="form-control" v-model="filters.first_name" placeholder="Enter first name">
            </div>
            <div class="col-6">
              <input type="text" class="form-control" v-model="filters.last_name" placeholder="Enter last name">
            </div>
          </div>
          <div class="mb-3">
            <select class="form-select" v-model="filters.gender">
              <option value="">Select Gender</option>
              <option v-for="option in genderOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <select class="form-select" v-model="filters.maritalStatus">
              <option value="">Select Marital Status</option>
              <option v-for="option in maritalStatusOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <select class="form-select" v-model="filters.education">
              <option value="">Select Qualification</option>
              <option v-for="option in educationOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <select class="form-select" v-model="filters.religion">
              <option value="">Select Religion</option>
              <option v-for="option in religionOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <select class="form-select" v-model="filters.country">
              <option value="">Select Country</option>
              <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <div v-if="filters.country === 'Pakistan'">
              <select id="city" class="form-select" v-model="filters.city">
                <option value="" disabled>Select City</option>
                <option v-for="cityOption in pakistanCities" :key="cityOption" :value="cityOption">
                  {{ cityOption }}
                </option>
              </select>
            </div>
            <div v-else>
              <input type="text" id="city" class="form-control" v-model="filters.city" placeholder="Enter City">
            </div>
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" v-model="filters.favoriteProfiles">
            <label class="form-check-label" @click="filters.favoriteProfiles = !filters.favoriteProfiles">My Favorite
              Profiles</label>
          </div>
          <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-secondary" @click="clearFilters">Clear</button>
            <button type="submit" class="btn btn-primary">Apply</button>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  emits: ['apply-filters'],
  components: {
    VueSlider,
  },
  data() {
    return {
      isVisible: false,
      showCountries: false,
      filteredCountries: [],
      genderOptions: [
        { label: 'Male', value: "Male" },
        { label: 'Female', value: "Female" },
      ],
      educationOptions: [
        { label: 'PhD', value: "PhD" },
        { label: 'Masters', value: "Master's" },
        { label: 'Bachelors', value: "Bachelor's" },
        { label: 'Intermediate', value: "Intermediate" },
        { label: 'Matric', value: "Matric" },
        { label: 'Other', value: "Other" }
      ],
      maritalStatusOptions: [
        { label: 'Single', value: 'Single' },
        { label: 'Married', value: 'Married' },
        { label: 'Divorced', value: 'Divorced' },
        { label: 'Widow', value: 'Widow' }
      ],
      religionOptions: [
        { value: 'Islam', label: 'Islam' },
        { value: 'Islam - Sunni, Barelvi', label: 'Islam - Sunni, Barelvi' },
        { value: 'Islam - Sunni, Deobandi', label: 'Islam - Sunni, Deobandi' },
        { value: 'Islam - Sunni, Ahle Hadees', label: 'Islam - Sunni, Ahle Hadees' },
        { value: 'Islam - Sunni, Other', label: 'Islam - Sunni, Other' },
        { value: 'Islam - Shia', label: 'Islam - Shia' },
        { value: 'Christianity', label: 'Christianity' },
        { value: 'Hinduism', label: 'Hinduism' },
        { value: 'Buddhism', label: 'Buddhism' },
        { value: 'Sikhism', label: 'Sikhism' }
      ],
      countryOptions: [
        { label: 'Pakistan', value: 'Pakistan' },
        { label: 'United States', value: 'United States' },
        { label: 'Canada', value: 'Canada' },
        { label: 'United Kingdom', value: 'United Kingdom' },
        { label: 'Australia', value: 'Australia' },
        { label: 'Germany', value: 'Germany' },
        { label: 'France', value: 'France' },
        { label: 'Italy', value: 'Italy' },
        { label: 'Spain', value: 'Spain' },
        { label: 'Japan', value: 'Japan' },
        { label: 'Malaysia', value: 'Malaysia' },
        { label: 'China', value: 'China' },
        { label: 'India', value: 'India' },
        { label: 'Brazil', value: 'Brazil' },
        { label: 'Mexico', value: 'Mexico' },
        { label: 'Argentina', value: 'Argentina' },
        { label: 'South Africa', value: 'South Africa' },
        { label: 'Egypt', value: 'Egypt' },
        { label: 'Nigeria', value: 'Nigeria' },
        { label: 'Kenya', value: 'Kenya' },
        { label: 'Bahrain', value: 'Bahrain' },
        { label: 'Kuwait', value: 'Kuwait' },
        { label: 'Oman', value: 'Oman' },
        { label: 'Qatar', value: 'Qatar' },
        { label: 'Saudi Arabia', value: 'Saudi Arabia' },
        { label: 'United Arab Emirates', value: 'United Arab Emirates' },
        { label: 'Finland', value: 'Finland' },
        { label: 'Russia', value: 'Russia' },
        { label: 'South Korea', value: 'South Korea' },
        { label: 'Singapore', value: 'Singapore' },
        { label: 'New Zealand', value: 'New Zealand' },
        { label: 'Hungary', value: 'Hungary' },
        { label: 'Turkey', value: 'Turkey' },
        { label: 'Sweden', value: 'Sweden' },
        { label: 'Italy', value: 'Italy' }

      ],
      pakistanCities: ["Karachi", "Lahore", "Islamabad", "Faisalabad", "Rawalpindi", "Hyderabad", "Multan", "Gujranwala", "Quetta", "Bahawalpur", "Sargodha", "Sialkot", "Sukkur", "Larkana", "Sheikhupura", "Rahim Yar Khan", "Jhang", "Dera Ghazi Khan", "Gujrat", "Kasur", "Mardan", "Mingora", "Dera Ismail Khan", "Nawabshah", "Sahiwal", "Mirpur Khas", "Okara", "Burewala", "Jacobabad", "Saddiqabad", "Kohat", "Murree", "Khanewal", "Hafizabad", "Kohlu", "Khuzdar", "Chakwal", "Daska", "Gojra", "Mandi Bahauddin", "Toba Tek Singh", "Khairpur", "Chishtian", "Attock", "Vehari", "Mianwali", "Jhelum", "Kashmor", "Qila Abdullah", "Haripur", "Lodhran", "Malakand", "Swabi", "Charsadda", "Thatta", "Bhakkar", "Badin", "Layyah", "Loralai", "Khanpur", "Turbat", "Muzaffargarh", "Ghotki", "Karak", "Nankana Sahib", "Ziarat", "Tando Allahyar", "Chaman", "Kandhkot", "Hangu", "Timargara", "Kasur", "Zafarwal", "Qila Saifullah", "Lakki Marwat", "Rajanpur", "Hala", "Kahror Pakka", "Mastung", "Gwadar", "Thari Mirwah", "Moro", "Tando Muhammad Khan", "Tando Adam", "Jalalpur Jattan", "Naushahro Feroze", "New Mirpur", "Dipalpur", "Shikarpur", "Havelian", "Lala Musa", "Bhawana", "Shahdadkot", "Matiari", "Ghauspur", "Akora Khattak", "Jampur", "Kotli", "Kahuta", "Kandiaro"],
      filters: {
        first_name: '',
        last_name: '',
        gender: '',
        ageRange: [18, 65],
        maritalStatus: '',
        country: '',
        city: '',
        education: '',
        religion: '',
        favoriteProfiles: false
      }
    };
  },
  methods: {
    toggleSheet() {
      this.isVisible = !this.isVisible;
    },
    setFilter(filter, value) {
      this.filters[filter] = value;
    },
    validateAgeRange() {
      if (this.filters.ageRange[0] > this.filters.ageRange[1]) {
        this.filters.ageRange[1] = this.filters.ageRange[0];
      }
    },
    filterCountries() {
      if (!this.filters.country) {
        this.filteredCountries = this.countries;
      } else {
        this.filteredCountries = this.countries.filter(c => c.toLowerCase().includes(this.filters.country.toLowerCase()));
      }
      this.showCountries = true;
    },
    setCountry(country) {
      this.filters.country = country;
      this.showCountries = false;
    },
    hideCountries() {
      setTimeout(() => { this.showCountries = false; }, 200);
    },
    clearFilters() {
      this.filters = {
        gender: '',
        ageRange: [18, 65],
        maritalStatus: '',
        country: '',
        city: '',
        education: '',
        religion: '',
        favoriteProfiles: false
      };
    },
    applyFilters() {
      this.toggleSheet();
      this.$emit('apply-filters', this.filters);
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 20px;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
}

.close-icon {
  color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.list-group {
  position: absolute;
  width: 100%;
  z-index: 2001;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}
</style>