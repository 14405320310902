<template>
  <div class="home-page">
    <div class="background" :style="backgroundStyle">
      <div class="gradient-overlay d-flex flex-column justify-content-between">
        <div class="container text-center mt-auto mb-auto">
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <h1 class="text-white">PakSouls.com</h1>
              </div>
              <div class="hearts mb-4">
                <i class="fas fa-heart big-heart"></i>
                <i class="fas fa-heart small-heart"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- Fixed-bottom section -->
        <div class="fixed-bottom text-white p-3">
          <div class="container">
            <div class="d-flex flex-column align-items-center">
              <div v-if="errors.social" class="alert alert-danger mt-3">{{ errors.social }}</div>
              <p class="mb-3">Join 1,000+ premium and 10,000+ total members seeking love. Find your soul mate now!
                <router-link to="/blog" class="text-white">View Our Blog</router-link>
              </p>
              <router-link to="/login" class="btn btn-login mb-3 w-100">Login</router-link>
              
              OR
              <!-- <button @click="loginWithFacebook">Login with Facebook</button> -->
              <GoogleLogin class="my-2" :callback="googleLoginCB" prompt></GoogleLogin>

              <p class="register-now mb-0">New User? <router-link to="/register" class="text-white">Register
                  Now!</router-link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { decodeCredential } from 'vue3-google-login';

export default {
  components: {

  },
  data() {
    return {
      errors: {
        social: null,
      },
      fbReady: false,
      backgroundImages: [
        'https://cdn.midjourney.com/ef54eb17-9bd9-46d3-9e9e-7290c5978420/0_0.webp',
        'https://cdn.midjourney.com/c5d98332-0c6e-40bf-9c96-1d9780849dd6/0_1.webp',
        'https://cdn.midjourney.com/f546b908-0607-4d38-8d8e-7a3a0976b863/0_0.webp',
        'https://cdn.midjourney.com/cd8ae573-857a-45fd-90a6-7c3753f733c9/0_2.webp',
        'https://cdn.midjourney.com/80610726-0343-416e-8ed2-5c7a8df7ac31/0_0.webp',
      ],
    };
  },
  computed: {
    backgroundStyle() {
      const randomIndex = Math.floor(Math.random() * this.backgroundImages.length);
      return {
        'background-image': `url('${this.backgroundImages[randomIndex]}')`,
        'background-size': 'cover',
        'background-position': 'center',
        height: '100vh',
      };
    },
  },
  created() {
    if (sessionStorage.getItem('accessToken')) {
      const redirect = '/members/search';
      this.$router.push(redirect);
    }
  },
  mounted() {
    document.title = 'PakSouls.com - Freemium Matrimonial Platform for Serious Pakistanis';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Discover your soulmate on PakSouls.com, the top Pakistani matrimonial site for secure and authentic matchmaking.');
    this.preloadImages();
  },
  methods: {
    /* eslint-disable */
    loginWithFacebook() {
      FB.login(response => {
        if (response.authResponse) {
          console.log('Welcome!  Fetching your information.... ');
          FB.api('/me', function (response) {
            console.log('Good to see you, ' + response.name + '.');
            console.log(response);
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, { scope: 'email,public_profile', return_scopes: true });
    },
    async googleLoginCB(response) {
      let user = decodeCredential(response.credential);
      try {
        const payload = {
          email: user.email,
          source: 'google'
        };
        const response = await axios.post(process.env.VUE_APP_API_URL + 'member/login/social', payload);
        let status = response.data.status;
        if (status == 'success') { //LOGIN
          // Route to login logic if user exists
          let token = response.data.token;
          let loggedinUser = response.data.user;

          sessionStorage.setItem('accessToken', token);
          sessionStorage.setItem('user', JSON.stringify(loggedinUser));

          const redirectUrl = this.$route.query.redirect || '/members/search';
          this.$router.push(redirectUrl);
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.errors.social = error.response.data.error;
        } else {
          // Handle registration for non-existing users
          let newUser = {
            first_name: user.family_name,
            last_name: user.given_name,
            email: user.email,
            photo_url: user.picture,
            email_verified: true,
            source: 'google'
          };
          this.$router.push({
            path: '/register',
            query: { user: JSON.stringify(newUser) }
          });
        }
      }
    },
    preloadImages() {
      this.backgroundImages.forEach(imageUrl => {
        const img = new Image();
        img.src = imageUrl;
      });
    },
  },
};
</script>

<style scoped>
.home-page .background {
  height: 100vh;
}

.home-page .gradient-overlay {
  background: linear-gradient(to top, #FD0755, transparent 75%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* Organize content in a column */
  justify-content: space-between;
  /* Distribute space between top and bottom */
}

.big-heart,
.small-heart {
  color: rgba(255, 255, 255, 0.9);
}

.big-heart {
  font-size: 8em;
}

.small-heart {
  font-size: 6em;
}

.btn-login {
  background-color: white;
  color: #FD0755;
  border-radius: 20px;
  padding: 12px 20px;
}

.text-white,
.register-now {
  color: white;
}
</style>