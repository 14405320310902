<template>
    <main-header heading="Referral Program"></main-header>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <h3>Referral Program</h3>
                <div class="alert alert-info" v-if="!affiliateData.count">
                    No earnings yet. Start sharing your affiliate link!
                </div>
                <div class="my-4" v-else>
                    <p>Total Money Earned: <strong>{{ affiliateData.sum }}</strong></p>
                    <hr />
                    <p>Total Money Paid: <strong>{{ affiliateData.paid }}</strong></p>
                </div>
                <div class="affiliate-codes mt-4">
                    <p>Referral Code: <strong class="me-2">{{ affiliateData.referral_code }}</strong>
                        <button class="btn btn-secondary btn-sm"
                            @click="copyToClipboard(affiliateData.referral_code)">Copy</button>
                    </p>
                    <p>Referral Link: <strong class="me-2"><a href="#">{{
                        affiliateData.referral_url
                    }}</a></strong>
                        <button class="btn btn-secondary btn-sm"
                            @click="copyToClipboard(affiliateData.referral_url)">Copy</button>
                    </p>
                    <p>You can redeem your earned money once you reach <strong>{{ affiliateData.threshold_money }}</strong>.
                    </p>
                </div>
                <div class="terms mt-4">
                    <h4>Terms and Conditions</h4>
                    <p>Participants in the PakSouls.com Referral Program must adhere to the following terms and
                        conditions:</p>

                    <ul class="list-group">
                        <li class="list-group-item">New members must be genuine individuals aged 18 years or older.</li>
                        <li class="list-group-item">A profile photo showing the member's face is required. Photos that
                            are fake or AI-generated are not permitted.</li>
                        <li class="list-group-item">All provided information, including name, date of birth, gender,
                            city, country, and other details, must be accurate and truthful.</li>
                        <li class="list-group-item">Members must specify their interests within their profile.</li>
                        <li class="list-group-item">Match preferences must be clearly stated in the profile.</li>
                        <li class="list-group-item">Email addresses and WhatsApp numbers must be verified.</li>
                        <li class="list-group-item">Complete profile details should be filled out.</li>
                        <li class="list-group-item">Members are required to log in to their account at least once.</li>
                        <li class="list-group-item bg-warning">Failure to meet these conditions will result in the
                            profile being deemed bogus, and no payments will be made for such profiles.</li>
                    </ul>

                    <h4 class="mt-4">Payment Chart</h4>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Gender</th>
                                <th scope="col">Location</th>
                                <th scope="col">Payment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Male</td>
                                <td>In Pakistan</td>
                                <td>{{ affiliateData.per_male_inside }}</td>
                            </tr>
                            <tr>
                                <td>Female</td>
                                <td>In Pakistan</td>
                                <td>{{ affiliateData.per_female_inside }}</td>
                            </tr>
                            <tr>
                                <td>Male</td>
                                <td>Outside Pakistan</td>
                                <td>{{ affiliateData.per_male_outside }}</td>
                            </tr>
                            <tr>
                                <td>Female</td>
                                <td>Outside Pakistan</td>
                                <td>{{ affiliateData.per_female_outside }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>You can redeem your earned money once you reach <strong>{{ affiliateData.threshold_money }}</strong>.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>
  
<script>
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    name: 'MemberAffiliated',
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    },
    data() {
        return {
            affiliateData: {
                count: 0,
                sum: 0,
                paid: 0,
                referral_code: '',
                referral_url: '',
                threshold_money: ''
            }
        };
    },
    mounted() {
        this.fetchAffiliateData();
    },
    methods: {

        fetchAffiliateData() {
            const accessToken = sessionStorage.getItem('accessToken');
            axios.get(process.env.VUE_APP_API_URL + 'affiliate-marketing/account', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    this.affiliateData = response.data;
                })
                .catch(error => {
                    console.error("There was an error fetching the affiliate data:", error);
                    // Handle error here, e.g., show notification or set error state
                });
        },
        copyToClipboard(text) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(text).then(() => {
                    alert('Copied to clipboard');
                }, (err) => {
                    console.error('Could not copy text: ', err);
                });
            } else {
                let textarea = document.createElement('textarea');
                textarea.value = text;
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    let successful = document.execCommand('copy');
                    let msg = successful ? 'Copied to clipboard' : 'Failed to copy';
                    alert(msg);
                } catch (err) {
                    console.error('Fallback: Oops, unable to copy', err);
                }
                document.body.removeChild(textarea);
            }
        }
    }
};
</script>
  
<style scoped>
/* Add any custom styles here */
</style>
  