<template>
  <public-header heading="Set New Password"></public-header>

  <div class="container my-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <h3>Set New Password</h3>
        <p>Please enter the authentication code you received on your email or WhatsApp and your new password.</p>

        <form @submit.prevent="submitForm">
          <div class="mb-3">
            <input type="text" class="form-control" placeholder="Please enter auth code" v-model="authCode" required
              :class="{ 'is-invalid': authCodeError }">
            <div v-if="authCodeError" class="invalid-feedback">{{ authCodeError }}</div>
          </div>
          <div class="mb-3 position-relative">
            <input :type="isPasswordVisible ? 'text' : 'password'" class="form-control" placeholder="New Password"
              v-model="newPassword" required :class="{ 'is-invalid': newPasswordError }">
            <div v-if="newPasswordError" class="invalid-feedback">{{ newPasswordError }}</div>
            <!-- Toggle Button -->
            <button type="button" class="btn btn-sm position-absolute top-50 end-0 translate-middle-y"
              @click="togglePasswordVisibility">
              <i :class="isPasswordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
            </button>
          </div>
          <div class="mb-3 position-relative">
            <input :type="isPasswordVisible ? 'text' : 'password'" class="form-control"
              placeholder="Confirm New Password" v-model="confirmPassword" required
              :class="{ 'is-invalid': confirmPasswordError }">
            <div v-if="confirmPasswordError" class="invalid-feedback">{{ confirmPasswordError }}</div>
            <!-- Using the same toggle button to control visibility of both password fields -->
          </div>
          <button type="submit" class="btn btn-primary w-100">Change Password</button>
          <p class="mt-3">
            <router-link to="/login" class="btn btn-secondary w-100">Login</router-link>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PublicHeader from '@/components/PublicHeader.vue';

export default {
  data() {
    return {
      authCode: '',
      newPassword: '',
      confirmPassword: '',
      authCodeError: '',
      newPasswordError: '',
      confirmPasswordError: '',
      isPasswordVisible: false, 
    };
  },
  components: {
    'public-header': PublicHeader,
  },
  methods: {
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    async submitForm() {
      this.authCodeError = '';
      this.newPasswordError = '';
      this.confirmPasswordError = '';

      if (this.newPassword !== this.confirmPassword) {
        this.newPasswordError = 'Passwords do not match.';
        return;
      }

      try {
        await axios.post(
          process.env.VUE_APP_API_URL + 'member/set-new-password',
          {
            auth_code: this.authCode,
            new_password: this.newPassword,
            confirm_new_password: this.newPassword
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        this.$router.push('/login');
        // Handle success response here, if needed
        // You can also add a success message or redirect the user to another page
      } catch (error) {
        // Handle the error as needed and set appropriate error messages
        if (error.response) {
          this.authCodeError = 'Invalid authentication code';
        } else {
          this.authCodeError = 'An error occurred while processing your request.';
        }
      }
    }
  }
};
</script>
