<template>
  <div>
    <h1>Member Dashboard</h1>
    <p>This is member dashboard</p>
    <router-link to="/member/preferences">Preferences</router-link>
    <!-- <router-link to="/member/logout">Logout</router-link>
    <router-link to="/members/search">Search</router-link> -->

    <button @click="showSearchFilters = true">Open Filters</button>

    <!-- Bind the 'open' class to showSearchFilters -->
    <SearchFilters :class="{ open: showSearchFilters }" v-if="showSearchFilters" @close="showSearchFilters = false" />
  </div>
</template>

<script>
import SearchFilters from '@/components/SearchFilters.vue';

export default {
  name: 'MemberDashboard',
  components: {
    SearchFilters
  },
  data() {
    return {
      showSearchFilters: false,
    };
  },
  methods: {
    toggleSearchFilters() {
      this.showSearchFilters = !this.showSearchFilters;
    }
  }
};
</script>

<style>
/* Your existing styles */
</style>
