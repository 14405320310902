<template>
    <main-header heading="Member Profile"></main-header>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div v-if="member !== null">
                    <div v-if="showAlert && subscription.credits < 2"
                        class="alert alert-warning alert-dismissible fade show my-4" role="alert" @click="hideAlert">
                        <span v-if="subscription.credits < 2 && subscription.credits > 0">You have {{
                    subscription.credits }} profile credits remaining. </span>
                        <span v-else-if="subscription.credits <= 0">You do not have any credits remaining.
                            <router-link to="/subscription/packages">Please upgrade your
                                subscription.</router-link></span>
                        <button type="button" class="btn-close" aria-label="Close"></button>
                    </div>
                    <div class="member-profile d-flex align-items-start my-4">
                        <div v-if="member.match_percentage > 0" class="image-container">
                            <div class="text-overlay">{{ member.match_percentage }}%</div>
                        </div>
                        <img class="is-premium" v-if="member.is_premium" src="@/assets/images/premium.png"
                            alt="Premium Member" />
                        <!-- Profile Photo -->
                        <img :src="member.photo_url" :alt="member.name" class="profile-photo me-3">
                        <div>
                            <h5>
                                <i class="icon-name"></i>
                                {{ member.name }}, {{ member.age }}
                                <i class="icon-age"></i>
                                <a class="link link-secondary link-sm" href="#"
                                    @click="copyToClipboard(member.profile_url)">
                                    <i class="far fa-copy"></i>
                                </a>
                            </h5>
                            <p>
                                <i class="icon-location"></i>
                                {{ member.city }}, {{ member.country }}
                            </p>
                            <p v-if="member.occupation">
                                <i class="fas fa-briefcase"></i> {{ member.occupation }}
                            </p>
                            <div v-if="member.can_view_profile">
                                <div class="mb-2">
                                    <div class="d-inline-flex align-items-center justify-content-center me-2"
                                        v-if="member.mobile_verified_at != null">
                                        <i class="bi bi-whatsapp me-2"></i> WhatsApp Verified
                                    </div>
                                    <div class="d-inline-flex align-items-center justify-content-center me-2"
                                        v-if="member.email_verified_at != null">
                                        <i class="bi bi-envelope-fill me-2"></i> Email Verified
                                    </div>
                                    <div class="d-inline-flex align-items-center justify-content-center me-2"
                                        v-if="member.views > 100">
                                        <i class="bi bi-star-fill me-2"></i> Popular Profile
                                    </div>
                                    <div class="d-inline-flex align-items-center justify-content-center me-2"
                                        v-if="member.last_login_at != null">
                                        <i class="bi bi-clock-fill me-2"></i> Last Seen {{
                    formatHumanFriendlyDate(member.last_login_at) }}
                                    </div>
                                    <div class="d-inline-flex align-items-center justify-content-center me-2">
                                        <router-link @click="trackEarnMoneyClick" to="/affiliate-marketing/dashboard">
                                            <i class="fas fa-money-bill-wave me-2"></i>
                                            Earn Money!</router-link>
                                    </div>
                                    <div class="me-2">
                                        <router-link :to="`/member/chat/${member.id}`"
                                            class="chat d-inline-flex align-items-center justify-content-center">
                                            <i class="bi bi-chat-dots me-2"></i> Chat with {{ member.first_name }}
                                        </router-link>
                                    </div>
                                    <div class="follow d-inline-flex align-items-center justify-content-center me-2"
                                        @click="toggleSubscription">
                                        <i
                                            :class="{ 'bi bi-person-check-fill me-2': isSubscribed, 'bi bi-person-plus-fill me-2': !isSubscribed }"></i>
                                        Follow {{ member.first_name }}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="member.can_view_profile">
                        <div v-if="member.hide_profile === false || member.profile_request_accepted === true"
                            id="private-information">
                            <div v-if="member.photos.length > 0">
                                <!-- Photo Gallery Section -->
                                <div v-if="member.photos.length > 0" class="row mt-4">
                                    <div class="col-12 col-md-6">
                                        <h5>Photo Gallery</h5>
                                        <swiper :pagination="{ clickable: true }" :slidesPerView="4" :spaceBetween="10">
                                            <swiper-slide v-for="(photo, index) in member.photos" :key="index">
                                                <img @click.prevent="openSlider(index)" :src="photo.url"
                                                    alt="Gallery Photo" class="gallery-photo">
                                            </swiper-slide>
                                        </swiper>
                                    </div>
                                </div>
                                <!-- Popup Slider -->
                                <div v-if="showSlider" class="slider-overlay">
                                    <Carousel :itemsToShow="1" :wrapAround="true">
                                        <Slide v-for="(photo) in member.photos" :key="photo.id">
                                            <img :src="photo.url" class="slider-image">
                                        </Slide>
                                    </Carousel>
                                    <button class="close-slider btn-close" @click="showSlider = false"></button>
                                </div>
                            </div>

                            <!-- Profile Text Section -->
                            <div class="row mt-4" v-if="member">
                                <div class="col-12 col-md-6">
                                    <h5>Profile</h5>
                                    <div class="member-story">
                                        <ul class="list-group list-group-flush">

                                            <li class="list-group-item" v-if="member.date_of_birth">Age: {{
                    member.age }} years</li>
                                            <li class="list-group-item" v-if="member.gender">Gender: {{ member.gender }}
                                            </li>
                                            <li class="list-group-item" v-if="member.marital_status">Marital Status: {{
                    member.marital_status
                }}</li>
                                            <li class="list-group-item" v-if="member.height || member.weight">Height &
                                                Weight: {{ member.height
                                                }} ft, {{ member.weight }} kg</li>
                                            <li class="list-group-item"
                                                v-if="member.education_level || member.industry">Qualification: {{
                    member.education_level }}</li>
                                            <li class="list-group-item"
                                                v-if="member.occupation || member.employment_details">Profession: {{
                    member.occupation }}, {{ member.employment_details }}</li>
                                            <li class="list-group-item"
                                                v-if="member.religion || member.sect || member.caste">Religion: {{
                    member.religion }}, {{ member.sect }}, {{ member.caste }} caste</li>
                                            <li class="list-group-item" v-if="member.city || member.country">Current
                                                Location: {{ member.city
                                                }}, {{ member.country }}</li>
                                            <li class="list-group-item" v-if="member.parental_status">Parental Status:
                                                {{ member.parental_status
                                                }}</li>
                                        </ul>
                                        <div v-if="member.profile">
                                            <h5 class="mt-4">About Me</h5>
                                            <span>{{ member.profile }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Interests Section -->
                            <div v-if="member.interests.length > 0" class="row mt-4">
                                <div class="col-12 col-md-6">
                                    <h5>Interests</h5>
                                    <div>
                                        <span class="badge border border-secondary me-2 mb-2"
                                            v-for="(interest, index) in member.interests" :key="index">
                                            {{ interest }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- Attributes Section -->
                            <div v-if="member.match_preferences !== null && Object.keys(member.match_preferences).length > 0"
                                class="row mt-4">
                                <div class="col-12 col-md-6 col-lg-4 mb-3">
                                    <h5>Match Preferences</h5>
                                    <ul class="list-group list-group-flush ideal-matches">
                                        <li v-if="member.match_preferences != null" class="list-group-item">
                                            Age: {{ member.match_preferences.age }}
                                        </li>
                                        <li v-if="member.match_preferences.country != null" class="list-group-item">
                                            Countries:
                                            {{ member.match_preferences.country }}</li>
                                        <li v-if="member.match_preferences.education_level != null"
                                            class="list-group-item">
                                            Education:
                                            {{ member.match_preferences.education_level }}</li>
                                        <li v-if="member.match_preferences.religion != null" class="list-group-item">
                                            Religion:
                                            {{ member.match_preferences.religion }}</li>
                                        <li v-if="member.match_preferences.marital_status != null"
                                            class="list-group-item">
                                            Marital Status:
                                            {{ member.match_preferences.marital_status }}</li>
                                        <li v-if="member.match_preferences.caste != null" class="list-group-item">Caste:
                                            {{ member.match_preferences.caste }}</li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Action Buttons -->
                            <!-- <div class="row mt-2 mb-5">
                        <div class="col-12 d-flex justify-content-start">
                            <button v-if="!profileRequestSent" type="button" class="btn btn-primary me-2 w-100"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Show Interest"
                                @click="updateProfileRequest('profile', $event)">
                                <i class="bi bi-heart"></i> I'm interested!
                            </button>
                            <div v-else>
                                Request sent.
                            </div>
                        </div>
                    </div> -->
                            <!-- PROFILE MESSAGES -->
                            <div v-if="!member.hide_comments" class="message-section mb-4">
                                <h5 class="my-4">Comments</h5>
                                <div class="messages" @scroll="checkScroll">
                                    <div v-for="message in messages" :key="message.id"
                                        :class="{ 'd-flex justify-content-end mb-2': isCurrentUser(message.user.id), 'd-flex justify-content-start mb-2': !isCurrentUser(message.user.id) }">
                                        <div
                                            :class="['message-bubble', { 'ms-auto': isCurrentUser(message.from_user_id), 'me-auto': !isCurrentUser(message.user.id) }]">
                                            <a :href="message.user.profile_url">
                                                <img :src="message.user.photo_url || defaultPhotoUrl(message.user.gender)"
                                                    alt="Profile Image" class="profile-img rounded-circle me-2">
                                            </a>
                                            <div :class="['message-content rounded-pill', messageBubbleClass(message.from_user_id)]"
                                                @mouseover="showDeleteButton(message.id)">
                                                <a :href="message.user.profile_url">
                                                    <strong class="fw-bold">{{ message.user.name }}</strong>
                                                </a>
                                                <div v-if="message.message.length > 60" data-bs-toggle="tooltip"
                                                    :title="message.message" data-bs-placement="top"
                                                    data-bs-trigger="hover">{{ message.message.slice(0, 60) + '...' }}
                                                </div>
                                                <div v-else>{{ message.message }}</div>
                                                <button
                                                    v-if="hoveredMessage === message.id && user.id === message.user.id"
                                                    class="btn btn-danger btn-sm delete-button"
                                                    @click="deleteMessage(message.id)">
                                                    <i class="bi bi-x"></i>
                                                </button>
                                            </div>

                                        </div>
                                        <div
                                            :class="['message-date mt-1', { 'text-end': isCurrentUser(message.user.id), 'text-start': !isCurrentUser(message.user.id) }]">
                                            {{ formatHumanFriendlyDate(message.messaged_at) }}
                                        </div>
                                    </div>

                                    <div v-if="loading" class="text-center">
                                        Loading...
                                    </div>
                                </div>
                                <div class="input-group my-3">
                                    <input type="text" class="form-control" placeholder="Type a message..."
                                        v-model="newMessage" maxlength="1024">
                                    <button class="btn btn-secondary" @click="sendMessage">Send</button>
                                    <small class="text-muted">Sharing WhatsApp, phone or any abusive word will result in
                                        blocking your profile!</small>
                                </div>
                                <div v-if="messageSent" class="text-success">
                                    Message sent
                                </div>
                            </div>
                            <div class="row mt-4 my-4">
                                <div class="col-12 d-flex justify-content-start">
                                    <!-- Request Photos Button -->
                                    <!-- <template v-if="member.photos.length === 0 && !member.photos_request_sent">
                                <button v-if="!photosRequestSent" type="button" class="btn btn-secondary me-2"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Request Photos"
                                    @click="updateProfileRequest('photos', $event)">
                                    <i class="bi bi-images"></i> Request Photos
                                </button>
                                <div v-else>
                                    Request for photos sent.
                                </div>
                            </template> -->

                                    <!-- Send Chat Request Button -->
                                    <!-- <template v-if="!chatRequestSent">
                                <button type="button" class="btn btn-success me-2" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Request Chat"
                                    @click="updateProfileRequest('chat', $event)">
                                    <i class="bi bi-chat-dots"></i> Send Chat Request
                                </button>
                            </template>
                            <div v-else>
                                Chat request sent.
                            </div> -->
                                    <!-- <div class="me-2">
                                        <router-link :to="`/member/chat/${member.id}`" class="btn btn-primary">
                                            <i class="bi bi-chat-dots"></i> Send Message
                                        </router-link>
                                    </div> -->
                                    <!-- Invite Member Button -->
                                    <div v-if="!member.already_invited">
                                        <div v-if="user.invite_only" class="me-2">
                                            <button v-if="!memberInvited" type="button" class="btn btn-success"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Invite Member"
                                                @click="inviteMember()">
                                                <i class="bi bi-person-plus"></i> Invite Member
                                            </button>
                                            <div v-else>
                                                Member invited.
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Ignore Member Button -->
                                    <div style="width: 100%;"> <!-- Ensure the parent container takes full width -->
                                        <div v-if="!memberIgnored" class="d-flex justify-content-end">
                                            <a href="#" class="text-danger" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Ignore Member"
                                                @click.prevent="ignoreMember()">
                                                <i class="bi bi-person-dash"></i> Ignore {{ member.first_name }}
                                            </a>
                                        </div>
                                        <div v-else class="d-flex justify-content-end">
                                            <span class="text-muted">Member ignored.</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div v-else-if="member.profile_request_sent">
                            You have either sent or received a request from this member. Please check the 'Request Sent'
                            or
                            'Received Requests' sections.
                        </div>
                        <div v-else>
                            <p> This is a private Profile. Please request profile owner for viewing information</p>
                            <div class="row mt-4">
                                <div class="col-12 d-flex justify-content-start">
                                    <button v-if="!profileRequestSent" type="button" class="btn btn-primary me-2"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Show Interest"
                                        @click="updateProfileRequest('profile', $event)">
                                        <i class="bi bi-heart"></i> I'm interested!
                                    </button>
                                    <div v-else>
                                        Request sent.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="subscription.credits > 0">
                        <div v-if="!subscribedToProfile" class="row">
                            <!-- Profile Text Section -->
                            <div class="col-12 mb-3" v-if="member">
                                <div class="col-12 col-md-6">
                                    <h5>Profile</h5>
                                    <div class="member-story">
                                        <ul class="list-group list-group-flush">

                                            <li class="list-group-item" v-if="member.date_of_birth">Age: {{
                    member.age }} years</li>
                                            <li class="list-group-item" v-if="member.gender">Gender: {{ member.gender }}
                                            </li>
                                            <li class="list-group-item" v-if="member.marital_status">Marital Status: {{
                    member.marital_status
                }}</li>
                                            <li class="list-group-item" v-if="member.height || member.weight">Height &
                                                Weight: {{ member.height
                                                }} ft, {{ member.weight }} kg</li>
                                            <li class="list-group-item"
                                                v-if="member.education_level || member.industry">Qualification: {{
                    member.education_level }}</li>
                                            <li class="list-group-item"
                                                v-if="member.occupation || member.employment_details">Profession: {{
                    member.occupation }}, {{ member.employment_details }}</li>
                                            <li class="list-group-item"
                                                v-if="member.religion || member.sect || member.caste">Religion: {{
                    member.religion }}, {{ member.sect }}, {{ member.caste }} caste</li>
                                            <li class="list-group-item" v-if="member.city || member.country">Current
                                                Location: {{ member.city
                                                }}, {{ member.country }}</li>
                                            <li class="list-group-item" v-if="member.parental_status">Parental Status:
                                                {{ member.parental_status
                                                }}</li>
                                        </ul>
                                        <div v-if="member.profile">
                                            <h5 class="mt-4">About Me</h5>
                                            <span>{{ member.profile }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Interests Section -->
                            <div v-if="member.interests.length > 0" class="col-12 mb-3">
                                <div class="col-12 col-md-6">
                                    <h5>Interests</h5>
                                    <div>
                                        <span class="badge border border-secondary me-2 mb-2"
                                            v-for="(interest, index) in member.interests" :key="index">
                                            {{ interest }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- Attributes Section -->
                            <div class="col-12 mb-3"
                                v-if="member.match_preferences !== null && Object.keys(member.match_preferences).length > 0">
                                <h5>Match Preferences</h5>
                                <ul class="list-group list-group-flush ideal-matches">
                                    <li v-if="member.match_preferences != null" class="list-group-item">
                                        Age: {{ member.match_preferences.age }}
                                    </li>
                                    <li v-if="member.match_preferences.country != null" class="list-group-item">
                                        Countries:
                                        {{ member.match_preferences.country }}</li>
                                    <li v-if="member.match_preferences.education_level != null" class="list-group-item">
                                        Education:
                                        {{ member.match_preferences.education_level }}</li>
                                    <li v-if="member.match_preferences.religion != null" class="list-group-item">
                                        Religion:
                                        {{ member.match_preferences.religion }}</li>
                                    <li v-if="member.match_preferences.marital_status != null" class="list-group-item">
                                        Marital Status:
                                        {{ member.match_preferences.marital_status }}</li>
                                    <li v-if="member.match_preferences.caste != null" class="list-group-item">Caste:
                                        {{ member.match_preferences.caste }}</li>
                                </ul>
                            </div>
                            <div class="col-12 mb-3">
                                <div v-if="!member.can_view_premium_profile">
                                    <span class="badge bg-warning text-wrap">
                                        This is a premium profile. Please <router-link
                                            to="/subscription/packages">upgrade your
                                            subscription</router-link> to contact them.
                                    </span>
                                </div>
                                <div v-else>
                                    <button type="button" class="btn btn-primary w-100" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Show Interest" @click="subscribeToProfile">
                                        <i v-if="!loading" class="bi bi-heart me-2"></i>
                                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        <span v-if="!loading">I'm interested!</span>
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div v-else>
                    <p>Requested profile was not found. Search more profile <router-link
                            to="/members/search">here</router-link>.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>
<script>
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import SwiperCore, { Pagination } from 'swiper/core';

import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';



SwiperCore.use([Pagination]);

export default {
    name: 'MemberProfile',
    components: {
        Swiper,
        SwiperSlide,
        Carousel,
        Slide,
        'main-header': MainHeader,
        'main-footer': MainFooter,
    },
    data() {
        return {
            subscription: {
                credits: 0
            },
            showAlert: false,

            subscribedToProfile: false,
            // loading: false,
            requestSuccess: false,
            requestMessage: '',

            isSubscribed: false,
            isProfileActive: false,
            profileRequestSent: false,
            profileRequestAccepted: false,
            PhotosRequestAccepted: false,

            isChatActive: false,
            chatRequestSent: false,
            isPhotosActive: false,
            photosRequestSent: false,
            memberIgnored: false,
            memberInvited: false,
            //SETTINGS
            member: {
                photo_url: '',
                first_name: '',
                last_name: '',
                name: '',
                age: '',
                city: '',
                country: '',
                about: '',
                height: '',
                weight: '',
                marital_status: '',
                gender: '',
                created_at: '',
                mobile_verified_at: '',
                email_verified_at: '',
                profile_complete: '',
                profile_request_sent: false,
                profile_request_accepted: false,
                photos_request_accepted: false,
                hide_comments: false,

                interests: [],
                photos: [],
            },
            showSlider: false,
            currentSlideIndex: 0,
            token: sessionStorage.getItem('accessToken'),
            //FOR MESSAGES
            messages: [],
            hoveredMessage: null,
            newMessage: '',
            loading: false,
            messageSent: false,
            currentPage: 1,
            hasMoreMessages: true,
            user: null,
            userId: null,
        };
    },
    created() {
        this.getLoggedinUser();
        this.fetchMemberProfile();
        //ADD VIEW
        const memberId = this.$route.params.memberId;

        if (memberId) {
            const requestData = {
                viewed_id: memberId,
            };
            const config = {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            };
            axios.post(process.env.VUE_APP_API_URL + 'member/add-view', requestData, config)
                .then(() => {

                })
                .catch((error) => {
                    console.error('Error while incrementing view', error);
                });
        } else {
            console.error('Member ID or Access Token is missing.');
        }


    },
    mounted() {
        this.getRemainingCredits();
    },
    methods: {
        copyToClipboard(text) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(text).then(() => {
                    alert('Profile link copied');
                }, (err) => {
                    console.error('Could not copy text: ', err);
                });
            } else {
                let textarea = document.createElement('textarea');
                textarea.value = text;
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    let successful = document.execCommand('copy');
                    let msg = successful ? 'Profile link copied' : 'Failed to copy';
                    alert(msg);
                } catch (err) {
                    console.error('Fallback: Oops, unable to copy', err);
                }
                document.body.removeChild(textarea);
            }
        },
        async getRemainingCredits() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + `member/subscription/credits-remaining`, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                });
                this.subscription.credits = response.data.credits_remaining;
                this.showAlert = true;
            } catch (error) {

                console.error(error);
            }
        },
        hideAlert() {
            this.showAlert = false;
        },
        async subscribeToProfile() {
            this.loading = true;
            const memberId = this.$route.params.memberId;
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + `member/subscribe/${memberId}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                });
                this.requestSuccess = true;
                this.requestMessage = response.data.message;
                this.member.can_view_profile = true;
            } catch (error) {
                this.requestSuccess = false;
                this.requestMessage = error.response.data.message;
            } finally {
                this.loading = false;
                this.subscribedToProfile = true;
            }
        },
        getLoggedinUser() {
            const userString = sessionStorage.getItem('user');
            if (userString) {
                this.user = JSON.parse(userString);
                this.user.invite_only = this.user.invite_only === '1' ? true : false;
            }
        },
        async fetchMemberProfile() {
            const memberId = this.$route.params.memberId;
            const headers = {
                "Authorization": "Bearer " + this.token,
                "Content-Type": "application/json",
            };
            await axios.get(process.env.VUE_APP_API_URL + `members/search/${memberId}`, { headers })
                .then(response => {
                    this.member = response.data;
                    this.member.interests = this.member.interests ? this.member.interests.split(',') : [];

                    let data = this.member.match_preferences;

                    if (typeof data === 'string') {
                        try {
                            data = JSON.parse(data);
                        } catch (e) {
                            console.error('Error parsing data:', e);
                            return;
                        }
                    }
                    for (let key in data) {
                        let value = data[key];
                        if (Array.isArray(value) && value.length > 0) {
                            data[key] = value.join(', ');
                        } else {
                            delete data[key];
                        }
                    }
                    this.member.match_preferences = data;

                    this.profileRequestSent = this.member.profile_request_sent;
                    this.profileRequestAccepted = this.member.profile_request_accepted;
                    this.profilePhotosAccepted = this.member.photos_request_accepted;

                    this.photosRequestSent = this.member.photos_request_sent;
                    this.chatRequestSent = this.member.chat_request_sent;
                    this.memberInvited = this.member.already_invited;
                    this.member.hide_photos = this.member.hide_photos === '1' ? true : false;
                    this.member.hide_profile = this.member.hide_profile === '1' ? true : false;
                    this.member.hide_comments = this.member.hide_comments === '1' ? true : false;
                    this.member.invite_only = this.member.invite_only === '1' ? true : false;
                    if (this.member.hide_photos) {
                        this.member.photo_url = this.member.gender == 'male' ? process.env.BASE_URL + 'images/male-placeholder.png' : process.env.BASE_URL + 'images/female-placeholder.png';
                    }
                    const timestamp = new Date().getTime();
                    this.member.photo_url += `?timestamp=${timestamp}`;
                    //PHOTOS
                    this.member.photos.map(photo => {
                        const timestamp = new Date().getTime(); // Get current timestamp
                        photo.url += `?timestamp=${timestamp}`; // Append timestamp to the photo URL
                        return photo;
                    });
                    //ALREADY SUBSCRIBED
                    this.isSubscribed = this.member.is_subscribed;
                    //LOAD MESSAGES
                    this.loadMessages();
                    this.userId = memberId;

                })
                .catch(error => {
                    console.error(error);
                    this.member = null;
                    // Handle error, e.g., show an error message to the user
                });
        },
        updateProfileRequest(item) {
            // Change icon color
            if (item === 'profile') {
                this.isProfileActive = !this.isProfileActive;
                sessionStorage.setItem('isProfileActive' + this.member.id, this.isProfileActive);
            } else if (item === 'chat') {
                this.isChatActive = !this.isChatActive;
                sessionStorage.setItem('isChatActive' + this.member.id, this.isChatActive);
            } else if (item === 'photos') {
                this.isPhotosActive = !this.isPhotosActive;
                sessionStorage.setItem('isPhotosActive' + this.member.id, this.isPhotosActive);
            }
            const postData = {
                requested_id: this.member.id,
                items: item
            };

            axios.post(process.env.VUE_APP_API_URL + "members/update-profle-request", postData, {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log(response);
                    if (item == 'profile') {
                        this.profileRequestSent = true;
                    } else if (item == 'photos') {
                        this.photosRequestSent = true;
                    } else if (item == 'chat') {
                        this.chatRequestSent = true;
                    }
                })
                .catch(error => {
                    console.error(error);
                    if (item == 'profile') {
                        this.profileRequestSent = true;
                    } else if (item == 'photos') {
                        this.photosRequestSent = true;
                    } else if (item == 'chat') {
                        this.chatRequestSent = true;
                    }
                });
        },
        ignoreMember() {

            const postData = {
                requested_id: this.member.id,
            };

            axios.post(process.env.VUE_APP_API_URL + "members/ignore-member", postData, {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.error(response);
                    this.photosRequestSent = true;
                    this.chatRequestSent = true;
                    this.memberInvited = false;
                    this.memberIgnored = true;

                    this.$router.push('/members/search');
                })
                .catch(error => {
                    console.error(error);

                });
        },
        inviteMember() {
            this.member.already_invited = true;
            const postData = {
                requested_id: this.member.id,
            };

            axios.post(process.env.VUE_APP_API_URL + "members/invite-member", postData, {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log(response);
                    this.memberInvited = false;


                })
                .catch(error => {
                    console.error(error);

                });
        },

        openSlider(index) {
            this.currentSlideIndex = index;
            this.showSlider = true;
        },
        closeSlider() {
            this.showSlider = false;
        },
        calculateAge(dateOfBirth) {
            const birthday = new Date(dateOfBirth);
            const today = new Date();
            let age = today.getFullYear() - birthday.getFullYear();
            const m = today.getMonth() - birthday.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
                age--;
            }
            return age.toString();
        },
        formatHumanFriendlyDate(dateInput) {
            const currentDate = new Date();
            const inputDate = new Date(dateInput);
            const diffInSeconds = Math.floor((currentDate - inputDate) / 1000);
            if (diffInSeconds < 60) { // less than 1 minute
                return "Just now";
            } else if (diffInSeconds < 3600) { // less than 1 hour
                return Math.floor(diffInSeconds / 60) + " minutes ago";
            } else if (diffInSeconds < 86400) { // less than 1 day
                return Math.floor(diffInSeconds / 3600) + " hours ago";
            } else if (diffInSeconds < 604800) { // less than 1 week
                return Math.floor(diffInSeconds / 86400) + " days ago";
            } else {
                // For dates older than 1 week, return the date in a readable format
                return inputDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });
            }
        },
        formatKey(key) {
            return key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
        },
        async loadMessages() {
            if (!this.member.hide_comments) {
                // Only proceed if loading more messages is allowed
                if (this.loading || !this.hasMoreMessages) return;

                this.loading = true;
                try {
                    const response = await axios.post(
                        `${process.env.VUE_APP_API_URL}member/profile/messages?page=${this.currentPage}`,
                        {
                            to_user_id: this.member.id
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${this.token}`
                            }
                        }
                    );
                    const updatedMessages = response.data.map(message => {

                        return message;
                    });

                    // Append new messages regardless of their count
                    this.messages = [...this.messages, ...updatedMessages];

                    // Check if there are less than 10 messages, indicating no more messages are available
                    if (updatedMessages.length < 10) {
                        this.hasMoreMessages = false; // Prevent further calls to loadMessages
                    } else {
                        this.currentPage++; // Only increment currentPage if more messages might be available
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loading = false;
                }
            }
        },

        async sendMessage() {
            if (!this.newMessage.trim()) return;


            const tempMessage = {
                from_user_id: this.user.id,
                to_user_id: this.member.id,
                message: this.newMessage,
                user: {
                    photo_url: this.user.photo_url,
                    name: this.user.name,
                    gender: this.user.gender,
                },
                messaged_at: new Date().toISOString(),
            };
            // Update messages array optimistically

            this.messages.unshift(tempMessage);
            //this.messages.push(tempMessage);


            try {
                await axios.post(process.env.VUE_APP_API_URL + 'member/profile/message/send', {
                    message: this.newMessage,
                    to_user_id: this.member.id
                }, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });
                this.messageSent = true;
                this.newMessage = '';
                setTimeout(() => this.messageSent = false, 3000);

            } catch (error) {
                console.error(error);
            }
        },
        checkScroll(event) {
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                this.loadMessages();
            }
        },
        isCurrentUser(fromUserId) {
            return this.member.id === fromUserId;
        },
        messageBubbleClass(fromUserId) {
            return this.isCurrentUser(fromUserId) ? 'bg-current-user' : 'bg-other-user';
        },
        humanFriendlyDate(date) {
            return new Date(date).toLocaleString();
        },
        trackEarnMoneyClick() {
            if (typeof window.gtag !== "undefined") {
                window.gtag('event', 'click', {
                    'event_category': 'Referral Program',
                    'event_label': 'Earn Money Link',
                    'value': 1
                });
            }
            // Optional: Use Vue Router to navigate to the dashboard page programmatically
            // this.$router.push('/affiliate-marketing/dashboard');
        },
        async toggleSubscription() {
            const memberId = this.$route.params.memberId;
            try {
                const status = this.isSubscribed ? 'unsubscribed' : 'subscribed';
                const response = await axios.post(process.env.VUE_APP_API_URL + 'member/profile/subscribe', {
                    user_id: memberId,
                    status: status
                }, {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                });
                if (response.status === 200) {
                    this.isSubscribed = !this.isSubscribed;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        },
        showDeleteButton(messageId) {
            this.hoveredMessage = messageId;
        },
        deleteMessage(messageId) {
            // Remove the message from the messages array
            const index = this.messages.findIndex(message => message.id === messageId);
            if (index !== -1) {
                this.messages.splice(index, 1);
            }

            // Send DELETE request to the server
            axios.delete(`${process.env.VUE_APP_API_URL}member/comment/${messageId}/delete`, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            });
        },
    }
};
</script>

<style scoped>
.image-container {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    left: 10px;
    background-size: cover;
    background-position: center;
    background-image: url('@/assets/images/match.png');
}

.text-overlay {
    font-size: 8px;
    position: absolute;
    top: 60%;
    left: 40%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.is-premium {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    z-index: 100;
}

.member-profile {
    position: relative;
    z-index: 10;
}

/* Style for the image boxes */
.gallery-photo {
    width: 120px;
    height: 120px;
    padding: 10px;
    /* Add padding around each image */
    cursor: pointer;
}

/* Style for the popup images */
.gallery-photo-popup {
    max-width: 100%;
    max-height: 70vh;
    /* Adjust the maximum height as needed */
    margin: 0 auto;
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    object-fit: cover;
}

.profile-image {
    margin: 0 20px;
}

.profile-image img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid white;
}

.verified-icons {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 10px;
    padding: 5px;
}

.icon-link i {
    color: #FFFFFF;
}

.icon-link i:hover,
.icon-link i.active {}

.badge {
    color: #D42481;
}

.card-img-overlay {
    background-color: transparent;
    /* Remove background color */
}

.btn-close {
    position: absolute;
    right: 0;
    top: 0;
}

.slider-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-image {
    max-width: 90%;
    max-height: 80%;
    object-fit: contain;
}

.close-slider {
    position: absolute;
    top: 100px;
    right: 20px;
    cursor: pointer;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
}

.ideal-matches .list-group-item {
    color: #000000;
}

/* .messages {
    max-height: 400px;
    overflow-y: auto;
} */

/* Messages from the current user */
.bg-current-user {
    background-color: #E9F5FF;
    padding: 8px 16px;
}

.bg-other-user {
    background-color: #F2F3F5;
    padding: 8px 16px;
}

.message-bubble {
    display: flex;
    align-items: center;
    width: 80%;
}

.message-content {
    width: 80%;
    padding: 10px 20px;
    border-radius: 15px;
    margin-bottom: 5px;
    position: relative;
}

.delete-button {
    font-size: 6px;
    color: #000;
    position: absolute;
    top: 12px;
    right: 10px;
    padding: 0;
    background-color: transparent;
    border: none;
    z-index: 100;
}



.message-date {
    font-size: 0.8rem;
}

/* Ensure images are circular and properly aligned */
.rounded-circle {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 50%;
    object-fit: cover;
}

.follow {
    cursor: pointer;
}

.alert {
    position: fixed;
    top: 40px;
    /* left: 50%;
    transform: translateX(-50%); */
    z-index: 1000;
}
</style>