<template>
    <main-header heading="Match Preferences"></main-header>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div v-if="successMessage" class="alert alert-success mt-3">{{ successMessage }}</div>
                <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
                <h3 class="mt-4">Change Match Preferences</h3>
                <form @submit.prevent="savePreferences">
                    <div class="mb-3">
                        <label for="ageRange" class="form-label">Age Range: {{ preferences.age[0] }} - {{
                    preferences.age[1]
                }}</label>
                        <vue-slider v-model="preferences.age" :min="18" :max="65" :tooltip="'hover'"></vue-slider>
                    </div>
                    <div class="mb-3">
                        <label for="country" class="form-label">Countries:</label>
                        <select id="country" class="form-select" v-model="preferences.country" multiple>
                            <option v-for="(value, index) in countries" :key="index" :value="value">{{ value }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <div v-if="preferences.country.includes('Pakistan')">
                            <label for="city" class="form-label">Cities:</label>
                            <select id="city" class="form-select" v-model="preferences.city" multiple>
                                <option value="" disabled>City</option>
                                <option v-for="cityOption in pakistanCities" :key="cityOption" :value="cityOption">
                                    {{ cityOption }}
                                </option>
                            </select>
                        </div>
                        <div v-else>
                            <label for="city" class="form-label">Cities:</label>
                            <input type="text" id="city" class="form-control" v-model="preferences.city">
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="education_level" class="form-label">Education Level:</label>
                        <select id="education_level" class="form-select" v-model="preferences.education_level" multiple>
                            <option v-for="(value, index) in educationLevels" :key="index" :value="value">{{ value }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="religion" class="form-label">Religions:</label>
                        <select id="religion" class="form-select" v-model="preferences.religion" multiple>
                            <option v-for="(value, index) in religions" :key="index" :value="value">{{ value }}</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="caste" class="form-label">Castes:</label>
                        <select id="caste" class="form-select" v-model="preferences.caste" multiple>
                            <option v-for="(value, index) in castes" :key="index" :value="value">{{ value }}</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="marital_status" class="form-label">Marital Status:</label>
                        <select id="marital_status" class="form-select" v-model="preferences.marital_status" multiple>
                            <option v-for="(value, index) in maritalStatues" :key="index" :value="value">{{ value }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="gender" class="form-label">Genders:</label>
                        <select id="gender" class="form-select" v-model="preferences.gender" multiple>
                            <option v-for="(value, index) in genders" :key="index" :value="value">{{ value }}</option>
                        </select>
                    </div>
                    <button type="submit" class="btn btn-primary w-100 mb-5">Save Preferences</button>
                </form>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import axios from "axios";
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

//import Multiselect from 'vue-multiselect';
// import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter,
        VueSlider,
        //Multiselect,
    },
    data() {
        return {
            preferences: {
                age_min: 18,
                age_max: 65,
                age: [18, 65],
                country: [],
                city: [],
                education_level: [],
                religion: [],
                marital_status: [],
                gender: [],
            },
            countries: [
                'Pakistan', 'United States', 'Canada', 'United Kingdom', 'Australia',
                'Germany', 'France', 'Italy', 'Spain', 'Finland', 'Japan', 'Malaysia', 'China', 'India',
                'Brazil', 'Mexico', 'Argentina', 'South Africa', 'Egypt', 'Nigeria',
                'Kenya', 'Bahrain', 'Kuwait', 'Oman', 'Qatar', 'Saudi Arabia', 'United Arab Emirates', 'Russia',
                'South Korea', 'Singapore', 'New Zealand', 'Hungary', 'Turkey', 'Sweden', 'Italy' 
            ],
            pakistanCities: ["Karachi", "Lahore", "Islamabad", "Faisalabad", "Rawalpindi", "Hyderabad","Multan", "Gujranwala", "Quetta", "Bahawalpur", "Sargodha", "Sialkot", "Sukkur", "Larkana", "Sheikhupura", "Rahim Yar Khan", "Jhang", "Dera Ghazi Khan", "Gujrat", "Kasur", "Mardan", "Mingora", "Dera Ismail Khan", "Nawabshah", "Sahiwal", "Mirpur Khas", "Okara", "Burewala", "Jacobabad", "Saddiqabad", "Kohat", "Murree", "Khanewal", "Hafizabad", "Kohlu", "Khuzdar", "Chakwal", "Daska", "Gojra", "Mandi Bahauddin", "Toba Tek Singh", "Khairpur", "Chishtian", "Attock", "Vehari", "Mianwali", "Jhelum", "Kashmor", "Qila Abdullah", "Haripur", "Lodhran", "Malakand", "Swabi", "Charsadda", "Thatta", "Bhakkar", "Badin", "Layyah", "Loralai", "Khanpur", "Turbat", "Muzaffargarh", "Ghotki", "Karak", "Nankana Sahib", "Ziarat", "Tando Allahyar", "Chaman", "Kandhkot", "Hangu", "Timargara", "Kasur", "Zafarwal", "Qila Saifullah", "Lakki Marwat", "Rajanpur", "Hala", "Kahror Pakka", "Mastung", "Gwadar", "Thari Mirwah", "Moro", "Tando Muhammad Khan", "Tando Adam", "Jalalpur Jattan", "Naushahro Feroze", "New Mirpur", "Dipalpur", "Shikarpur", "Havelian", "Lala Musa", "Bhawana", "Shahdadkot", "Matiari", "Ghauspur", "Akora Khattak", "Jampur", "Kotli", "Kahuta", "Kandiaro"],
            educationLevels: [
                'PhD', 'Master\'s', 'Bachelor\'s', 'Intermediate', 'Matric', 'Other'
            ],
            maritalStatues: [
                'single', 'married', 'divorced', 'widow', 'other'
            ],
            genders: ['Male', 'Female'],
            religions: [
                'Islam', 'Islam - Sunni, Barelvi', 'Islam - Sunni, Deobandi',
                'Islam - Sunni, Ahle Hadees', 'Islam - Sunni, Other', 'Islam - Shia',
                'Christianity', 'Hinduism', 'Buddhism', 'Sikhism'
            ],
            castes: ["Jat", "Rajput", "Arain", "Syed", "Mughal", "Baloch", "Pathan (Pashtun)",
                "Sheikh", "Siddiqui", "Ansari", "Awan", "Gujjar", "Butt", "Malik", "Khan",
                "Qureshi", "Chaudhry", "Mirza", "Baig", "Bhatti", "Rana", "Alvi", "Hashmi",
                "Usmani", "Abbasi", "Farooqi", "Gilani", "Kazmi", "Rizvi", "Naqvi", "Zaidi",
                "Bukhari", "Soomro", "Mahar", "Bugti", "Mengal", "Memon", "Marri", "Bhutto", "Talpur",
                "Mazari", "Jamali", "Khosa", "Leghari", "Magsi", "Rind", "Khetran", "Jatoi",
                "Jiskani", "Khuhro", "Shah", "Yousufzai", "Other"],
            successMessage: '',
            errorMessage: '',
        };
    },
    created() {
        this.loadPreferences();
    },
    methods: {
        async loadPreferences() {
            const apiUrl = process.env.VUE_APP_API_URL + "member/settings";
            const accessToken = sessionStorage.getItem('accessToken');
            const headers = { "Authorization": "Bearer " + accessToken };
            try {
                const response = await axios.get(apiUrl, { headers });
                const savedPreferences = JSON.parse(response.data.settings.match_preferences);
                if (savedPreferences) {
                    this.preferences = savedPreferences;
                }
            } catch (error) {
                console.error("Error fetching member settings:", error);
            }
        },
        savePreferences() {
            const match_preferences = {
                age: [this.preferences.age[0], this.preferences.age[1]],
                country: this.preferences.country,
                city: this.preferences.city,
                education_level: this.preferences.education_level,
                religion: this.preferences.religion,
                marital_status: this.preferences.marital_status,
                caste: this.preferences.caste,
                gender: this.preferences.gender
            };
            const accessToken = sessionStorage.getItem('accessToken');
            axios.post(process.env.VUE_APP_API_URL + 'member/update-settings', { match_preferences }, {
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Content-Type": "application/json",
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        this.successMessage = 'Preferences saved successfully.';
                        this.errorMessage = '';
                        sessionStorage.setItem('match_preferences', JSON.stringify(match_preferences));
                    } else {
                        this.successMessage = '';
                        this.errorMessage = 'Failed to save preferences.';
                    }
                })
                .catch(error => {
                    this.successMessage = '';
                    this.errorMessage = 'Failed to save preferences. Error: ' + error;
                });
        }
    },
    mounted() {
        const savedPreferences = JSON.parse(sessionStorage.getItem('match_preferences'));
        if (savedPreferences) {
            this.preferences = savedPreferences;
        }
    },
};
</script>

<style scoped>
/* Add your custom CSS styling here */
</style>