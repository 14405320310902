<template>
  <main-header heading="Change Password"></main-header>
  <div class="container my-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div v-if="toastMessage" class="alert" :class="toastType ? 'alert-success' : 'alert-danger'">
          {{ toastMessage }}
        </div>
        <h3 class="mt-4">Change Password</h3>
        <form @submit.prevent="submitForm">
          <div class="mb-3 position-relative">
            <input :type="isPasswordVisible ? 'text' : 'password'" class="form-control" placeholder="Current Password" id="currentPassword"
              v-model="passwords.currentPassword" :class="{ 'is-invalid': !validationState.currentPassword }">
            <div class="invalid-feedback">Current password is required.</div>
            <button type="button" class="btn btn-sm position-absolute top-50 end-0 translate-middle-y"
              @click="togglePasswordVisibility">
              <i :class="isPasswordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
            </button>
          </div>
          <div class="mb-3 position-relative">
            <input :type="isPasswordVisible ? 'text' : 'password'" class="form-control" placeholder="New Password"  id="newPassword"
              v-model="passwords.newPassword" :class="{ 'is-invalid': !validationState.newPassword }">
            <div class="invalid-feedback">New password is required.</div>
          </div>
          <div class="mb-3 position-relative">
            <input :type="isPasswordVisible ? 'text' : 'password'" class="form-control" placeholder="Confirm New Password" id="confirmNewPassword"
              v-model="passwords.confirmNewPassword" :class="{ 'is-invalid': !validationState.confirmNewPassword }">
            <div class="invalid-feedback">Passwords must match and cannot be empty.</div>
            <!-- No toggle button needed for confirm new password as it uses the same visibility state -->
          </div>
          <div class="d-flex justify-content-between mt-4">
            <button type="submit" class="btn btn-primary w-100" :disabled="isLoading">
              <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ isLoading ? 'Loading...' : 'Submit' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <main-footer></main-footer>
</template>

<script>
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
  components: {
    'main-header': MainHeader,
    'main-footer': MainFooter,
  },
  data() {
    return {
      isLoading: false,
      passwords: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      validationState: {
        currentPassword: true,
        newPassword: true,
        confirmNewPassword: true,
      },
      isPasswordVisible: false,
      toastMessage: '',
      toastType: true,
      token: sessionStorage.getItem('accessToken'),
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    validateForm() {
      const passwordsMatch = this.passwords.newPassword === this.passwords.confirmNewPassword;
      this.validationState = {
        currentPassword: !!this.passwords.currentPassword,
        newPassword: !!this.passwords.newPassword,
        confirmNewPassword: passwordsMatch && !!this.passwords.confirmNewPassword,
      };
      return this.validationState.currentPassword && this.validationState.newPassword && this.validationState.confirmNewPassword;
    },
    resetForm() {
      this.passwords = {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      };
      this.validationState = {
        currentPassword: true,
        newPassword: true,
        confirmNewPassword: true,
      };
    },
    async submitForm() {
      if (!this.validateForm()) {
        this.toastType = false;
        this.toastMessage = "Please correct the errors before submitting.";
        return;
      }

      this.isLoading = true;
      const payload = {
        current_password: this.passwords.currentPassword,
        new_password: this.passwords.newPassword,
        new_password_confirmation: this.passwords.confirmNewPassword,
      };

      try {
        await axios.put(process.env.VUE_APP_API_URL + 'member/change-password', payload, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.toastType = true;
        this.toastMessage = "Password changed successfully";
        this.resetForm();
      } catch (error) {
        this.toastType = false;
        this.toastMessage = "An error occurred while changing the password: " + error.response.data.message || error.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>


<style scoped>
/* Add any additional styling here */
</style>