<template>
    <main-header heading="Verify WhatsApp Number"></main-header>

    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div v-if="successMessage" class="alert alert-success mt-3">{{ successMessage }}</div>
                <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
                <h3 class="mt-4">Verify WhatsApp</h3>
                <div class="my-4">
                    <p> Please contact <a target="_blank" href="https://wa.me/+971568683241">Administrator on WhatsApp</a> to verify your number. Thank you.</p>
                </div>
                <!-- <div class="mb-3">
                    <label for="verificationCode" class="form-label">Verification Code</label>
                    <input type="text" class="form-control" id="verificationCode" v-model="verificationCode"
                        :placeholder="verificationCodePlaceholder" :disabled="isVerificationCodeDisabled" required>
                    <small>Click on the button 'Send Verification Code' to receive codes on your WhatsApp.</small>
                </div>
                <div class="d-flex justify-content-between mt-4">
                    <button class="btn btn-primary" style="width: 49%;margin-right:2%;" @click="sendVerificationCode"
                        :disabled="isSendCodeDisabled">Send
                        Verification Code</button>
                    <button class="btn btn-secondary" style="width: 49%;" @click="verifyCode"
                        :disabled="!isVerifyCodeEnabled">Verify
                        Code</button>
                </div> -->
                <hr class="my-4">
                <router-link class="btn btn-secondary w-100" to="/login">Login</router-link>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>


<script>
import { ref } from 'vue';
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter,
    },
    setup() {
        const verificationCode = ref('');
        const isSendCodeDisabled = ref(false);
        const isVerifyCodeEnabled = ref(false);
        const isVerificationCodeDisabled = ref(true);
        const verificationCodePlaceholder = ref("Click on the button 'Send Verification Code' to receive codes on your WhatsApp.");
        const successMessage = ref('');
        const errorMessage = ref('');

        const sendVerificationCode = () => {
            isSendCodeDisabled.value = true;
            isVerifyCodeEnabled.value = true;
            isVerificationCodeDisabled.value = false;
            verificationCodePlaceholder.value = "Please enter here the codes received on your WhatsApp.";

            axios.post(process.env.VUE_APP_API_URL + 'member/generate-verification-codes/', { text: 'whatsapp' }, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                }
            }).catch(() => {
                errorMessage.value = 'There was an error sending the verification code. Please try again later.';
                isSendCodeDisabled.value = false;
                isVerificationCodeDisabled.value = true;
            });
        };

        const verifyCode = () => {
            axios.post(process.env.VUE_APP_API_URL + 'member/verify-codes/', { text: 'whatsapp', code: verificationCode.value }, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                }
            }).then(() => {
                successMessage.value = 'Your mobile WhatsApp number has been verified successfully';
                isSendCodeDisabled.value = true;
                isVerifyCodeEnabled.value = false;
                isVerificationCodeDisabled.value = true;
            }).catch(() => {
                errorMessage.value = 'There is an error verifying your WhatsApp number. Please try again later.';
            });
        };

        return { verificationCode, isSendCodeDisabled, isVerifyCodeEnabled, isVerificationCodeDisabled, verificationCodePlaceholder, successMessage, errorMessage, sendVerificationCode, verifyCode };
    }
};
</script>

<style>
/* Bootstrap 5 will handle most of the styling, add custom styles if needed */
</style>