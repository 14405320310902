<template>
    <main-header heading="Settings"></main-header>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div v-if="toastMessage" class="alert" :class="toastType ? 'alert-success' : 'alert-danger'">
                    {{ toastMessage }}
                </div>
                <h3 class="mt-4 mb-4">Settings</h3>
                <div>
                    <div class="row mb-3">
                        <div class="col-8">
                            <label for="hide_profile" class="form-label">Do not allow members to view your profile</label>
                        </div>
                        <div class="col-4 text-end">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="hide_profile" v-model="hideProfile"
                                    @change="postSetting('hide_profile', hideProfile)">
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-8">
                            <label for="hide_photos" class="form-label">Do not allow members to view your photos</label>
                        </div>
                        <div class="col-4 text-end">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="hide_photos" v-model="hidePhotos"
                                    @change="postSetting('hide_photos', hidePhotos)">
                            </div>
                        </div>
                    </div>

                    <!-- Add similar rows for other settings here -->
                    <!-- Example: Allow members to send you messages -->
                    <div class="row mb-3">
                        <div class="col-8">
                            <label for="hide_chat" class="form-label">Do not allow members to send you messages</label>
                        </div>
                        <div class="col-4 text-end">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="hide_chat" v-model="hideChat"
                                    @change="postSetting('hide_chat', hideChat)">
                            </div>
                        </div>
                    </div>
                    <!-- Example: Allow members to send you messages -->
                    <div class="row mb-3">
                        <div class="col-8">
                            <label for="hide_comments" class="form-label">Do not allow users to comment on your
                                profile</label>
                        </div>
                        <div class="col-4 text-end">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="hide_comments" v-model="hideComments"
                                    @change="postSetting('hide_comments', hideComments)">
                            </div>
                        </div>
                    </div>

                    <!-- New setting: Invite Only -->
                    <div class="row mb-3">
                        <div class="col-8">
                            <label for="invite_only" class="form-label">Hide your profile completely and allow only invited
                                members to view your profile.</label>
                        </div>
                        <div class="col-4 text-end">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="invite_only" v-model="inviteOnly"
                                    @change="postSetting('invite_only', inviteOnly)">
                            </div>
                        </div>
                    </div>
                    <!-- New setting: Allow current location -->
                    <div class="row mb-3">
                        <div class="col-8">
                            <label for="allow_location" class="form-label">Allow members to see your current
                                location</label>
                        </div>
                        <div class="col-4 text-end">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="allow_location" v-model="allowLocation"
                                    @change="postSetting('allow_location', allowLocation)">
                            </div>
                        </div>
                    </div>

                    <!-- New setting: Allow browser notifications -->
                    <div class="row mb-3">
                        <div class="col-8">
                            <label for="allow_browser_notifications" class="form-label">Allow browser notifications</label>
                        </div>
                        <div class="col-4 text-end">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="allow_browser_notifications"
                                    v-model="allowBrowserNotifications"
                                    @change="postSetting('allow_browser_notifications', allowBrowserNotifications)">
                            </div>
                        </div>
                    </div>

                    <!-- New setting: Allow browser notifications -->
                    <div class="row mb-3">
                        <div class="col-8">
                            <label for="preferred_notification" class="form-label">Choose preferred way of receiving
                                updates</label>
                        </div>
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="preferred_notification" id="email"
                                    value="email" v-model="preferredNotification"
                                    @change="postSetting('preferred_notification', preferredNotification)">
                                <label class="form-check-label" for="email">Email</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="preferred_notification" id="whatsapp"
                                    value="whatsapp" v-model="preferredNotification"
                                    @change="postSetting('preferred_notification', preferredNotification)">
                                <label class="form-check-label" for="whatsapp">WhatsApp</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="preferred_notification" id="push"
                                    value="push" v-model="preferredNotification"
                                    @change="postSetting('preferred_notification', preferredNotification)">
                                <label class="form-check-label" for="push">Push Notifications</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>
<script>
import axios from "axios";
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
// eslint-disable-next-line no-unused-vars
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDIpW5FBLlu5_drJkcHZKA1C9js9IvqK-M",
    authDomain: "authentication-f3509.firebaseapp.com",
    databaseURL: "https://authentication-f3509.firebaseio.com",
    projectId: "authentication-f3509",
    storageBucket: "authentication-f3509.appspot.com",
    messagingSenderId: "942708344379",
    appId: "1:942708344379:web:f926a89f0aecdae825be1e",
}
// eslint-disable-next-line no-unused-vars
const app = initializeApp(firebaseConfig);

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    },
    data() {
        return {
            hideProfile: false,
            hidePhotos: false,
            hideChat: false,
            hideComments: false,
            inviteOnly: false,
            allowLocation: false,
            allowBrowserNotifications: false,
            preferredNotification: 'email',
            toastMessage: "",
            toastType: true, // true for success, false for error
        };
    },
    created() {
        // Fetch member settings from API on component mount
        this.fetchMemberSettings();
    },
    methods: {
        async fetchMemberSettings() {
            const apiUrl = process.env.VUE_APP_API_URL + "member/settings";
            const accessToken = sessionStorage.getItem('accessToken');
            const headers = {
                "Authorization": "Bearer " + accessToken,
            };

            try {
                const response = await axios.get(apiUrl, { headers });
                const settings = response.data.settings;

                this.hideProfile = settings.hide_profile === '1';
                this.hidePhotos = settings.hide_photos === '1';
                this.hideChat = settings.hide_chat === '1';
                this.hideComments = settings.hide_comments === '1';
                this.inviteOnly = settings.invite_only === '1';

                this.allowLocation = settings.allow_location === '1';
                this.allowBrowserNotifications = settings.allow_browser_notifications === '1';
                this.preferredNotification = settings.preferred_notification;

            } catch (error) {
                console.error("Error fetching member settings:", error);
            }
        },
        async postSetting(settingName, value) {
            const apiUrl = process.env.VUE_APP_API_URL + "member/update-settings";
            const data = { [settingName]: value };
            const accessToken = sessionStorage.getItem('accessToken');
            const headers = {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json",
            };
            if (settingName === 'allow_browser_notifications' && value) {
                try {
                    if ('serviceWorker' in navigator) {
                        const messaging = getMessaging();
                        await getToken(messaging, { vapidKey: process.env.FIREBASE_SERVER_KEY }).then((currentToken) => {
                            if (currentToken) {
                                data['device_token'] = currentToken;
                                console.log(currentToken);
                            } else {
                                console.log('No registration token available. Request permission to generate one.');
                            }
                        }).catch((err) => {
                            console.log('An error occurred while retrieving token. ', err);
                        });
                    }
                } catch (error) {
                    console.error('Error getting the device token:', error);
                }
            }
            if (settingName === 'allow_location' && value === true) {
                try {
                    const userLocation = await this.getUserLocation();
                    data['location'] = userLocation;
                } catch (locationError) {
                    console.error("Error fetching user location:", locationError);
                }
            }
            try {
                await axios.post(apiUrl, data, { headers });
                this.toastType = true;
                this.toastMessage = "Setting updated successfully";
            } catch (error) {
                this.toastType = false;
                this.toastMessage = "An error occurred while updating settings.";
            }
        },
        async getUserLocation() {
            return new Promise((resolve, reject) => {
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const { latitude, longitude } = position.coords;
                            resolve({ latitude, longitude });
                        },
                        (error) => {
                            reject(error);
                        }
                    );
                } else {
                    reject(new Error("Geolocation is not supported by this browser."));
                }
            });
        },

    }
};
</script>