<template>
    <main-header heading="Account"></main-header>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <h3>Account Management</h3>

                <ul class="list-group">
                    <router-link to="/member/photos/upload" class="text-decoration-none">
                        <li class="list-group-item">
                            <i class="bi bi-cloud-arrow-up-fill me-2"></i> Upload Photos
                        </li>
                    </router-link>

                    <router-link :to="`/members/search/${user.id}`" class="text-decoration-none">
                        <li class="list-group-item">
                            <i class="bi bi-person-fill me-2"></i> Public Profile
                        </li>
                    </router-link>

                    <router-link to="/member/profile/edit" class="text-decoration-none">
                        <li class="list-group-item">
                            <i class="bi bi-person-lines-fill me-2"></i> Edit Profile
                        </li>
                    </router-link>
                    
                    <router-link to="/guardian/edit" class="text-decoration-none">
                        <li class="list-group-item">
                            <i class="bi bi-person-plus-fill me-2"></i> Add Guardian Details
                        </li>
                    </router-link>

                    <router-link to="/member/interests/edit" class="text-decoration-none">
                        <li class="list-group-item">
                            <i class="bi bi-pencil-square me-2"></i> Edit Interests
                        </li>
                    </router-link>

                    <router-link to="/member/verify-contact" class="text-decoration-none">
                        <li class="list-group-item">
                            <i class="bi bi-telephone-fill me-2"></i> Update / Verify Contact
                        </li>
                    </router-link>

                    <router-link to="/member/password/change" class="text-decoration-none">
                        <li class="list-group-item">
                            <i class="bi bi-key-fill me-2"></i> Change Password
                        </li>
                    </router-link>

                    <router-link to="/member/subscriptions" class="text-decoration-none">
                        <li class="list-group-item">
                            <i class="bi bi-gear-fill me-2"></i> Manage Subscriptions
                        </li>
                    </router-link>

                    <router-link to="/member/settings" class="text-decoration-none">
                        <li class="list-group-item">
                            <i class="bi bi-gear-fill me-2"></i> Settings
                        </li>
                    </router-link>


                    <router-link to="/member/logout" class="text-decoration-none">
                        <li class="list-group-item">
                            <i class="bi bi-box-arrow-right me-2"></i>
                            Logout
                        </li>
                    </router-link>

                    <router-link to="/member/delete-account" class="text-decoration-none">
                        <li class="list-group-item text-danger">
                            <i class="bi bi-trash-fill me-2"></i> Delete Account
                        </li>
                    </router-link>
                </ul>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    },
    data() {
        return {
            user: JSON.parse(sessionStorage.getItem('user')),
        }
    },
    methods: {
        goBack() {

        }
    }
    // created(){
    //     this.user = JSON.parse(sessionStorage.getItem('user'));
    // }
}
</script>

<style scoped></style>
