<template>
    <div class="matches-of-the-day" v-if="matches.length > 0">
        <h5 class="my-4">Matches of the Day</h5>
        <swiper :pagination="{ clickable: true }" :slides-per-view="2">
            <swiper-slide v-for="match in matches" :key="match.id" @click="goToProfile(match.id)">
                <div class="text-center">
                    <img :src="getPhotoUrl(match)" class="rounded-circle mb-2"
                        :alt="match.name">
                    <div><strong>{{ match.name }}</strong></div>
                    <div>
                        <i class="fas fa-birthday-cake"></i>&nbsp;<span>{{ match.gender.charAt(0).toUpperCase() +
                            match.gender.slice(1) }}</span>
                        <i class="bi bi-heart-fill mx-1"></i>
                        <span>{{ calculateAge(match.date_of_birth) }} years</span>
                    </div>
                    <div class="mt-2">
                        <i class="bi bi-geo-alt-fill mx-1"></i>
                        <span>{{ match.location }}</span>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <hr class="my-4">
    </div>
</template>
  
<script>
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination } from 'swiper/core';
import 'swiper/css';

SwiperCore.use([Pagination]);

export default {
    name: 'MatchesOfTheDay',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            matches: [],
            token: sessionStorage.getItem('accessToken'),
        };
    },
    methods: {
        fetchMatches() {
            axios.get(process.env.VUE_APP_API_URL + 'member/settings', {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            })
                .then(response => {
                    // Note the change here from response.settings to response.data.settings
                    if (response.data && response.data.settings) {
                        let settings = response.data.settings; // Adjusted for clarity
                        if (settings.matches_of_the_day) {
                            let matchesData = settings.matches_of_the_day;
                            // Check if matches_of_the_day is a string and attempt to parse it
                            if (typeof matchesData === 'string') {
                                try {
                                    matchesData = JSON.parse(matchesData);
                                } catch (e) {
                                    console.error('Error parsing matches_of_the_day:', e);
                                    return; // Stop execution if parsing fails
                                }
                            }
                            this.matches = matchesData;
                        }
                    } else {
                        console.log('Unexpected response structure:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching matches:', error);
                });
        },
        calculateAge(dob) {
            const birthDate = new Date(dob);
            const difference = Date.now() - birthDate.getTime();
            const ageDate = new Date(difference);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        },
        getPhotoUrl(match) {
            return match.photo_url;
        },
        goToProfile(id) {
            window.location.href = `/members/search/${id}`;
        }
    },
    mounted() {
        this.fetchMatches();
    }
};
</script>
  
<style scoped>
.rounded-circle {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 50%; 
    object-fit: cover; 
}
</style>
  