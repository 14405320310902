<template>
    <div v-if="showIcon" class="whatsapp-icon">
        <a :href="whatsappLink" target="_blank">
            <img src="@/assets/images/whatsapp.png" alt="WhatsApp" />
        </a>
    </div>
</template>
  
<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
    name: 'WhatsAppIcon',
    setup() {
        const route = useRoute();
        const whatsappLink = `https://wa.me/971568683241`;

        const allowedPaths = [
            '/login', 
            '/reset-password',
            '/set-new-password',
            '/register',
            '/page/contact',
            '/affiliate-marketing/dashboard',
            '/member/subscriptions',
            '/subscription/packages',
            '/subscription/payment',
            '/subscription/complete'
        ];

        const showIcon = computed(() => allowedPaths.includes(route.path));

        return { whatsappLink, showIcon };
    },
};
</script>
  
<style scoped>
.whatsapp-icon {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10000;
    cursor: pointer;
}

.whatsapp-icon img {
    width: 40px;
    height: 40px;
    cursor: pointer;
}
</style>
  