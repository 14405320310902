<template>
    <main-header heading="Member Management"></main-header>

    <div class="container my-4">
        <h3 class="mb-4">Member Management</h3>
        <ul class="list-group">

            <router-link to="/member/get-requests-received" class="text-decoration-none">
                <li class="list-group-item">
                    <i class="bi bi-envelope-open me-2"></i> Interest Requests
                </li>
            </router-link>
            <router-link to="/member/get-requests-sent" class="text-decoration-none">
                <li class="list-group-item">

                    <i class="bi bi-send me-2"></i> My Interests
                </li>
            </router-link>
            <router-link to="/member/get-shortlisted-matches" class="text-decoration-none">
                <li class="list-group-item">
                    <i class="bi bi-star me-2"></i> Shortlisted Matches
                </li>
            </router-link>
            <router-link to="/member/get-ignored-members" class="text-decoration-none">
                <li class="list-group-item">
                    <i class="bi bi-person-x-fill me-2"></i> Ignored Members
                </li>
            </router-link>
            <router-link to="/member/match-preferences" class="text-decoration-none">
                <li class="list-group-item">
                    <i class="bi bi-heart-fill me-2"></i> Match Preferences
                </li>
            </router-link>
            <router-link to="/affiliate-marketing/dashboard" class="text-decoration-none">
                <li class="list-group-item">
                    <i class="bi bi-cash-coin me-2"></i> Referral Program
                </li>
            </router-link>
            <router-link to="/page/contact" class="text-decoration-none">
                <li class="list-group-item">
                    <i class="bi bi-telephone-fill me-2"></i> Support
                </li>
            </router-link>
        </ul>
    </div>
    <main-footer></main-footer>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    },
    methods: {
        goBack() {

        }
    }
}
</script>

<style scoped></style>
