<template>
  <div class="card member-card" @click="goToMember">
    <div class="card-body" :style="{ backgroundImage: 'url(' + photo_url + ')' }">
      <div class="text-block">
        <h4 class="card-title text-white">{{ full_name }}</h4>
        <div class="card-text text-white">
          <i class="fas fa-birthday-cake"></i> {{ age }}
          <i class="fas fa-heart"></i> {{ capitalizedMaritalStatus }}
          <i :class="genderIcon"></i> {{ capitalizedGender }}
          <span v-if="occupation">
            <i class="fas fa-briefcase"></i> {{ occupation }}
          </span>
        </div>
        <div class="card-text text-white"><i class="fas fa-map-marker-alt"></i> {{ location }}</div>
      </div>
    </div>
    <div v-if="match_percentage > 0" class="image-container">
      <div class="text-overlay">{{ match_percentage }}%</div>
    </div>
    <img class="is-premium" v-if="is_premium" src="@/assets/images/premium.png" alt="Premium Member" />
  </div>
</template>

<script>
export default {
  name: 'MemberCard',
  props: {
    memberId: Number,
    full_name: String,
    age: String,
    marital_status: String,
    gender: String,
    occupation: String,
    location: String,
    photo_url: String,
    is_premium: String,
    match_percentage: Number
  },
  methods: {
    goToMember() {
      this.$emit('memberClicked', this.memberId);
    }
  },
  computed: {
    capitalizedMaritalStatus() {
      return this.marital_status.charAt(0).toUpperCase() + this.marital_status.slice(1);
    },
    capitalizedGender() {
      return this.gender.charAt(0).toUpperCase() + this.gender.slice(1);
    },
    genderIcon() {
      return this.gender === 'male' ? 'fas fa-mars' : 'fas fa-venus';
    }
  }
}
</script>

<style scoped>
.member-card {
  color: white;
  width: 100%;
  height: 250px;
  position: relative;
  margin-bottom: 16px;
}

.member-card .card-body {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
}

.image-container {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  left: 10px;
  background-size: cover;
  background-position: center;
  background-image: url('@/assets/images/match.png');
}

.text-overlay {
  font-size: 8px;
  position: absolute;
  top: 60%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.is-premium {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
}

.text-block {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
  width: 100%;
  padding: 20px;
  margin-left: -16px;
}
</style>
