import axios from 'axios';
import { createRouter, createWebHistory } from 'vue-router';
//******************* PUBLIC PAGES *******************
import HomePage from './views/HomePage.vue';
import ResetPassword from './views/ResetPassword.vue';
import SetNewPassword from './views/SetNewPassword.vue';
//******************* MEMBER PAGES *******************
import MemberRegister from './views/MemberRegister.vue';
import MemberLogin from './views/MemberLogin.vue';
import MemberDashboard from './views/MemberDashboard.vue'; // Ensure this import is correct
//import MemberPreferences from './views/MemberPreferences.vue'; // Ensure this import is correct
import MemberProfileEdit from './views/MemberProfileEdit.vue'; // Ensure this import is correct
import MemberInterestsEdit from './views/MemberInterestsEdit.vue';
import MatchPreferences from './views/MatchPreferences.vue'; // Ensure this import is correct
import PasswordChange from './views/PasswordChange.vue';
import MemberPhotos from './views/MemberPhotos.vue';
import MemberDelete from './views/MemberDelete.vue';
import MemberSettings from './views/MemberSettings.vue';
import MemberVerifyContact from './views/MemberVerifyContact.vue';


import MemberLogout from './views/MemberLogout.vue';
import MemberSearch from './views/MemberSearch.vue';
import MemberProfile from './views/MemberProfile.vue';
import VerifyWhatsApp from './views/VerifyWhatsApp.vue';
import VerifyEmail from './views/VerifyEmail.vue';

import MemberRequestsSent from './views/MemberRequestsSent.vue';
import MemberShortlisted from './views/MemberShortlisted.vue';

import MemberRequestsReceived from './views/MemberRequestsReceived.vue';
import MemberIgnored from './views/MemberIgnored.vue';

//
import MemberAccount from './views/MemberAccount.vue';
import MemberManagement from './views/MemberManagement.vue';
import MemberChat from './views/MemberChat.vue';
import MemberChatRoom from './views/MemberChatRoom.vue';

//AFFLIATE MARKETING
import MemberAffliated from './views/MemberAffliated.vue';

//BLOG
import BlogPosts from './views/BlogPosts.vue';
import BlogPostBySlug from './views/BlogPostBySlug.vue';

//PAGES
import StaticPage from './views/StaticPage.vue';

//SUBSCRIPTION
import MemberSubscriptions from './views/MemberSubscriptions.vue';
import SubscriptionPackages from './views/SubscriptionPackages.vue';
import SubscriptionPayment from './views/SubscriptionPayment.vue';
import SubscriptionComplete from './views/SubscriptionComplete.vue';

//PAYPAL
import SubscriptionPayPal from './views/SubscriptionPayPal.vue';

//Guardian
import GuardianEdit from './views/GuardianEdit.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/register', component: MemberRegister },
  { path: '/login', component: MemberLogin },
  { path: '/reset-password', component: ResetPassword },
  { path: '/set-new-password', component: SetNewPassword },
  //Blog posts
  { path: '/blog', component: BlogPosts },
  {
    path: '/blog/:slug',
    name: 'BlogPostBySlug',
    component: BlogPostBySlug,
    props: true,
  },
  { path: '/member/dashboard', component: MemberDashboard, meta: { requiresAuth: true } },
  //{ path: '/member/preferences', component: MemberPreferences, meta: { requiresAuth: true } },
  { path: '/member/profile/edit', component: MemberProfileEdit, meta: { requiresAuth: true } },
  { path: '/member/interests/edit', component: MemberInterestsEdit, meta: { requiresAuth: true } },
  { path: '/member/password/change', component: PasswordChange, meta: { requiresAuth: true } },
  { path: '/member/delete-account', component: MemberDelete, meta: { requiresAuth: true } },
  { path: '/member/photos/upload', component: MemberPhotos, meta: { requiresAuth: true } },
  { path: '/member/settings', component: MemberSettings, meta: { requiresAuth: true } },
  { path: '/member/verify-contact', component: MemberVerifyContact, meta: { requiresAuth: true } },
  { path: '/member/logout', component: MemberLogout, meta: { requiresAuth: true } },
  { path: '/members/search', component: MemberSearch, meta: { requiresAuth: true } },
  { path: '/member/verify-whatsapp', component: VerifyWhatsApp, meta: { requiresAuth: true } },
  { path: '/member/verify-email', component: VerifyEmail, meta: { requiresAuth: true } },
  { path: '/member/get-shortlisted-matches', component: MemberShortlisted, meta: { requiresAuth: true } },
  { path: '/member/get-ignored-members', component: MemberIgnored, meta: { requiresAuth: true } },
  { path: '/member/get-requests-sent', component: MemberRequestsSent, meta: { requiresAuth: true } },
  { path: '/member/get-requests-received', component: MemberRequestsReceived, meta: { requiresAuth: true } },
  { path: '/member/match-preferences', component: MatchPreferences, meta: { requiresAuth: true } },

  { path: '/member/account', component: MemberAccount, meta: { requiresAuth: true } },
  { path: '/member/management', component: MemberManagement, meta: { requiresAuth: true } },
  { path: '/member/chat', component: MemberChat, meta: { requiresAuth: true } },
  {
    path: '/member/chat/:memberId',
    name: 'MemberChatRoom',
    component: MemberChatRoom,
    props: true,
    meta: { requiresAuth: true }
  },
  //affliate marketing
  { path: '/affiliate-marketing/dashboard', component: MemberAffliated, meta: { requiresAuth: true } },
  {
    path: '/members/search/:memberId',
    name: 'MemberProfile',
    component: MemberProfile,
    props: true,
    meta: { requiresAuth: true }
  },
  //SUBSCRIPTION
  { path: '/member/subscriptions', component: MemberSubscriptions, meta: { requiresAuth: true } },
  { path: '/subscription/packages', component: SubscriptionPackages, meta: { requiresAuth: true } },
  { path: '/subscription/payment', component: SubscriptionPayment, meta: { requiresAuth: true } },
  { path: '/subscription/complete', component: SubscriptionComplete, meta: { requiresAuth: true } },
  //PAYPAL
  { path: '/subscription/paypal', component: SubscriptionPayPal, meta: { requiresAuth: true } },
  { path: '/guardian/edit', component: GuardianEdit, meta: { requiresAuth: true } },

  //PAGES
  {
    path: '/page/:slug',
    name: 'StaticPage',
    component: StaticPage,
    props: true,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && (error.response.status === 401 || error.response.status === 429)) {
      if (sessionStorage.getItem('accessToken')) {
        sessionStorage.removeItem('accessToken');

        if (sessionStorage.getItem('members')) {
          sessionStorage.removeItem('members');
        }

        if (sessionStorage.getItem('match_preferences')) {
          sessionStorage.removeItem('match_preferences');
        }

        if (sessionStorage.getItem('user')) {
          sessionStorage.removeItem('user');
        }
      }
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  const allowedRoutes = [
    '/member/account',
    '/member/photos/upload',
    '/member/profile/edit',
    '/member/password/change',
    '/member/interests/edit',
    '/member/verify-contact',
    '/member/verify-whatsapp',
    '/member/verify-email',
    '/member/subscriptions',
    '/subscription/packages',
    '/subscription/payment',
    '/subscription/complete',
    '/member/logout'
  ];

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = sessionStorage.getItem('accessToken');
  const user = JSON.parse(sessionStorage.getItem('user'));

  if (requiresAuth && !isAuthenticated) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  } else if (user && user.active === 0 && !allowedRoutes.includes(to.path)) {
    next('/member/account');
  } else {
    next();
  }
});


export default router;
