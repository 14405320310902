<template>
    <main-header heading="Subscription Payments"></main-header>
    <div class="container my-4">
        <div v-if="selectedPackage" class="card mb-4">
            <div class="card-body">
                <h5 class="card-title">{{ selectedPackage.title }}</h5>
                <p class="card-text">
                    <span v-if="discountedPrice">
                        <del class="text-muted">{{ formatPrice(selectedPackage.price) }}</del>
                        <span class="text-success">{{ formatPrice(discountedPrice) }}</span>
                    </span>
                    <span v-else>
                        {{ formatPrice(selectedPackage.price) }}
                    </span>
                </p>
                <p>{{ selectedPackage.details }}</p>
                <a href="#" @click="toggleCouponField">Have Coupon Code? Apply here.</a>
                <div v-if="showCouponField" class="my-2">
                    <input type="text" class="form-control" placeholder="Coupon Code" v-model="couponCode">
                    <button class="btn btn-primary mt-2" @click="applyCoupon">Apply</button>
                    <p :style="{ color: couponMessageColor }">{{ couponMessage }}</p>
                </div>
                <div class="my-4">
                    <label class="my-2">Select payment method:</label>
                    <div class="btn-group" role="group">
                        <div v-for="method in paymentMethods" :key="method.id" class="btn-group" role="group">
                            <input  class="mx-2" type="radio" :id="'paymentMethod_' + method.id" :value="method.id"
                                v-model="selectedPaymentMethod" @change="updateSelectedPaymentMethodName">
                            <label class="btn btn-outline-primary" :for="'paymentMethod_' + method.id">{{ method.name
                                }}</label>
                        </div>
                    </div>
                </div>
                <button class="btn btn-success mt-3" @click="makePayment">Make Payment</button>
                <p v-if="paymentErrorMessage" class="text-danger">{{ paymentErrorMessage }}</p>

            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    components: {
        MainHeader,
        MainFooter,
    },
    data() {
        return {
            selectedPackage: JSON.parse(sessionStorage.getItem('package')),
            discountedPrice: '',
            showCouponField: false,
            couponCode: '',
            couponMessage: '',
            couponMessageColor: 'black',
            paymentErrorMessage: '', // Added for payment error messages
            selectedPaymentMethod: '',
            selectedPaymentMethodName: '',
            paymentMethods: [
                { id: '1', name: 'EasyPaisa' },
                { id: '2', name: 'PayPal' },
            ],
            accessToken: sessionStorage.getItem('accessToken'),
        };
    },
    methods: {
        updateSelectedPaymentMethodName() {
            const selectedMethod = this.paymentMethods.find(method => method.id === this.selectedPaymentMethod);
            if (selectedMethod) {
                this.selectedPaymentMethodName = selectedMethod.name;
            }
        },
        formatPrice(value) {
            if (this.selectedPaymentMethod !== 1) {
                let convertedValue = (value / 200) * (this.discountedPrice ? 1 : 1);
                convertedValue = Math.ceil(convertedValue / 5) * 5;
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(convertedValue);
            } else {
                return Math.abs(value) < 0.01 ? 'Free' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PKR' }).format(value);
            }
        },
        toggleCouponField() {
            this.showCouponField = !this.showCouponField;
        },
        async applyCoupon() {
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + 'subscription/coupon-code/apply', {
                    coupon_code: this.couponCode,
                    package_id: this.selectedPackage.id
                }, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                    },
                });
                this.couponMessage = response.data.message;
                this.discountedPrice = response.data.data.discounted_price;
                this.showCouponField = false;
                this.couponMessageColor = 'green';
            } catch (error) {
                this.couponMessage = error.response.data.message;
                this.couponMessageColor = 'red';
            }
        },
        async makePayment() {
            try {
                // Clear previous error message
                this.paymentErrorMessage = '';
                const response = await axios.post(process.env.VUE_APP_API_URL + 'subscription/transaction', {
                    package_id: this.selectedPackage.id,
                    coupon_id: this.couponCode,
                    payment_method_id: this.selectedPaymentMethod,
                    amount: this.discountedPrice ? this.discountedPrice : this.selectedPackage.price
                }, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                    },
                });

                const invoiceUrl = response.data.invoice_url;

                sessionStorage.setItem('invoiceUrl', invoiceUrl);

                sessionStorage.setItem('paymentMethod', this.selectedPaymentMethodName);
                if (this.selectedPaymentMethod == 1) {
                    this.$router.push('/subscription/complete');
                } else {
                    this.$router.push('/subscription/complete');
                }
            } catch (error) {
                this.paymentErrorMessage = error.response.data.message;
            }
        },

        async fetchPaymentMethods() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + `subscription/payment-methods/`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                    },
                });
                this.paymentMethods = response.data;

                // Set the selectedPaymentMethod to the first method ID by default
                if (this.paymentMethods.length > 0) {
                    this.selectedPaymentMethod = this.paymentMethods[0].id;
                    this.updateSelectedPaymentMethodName();
                }

            } catch (error) {
                console.error('Error fetching package:', error);
            }
        }
    },
    created() {
        this.fetchPaymentMethods();
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>
