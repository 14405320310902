<template>
    <transition name="slide-up">
      <div v-if="isVisible" class="bottom-sheet">
        <h3>Bottom Sheet is here!</h3>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <button @click="close">Close</button>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'SearchFilters',
    data() {
      return {
        isVisible: false
      };
    },
    methods: {
      open() {
        this.isVisible = true;
      },
      close() {
        this.isVisible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;
  }
  .slide-up-enter-active, .slide-up-leave-active {
    transition: all 0.3s ease;
  }
  .slide-up-enter, .slide-up-leave-to {
    transform: translateY(100%);
  }
  </style>
  